import React from 'react'
import { Card } from 'react-bootstrap'

const IntegrationCard = ({ logo, name, description, children }) => {
  return (
    <Card className="mb-4 h-100">
      <Card.Header className="d-flex align-items-center">
        {logo && (
          <img
            src={logo}
            alt={`${name} logo`}
            style={{ height: '30px', marginRight: '10px' }}
          />
        )}

        <strong>{name}</strong>
      </Card.Header>
      <Card.Body className="d-flex flex-column">
        <Card.Text>{description}</Card.Text>
        <div className="mt-auto">{children}</div>
      </Card.Body>
    </Card>
  )
}

export default IntegrationCard
