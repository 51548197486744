import React from 'react'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
  ArrowRightCircle,
  PlusCircle,
  SignMergeLeft,
  Trash,
  XCircle,
} from 'react-bootstrap-icons'
import { Formik, Field, ErrorMessage, FieldArray } from 'formik'
import PhoneInput from 'react-phone-input-2'
import { Row, Col, Form, Button, Table } from 'react-bootstrap'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { client } from '../../libs/apollo'
import { US_STATES, CANADIAN_PROVINCES } from '../../libs/constant'
import DeleteSubjectModal from './DeleteSubjectModal'
import validator from 'validator'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import Loading from '../common/Loading'
import toast from 'react-hot-toast'
import { loggedInUserVar, settingsVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import MetaDataTable from '../common/MetaData'
import { useParams, useLocation } from 'react-router-dom'
import { formatDateTimeToString } from '../../libs/utils'
import ConfirmCreateSubjectModal from './ConfirmCreateSubjectModal'
import { Person } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useFolderConfiguration } from '../../libs/folderConfiguration'
import MergeIntoSubjectModal from './MergeIntoSubjectModal'
import moment from 'moment'

export default function SubjectForm(props) {
  const {
    displayBilling,
    toggleModal,
    setStripeCard,
    handleStageChange,
    setBookingStage,
    bookSessionFlow,
    onCompleted,
    subjectId,
    subjectViewing,
    allowDelete,
    studentViewing,
    hideCardElement,
    showFormBorder,
    subjectModal,
    organization,
    subjectGroupId,
    subjectGroupName,
    displayMerge,
  } = props

  const { contact } = useParams()
  const location = useLocation()
  const history = useHistory()
  const folderConfiguration = useFolderConfiguration()
  let newSubject = true
  if (props.updateSubject || subjectId) {
    newSubject = false
  }
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const cantDelete = [
    'Scheduling Analyst',
    'General Staff',
    'Subject',
    'Equipment Manager',
    'Organization Contact',
  ].includes(loggedInUser?.permissions?.group)

  const canMutation = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )

  const cantSeeMetadata = ['Organization Contact', 'Subject'].includes(
    loggedInUser?.permissions?.group
  )
  const cantUpdate = ['Equipment Manager', 'General Staff'].includes(
    loggedInUser?.permissions?.group
  )
  const settings = useReactiveVar(settingsVar)
  const stripe = useStripe()
  const stripeElements = useElements()
  const [updateSubject, setUpdateSubject] = useState(props.updateSubject)
  const [stripeCardElementErrors, setStripeCardElementErrors] = useState()
  const [stripeCardElementEmpty, setStripeCardElementEmpty] = useState(true)
  const [showOrgResults, setShowOrgResults] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showMergeIntoSubjectModal, setShowMergeIntoSubjectModal] =
    useState(false)
  const [subjectGroups, setSubjectGroups] = useState([])
  const [showConfirmCreateSubjectModal, setShowConfirmCreateSubjectModal] =
    useState(false)

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const toggleMergeIntoSubjectModal = () => {
    setShowMergeIntoSubjectModal(!showMergeIntoSubjectModal)
  }

  const [getSubject, { data: getSubjectData }] = useLazyQuery(
    gql`
      query SubjectDetailQuery($subjectId: ID!) {
        subject(id: $subjectId) {
          id
          addressLineOne
          addressLineTwo
          city
          rootBoxFolderId
          state
          metadata
          zipCode
          customOne
          customTwo
          sharedCanCreateFiles
          sharedCanSeeFiles
          sharedCanCreateFolders
          customThree
          customFour
          customFive
          gaiaUser {
            id
            jobTitle
            metadata
            emailConfirmed
            isActive
            lastLogin
            firstName
            lastName
            fullName
            lastLogin
            lastLogin
            loginLocation
            phoneNumber
            dummyUsername
            secondaryPhoneNumber
            secondaryEmail
            email
            emailNotificationsEnabled
            smsNotificationsEnabled
            stripeCustomer {
              stripePaymentMethods(primary: true) {
                edges {
                  node {
                    stripeResource
                    created
                  }
                }
              }
            }
          }
          organization {
            id
            name
            category
          }
          subjectGroups {
            edges {
              node {
                id
                name
              }
            }
          }
          studentId
          colleagues {
            edges {
              node {
                id
                gaiaUser {
                  id
                  lastLogin
                  firstName
                  lastName
                  fullName
                  phoneNumber
                  email
                  emailNotificationsEnabled
                  smsNotificationsEnabled
                }
              }
            }
          }
          parents {
            edges {
              node {
                id
                gaiaUser {
                  id
                  isActive
                  lastLogin
                  firstName
                  lastName
                  fullName
                  phoneNumber
                  email
                  emailNotificationsEnabled
                  smsNotificationsEnabled
                }
              }
            }
          }
          notes
          city
          state
          zipCode
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        subjectId: subjectId,
      },
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (subjectId) {
      getSubject()
    }
  }, [subjectId])

  useEffect(() => {
    if (getSubjectData?.subject) {
      setUpdateSubject(getSubjectData.subject)
    }
  }, [getSubjectData])

  const EMAIL_CHECK = gql`
    query SubjectFormGaiaUsers($emailIexact: String) {
      gaiaUsers(email_Iexact: $emailIexact, archived: false) {
        nodeCount
        edges {
          node {
            email
            archived
            firstName
            employee {
              archived
            }
          }
        }
      }
    }
  `

  const [createSubjectMutation] = useMutation(
    gql`
      mutation SubjectFormCreateSubject(
        $createSubjectInput: CreateSubjectInput!
      ) {
        createSubject(input: $createSubjectInput) {
          subject {
            id
            gaiaUser {
              fullName
              id
              email
              stripeCustomer {
                stripePaymentMethods(primary: true) {
                  edges {
                    node {
                      stripeResource
                      created
                    }
                  }
                }
              }
            }
            studentId
            organization {
              id
              name
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted(data) {
        setSubmitting(false)
        toast.success('Subject Saved')
        if (onCompleted) {
          onCompleted(data)
        }
        if (toggleModal) {
          toggleModal()
        }
      },
      refetchQueries: [
        'SubjectDetailQuery',
        'Subjects',
        'OrganizationsSubjects',
        'SubjectGroupsQuery',
        'SubjectsQuery',
      ],
    }
  )

  const [updateSubjectMutation, { loading: updateSubjectLoading }] =
    useMutation(
      gql`
        mutation SubjectFormUpdateSubject(
          $updateSubjectInput: UpdateSubjectInput!
        ) {
          updateSubject(input: $updateSubjectInput) {
            subject {
              id
              gaiaUser {
                fullName
                id
                email
                stripeCustomer {
                  stripePaymentMethods(primary: true) {
                    edges {
                      node {
                        stripeResource
                        created
                      }
                    }
                  }
                }
              }
              studentId
              organization {
                id
                name
              }
            }
          }
        }
      `,
      {
        errorPolicy: 'all',
        onCompleted(data) {
          setSubmitting(false)
          if (!bookSessionFlow) {
            if (subjectViewing) {
              toast.success('Saved')
            } else {
              toast.success('Subject Saved')
            }
          }
          if (onCompleted) {
            onCompleted(data)
          }
          if (setBookingStage) {
            setBookingStage('confirmSession')
          }
          if (handleStageChange) {
            handleStageChange('review')
          }
          if (toggleModal) {
            toggleModal()
          }
          if (
            setStripeCard &&
            data.updateSubject.subject.gaiaUser.stripeCustomer
              ?.stripePaymentMethods?.edges.length > 0
          ) {
            const stripeCard = JSON.parse(
              data.updateSubject.subject.gaiaUser.stripeCustomer
                ?.stripePaymentMethods?.edges[0]?.node?.stripeResource
            )
            setStripeCard(stripeCard)
          }
        },
        refetchQueries: [
          'SubjectDetailQuery',
          'Subjects',
          'OrganizationsSubjects',
          'SubjectsQuery',
        ],
      }
    )
  const [searchOrgs, { data: organizationData }] = useLazyQuery(
    gql`
      query Organizations($organizationsNameIcontains: String) {
        organizations(name_Icontains: $organizationsNameIcontains) {
          edges {
            node {
              id
              name
              category
              subjectSharedCanSeeFiles
              subjectSharedCanCreateFiles
              subjectSharedCanCreateFolders
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
    }
  )

  const handleOrgChange = (formik, e) => {
    searchOrgs({
      variables: {
        organizationsNameIcontains: e.target.value,
      },
      fetchPolicy: 'network-only',
    })
    formik.setFieldValue(`organizationName`, e.target.value)
    if (!e.target.value) {
      formik.setFieldValue(`schoolOrganization`, null)
      formik.setFieldValue('organizationId', null)
    }
    setShowOrgResults(true)
  }

  const handleOrganizationXClick = (formik) => {
    formik.setFieldValue(`organizationId`, '')
    formik.setFieldValue(`organizationName`, '')
    formik.setFieldValue(`schoolOrganization`, false)
    formik.setFieldValue(
      'sharedCanSeeFiles',
      folderConfiguration.subjectSharedCanSeeFiles
    )
    formik.setFieldValue(
      'sharedCanCreateFiles',
      folderConfiguration.subjectSharedCanCreateFiles
    )
    formik.setFieldValue(
      'sharedCanCreateFolders',
      folderConfiguration.subjectSharedCanCreateFolders
    )
  }

  const handleOrgClick = (formik, node) => {
    formik.setFieldValue('organizationId', node.id)
    formik.setFieldValue('organizationName', node.name)
    formik.setFieldValue('sharedCanSeeFiles', node.subjectSharedCanSeeFiles)
    formik.setFieldValue(
      'sharedCanCreateFiles',
      node.subjectSharedCanCreateFiles
    )
    formik.setFieldValue(
      'sharedCanCreateFolders',
      node.subjectSharedCanCreateFolders
    )
    if (node.category === 'SCHOOL') {
      formik.setFieldValue(`schoolOrganization`, true)
    } else {
      formik.setFieldValue(`schoolOrganization`, false)
    }
    getSubjectGroups(node.id)
    setShowOrgResults(false)
  }

  let schoolOrganization = null
  if (updateSubject && updateSubject?.organization) {
    if (updateSubject.organization.category === 'SCHOOL') {
      schoolOrganization = true
    } else {
      schoolOrganization = false
    }
  }
  let stripePaymentMethodResource
  if (updateSubject && updateSubject.gaiaUser.stripeCustomer) {
    const stripePaymentMethodNodes =
      updateSubject.gaiaUser.stripeCustomer.stripePaymentMethods
    if (stripePaymentMethodNodes.edges.length > 0) {
      stripePaymentMethodResource = JSON.parse(
        stripePaymentMethodNodes.edges[
          stripePaymentMethodNodes.edges.length - 1
        ].node.stripeResource
      )
    }
  }
  const [subjectGroupsQuery, { data: subjectGroupsQueryData }] = useLazyQuery(
    gql`
      query SubjectGroupsQuery($organization: ID) {
        subjectGroups(archived: false, organization: $organization) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const getSubjectGroups = (organizationId) => {
    const variables = { organization: organizationId }
    subjectGroupsQuery({ variables })
  }

  useEffect(() => {
    if (organization) {
      getSubjectGroups(organization.id)
    }
  }, [organization])

  useEffect(() => {
    const currentsubjectGroups = []
    if (subjectGroupsQueryData) {
      subjectGroupsQueryData.subjectGroups.edges.forEach((subjectGroup) => {
        currentsubjectGroups.push(subjectGroup.node)
      })
      setSubjectGroups(currentsubjectGroups)
    }
  }, [subjectGroupsQueryData])

  function handleSubjectGroupClick(e, formik) {
    const selectedValue = e.target.value
    if (
      !formik.values.subjectGroups.find(
        (subjectGroup) => subjectGroup.id === selectedValue
      )
    ) {
      const subjectGroupData = subjectGroups.find(
        (subjectGroup) => subjectGroup.id === selectedValue
      )
      formik.setFieldValue('subjectGroups', [
        ...formik.values.subjectGroups,
        subjectGroupData,
      ])
    }
  }

  function deleteSubjectGroupClick(id, formik) {
    const filteredArray = formik.values.subjectGroups.filter(
      (subjectGroup) => subjectGroup.id !== id
    )
    formik.setFieldValue('subjectGroups', filteredArray)
  }

  useEffect(() => {
    if (updateSubject) {
      getSubjectGroups(updateSubject.organization?.id)
    }
  }, [updateSubject])

  if ((subjectId && !getSubjectData) || !folderConfiguration)
    return (
      <Row>
        <Col>
          <Loading />
        </Col>
      </Row>
    )

  return (
    <>
      {displayMerge && canMutation && (
        <MergeIntoSubjectModal
          showModal={showMergeIntoSubjectModal}
          toggleModal={toggleMergeIntoSubjectModal}
          mergeSubjectId={subjectId}
        />
      )}
      {allowDelete && (
        <DeleteSubjectModal
          showModal={showDeleteModal}
          toggleModal={toggleDeleteModal}
          subjectId={subjectId}
        />
      )}

      <Formik
        enableReinitialize
        initialValues={
          updateSubject
            ? {
                firstName: updateSubject?.gaiaUser?.firstName,
                lastName: updateSubject?.gaiaUser?.lastName,
                password: '',
                active: updateSubject?.gaiaUser?.isActive,
                metadata: JSON.parse(updateSubject?.metadata),
                studentId: updateSubject?.studentId,
                customOne: updateSubject?.customOne,
                customTwo: updateSubject?.customTwo,
                sharedCanSeeFiles: updateSubject?.sharedCanSeeFiles,
                sharedCanCreateFiles: updateSubject?.sharedCanCreateFiles,
                sharedCanCreateFolders: updateSubject?.sharedCanCreateFolders,
                customThree: updateSubject?.customThree,
                customFour: updateSubject?.customFour,
                customFive: updateSubject?.customFive,
                schoolOrganization: schoolOrganization,
                confirmPassword: '',
                email: updateSubject?.gaiaUser?.dummyUsername
                  ? ''
                  : updateSubject?.gaiaUser?.email,
                secondaryEmail: updateSubject?.gaiaUser?.secondaryEmail,
                phoneNumber: updateSubject?.gaiaUser?.phoneNumber,
                secondaryPhoneNumber:
                  updateSubject?.gaiaUser?.secondaryPhoneNumber,
                notes: updateSubject?.notes,
                parents: updateSubject?.parents.edges.map((parent) => {
                  return {
                    active: parent.node.gaiaUser.isActive,
                    id: parent.node.id,
                    firstName: parent.node?.gaiaUser?.firstName,
                    lastName: parent.node?.gaiaUser?.lastName,
                    email: parent.node?.gaiaUser?.email,
                    phoneNumber: parent.node?.gaiaUser.phoneNumber
                      ? parent.node?.gaiaUser?.phoneNumber
                      : null,
                    secondaryPhoneNumber: parent.node?.gaiaUser
                      .secondaryPhoneNumber
                      ? parent.node?.gaiaUser?.secondaryPhoneNumber
                      : null,
                    secondaryEmail: parent.node?.gaiaUser.secondaryEmail
                      ? parent.node?.gaiaUser?.secondaryEmail
                      : null,
                    smsNotificationsEnabled:
                      parent.node?.gaiaUser?.smsNotificationsEnabled,
                    emailNotificationsEnabled:
                      parent.node?.gaiaUser?.emailNotificationsEnabled,
                  }
                }),
                colleagues: updateSubject.colleagues.edges.map((colleague) => {
                  return {
                    id: colleague.node.id,
                    firstName: colleague.node?.gaiaUser?.firstName,
                    lastName: colleague.node?.gaiaUser?.lastName,
                    email: colleague.node?.gaiaUser?.email,
                    phoneNumber: colleague.node?.gaiaUser.phoneNumber
                      ? colleague.node?.gaiaUser?.phoneNumber
                      : null,
                    secondaryPhoneNumber: colleague.node?.gaiaUser
                      .secondaryPhoneNumber
                      ? colleague.node?.gaiaUser.secondaryPhoneNumber
                      : null,
                    secondaryEmail: colleague.node?.gaiaUser.secondaryEmail
                      ? colleague.node?.gaiaUser?.secondaryEmail
                      : null,
                    smsNotificationsEnabled:
                      colleague.node?.gaiaUser?.smsNotificationsEnabled,
                    emailNotificationsEnabled:
                      colleague.node?.gaiaUser?.emailNotificationsEnabled,
                  }
                }),
                removeParents: [],
                removeColleagues: [],
                jobTitle: updateSubject.gaiaUser.jobTitle,
                smsNotificationsEnabled:
                  updateSubject?.gaiaUser?.smsNotificationsEnabled,
                emailNotificationsEnabled:
                  updateSubject?.gaiaUser?.emailNotificationsEnabled,
                addressLineOne: updateSubject?.addressLineOne,
                addressLineTwo: updateSubject?.addressLineTwo,
                city: updateSubject?.city,
                state: updateSubject?.state,
                zipCode: updateSubject?.zipCode,
                organizationName: updateSubject?.organization?.name,
                organizationId: updateSubject?.organization?.id,
                subjectGroups: updateSubject?.subjectGroups?.edges.map(
                  (subjectGroup) => {
                    return subjectGroup.node
                  }
                ),
              }
            : {
                firstName: '',
                lastName: '',
                password: '',
                metadata: {},
                jobTitle: '',
                customOne: '',
                customTwo: '',
                customThree: '',
                customFour: '',
                customFive: '',
                sharedCanSeeFiles: organization
                  ? organization.subjectSharedCanSeeFiles
                  : folderConfiguration?.subjectSharedCanSeeFiles,
                sharedCanCreateFiles: organization
                  ? organization.subjectSharedCanCreateFiles
                  : folderConfiguration?.subjectSharedCanCreateFiles,
                sharedCanCreateFolders: organization
                  ? organization.subjectSharedCanCreateFolders
                  : folderConfiguration?.subjectSharedCanCreateFolders,
                confirmPassword: '',
                schoolOrganization: '',
                skipWelcomeEmail: true,
                studentId: '',
                emailConfirmed: false,
                email: '',
                active: true,
                secondaryEmail: '',
                phoneNumber: '',
                secondaryPhoneNumber: '',
                parents: [],
                notes: '',
                colleagues: [],
                removeParents: [],
                removeColleagues: [],
                smsNotificationsEnabled: true,
                emailNotificationsEnabled: true,
                addressLineOne: '',
                addressLineTwo: '',
                city: '',
                state: '',
                zipCode: '',
                organizationName: organization ? organization.name : '',
                organizationId: organization ? organization.id : '',
                subjectGroups:
                  subjectGroupName && subjectGroupId
                    ? [
                        {
                          id: subjectGroupId,
                          name: subjectGroupName,
                        },
                      ]
                    : [],
              }
        }
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .nullable()
            .test('isRequired', 'required', (value) => {
              let valid = true
              if (!value) {
                valid = false
              }
              return valid
            }),
          lastName: Yup.string()
            .nullable()
            .test('isRequired', 'required', (value) => {
              let valid = true
              if (!value) {
                valid = false
              }
              return valid
            }),
          notes: Yup.string().nullable(),
          customOne: Yup.string().nullable(),
          customTwo: Yup.string().nullable(),
          customThree: Yup.string().nullable(),
          customFour: Yup.string().nullable(),
          customFive: Yup.string().nullable(),
          emailConfirmed: Yup.bool(),
          sharedCanSeeFiles: Yup.bool(),
          sharedCanCreateFiles: Yup.bool(),
          sharedCanCreateFolders: Yup.bool(),
          phoneNumber: Yup.mixed()
            .test('isPhoneNumber', 'Required', (value) => {
              let valid = true
              if (
                !loggedInUser.permissions.isEmployee &&
                settings.requireSubjectPhone &&
                !value
              ) {
                valid = false
              }
              return valid
            })
            .test(
              'isNotRepeat',
              'Parent phone numbers must not match subject phone number',
              (value, context) => {
                let valid = true
                if (value) {
                  context.parent.parents.forEach((parent) => {
                    if (
                      value === parent.phoneNumber ||
                      value === parent.secondaryPhoneNumber
                    ) {
                      valid = false
                    }
                  })
                }
                return valid
              }
            )
            .test('isPhoneNumber', 'Invalid phone number', (value) => {
              let valid = true
              if (value && !validator.isMobilePhone(value, 'en-US')) {
                valid = false
              }
              return valid
            }),
          jobTitle: Yup.string().nullable(),
          studentId: Yup.string().nullable(),
          addressLineOne: Yup.string().nullable(),
          addressLineTwo: Yup.string().nullable(),
          city: Yup.string().nullable(),
          state: Yup.string().nullable(),
          zipCode: Yup.string().nullable(),
          password: Yup.string(),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref('password')],
            'Password and confirm password do not match'
          ),
          secondaryPhoneNumber: Yup.string()
            .nullable()
            .test(
              'isNotRepeat',
              'Phone numbers must be unique',
              (value, context) => {
                let valid = true
                if (value && value === context.parent.phoneNumber) {
                  valid = false
                }
                return valid
              }
            )
            .test(
              'isNotRepeat',
              'Parent phone numbers must not match subject phone numbers',
              (value, context) => {
                let valid = true
                if (value) {
                  context.parent.parents.forEach((parent) => {
                    if (
                      value === parent.phoneNumber ||
                      value === parent.secondaryPhoneNumber
                    ) {
                      valid = false
                    }
                  })
                }
                return valid
              }
            )
            .test('isPhoneNumber', 'Invalid phone number', (value) => {
              let valid = true
              if (value && !validator.isMobilePhone(value, 'en-US')) {
                valid = false
              }
              return valid
            }),
          secondaryEmail: Yup.string()
            .nullable()
            .test('isNotRepeat', 'Emails must be unique', (value, context) => {
              let valid = true
              if (value && value === context.parent.email) {
                valid = false
              }
              return valid
            })
            .test(
              'isNotRepeat',
              'Parent emails must not match subject emails',
              (value, context) => {
                let valid = true
                if (value) {
                  context.parent.parents.forEach((parent) => {
                    if (
                      value === parent.email ||
                      value === parent.secondaryEmail
                    ) {
                      valid = false
                    }
                  })
                }
                return valid
              }
            )
            .test('isEmail', 'Invalid email', (value) => {
              let valid = true
              if (value && !validator.isEmail(value)) {
                valid = false
              }
              return valid
            }),
          email: Yup.string()
            .test('isEmail', 'Required', (value) => {
              let valid = true
              if (!value && !loggedInUser?.permissions?.isEmployee) {
                valid = false
              }
              if (
                !loggedInUser.permissions.isEmployee &&
                settings.requireSubjectEmail &&
                !value
              ) {
                valid = false
              }
              return valid
            })
            .test(
              'isUnique',
              'Account with this email already exists',
              async (value) => {
                let valid = true
                if (value) {
                  const { data } = await client.query({
                    query: EMAIL_CHECK,
                    fetchPolicy: 'network-only',
                    variables: {
                      emailIexact: value,
                    },
                  })
                  if (updateSubject) {
                    let matchesCurrent = false
                    if (data.gaiaUsers.edges.length > 0) {
                      data.gaiaUsers.edges.forEach((node) => {
                        if (
                          !matchesCurrent &&
                          node.node.email === updateSubject.gaiaUser.email
                        ) {
                          matchesCurrent = true
                        }
                      })
                      if (!matchesCurrent) {
                        valid = false
                      }
                    }
                  } else {
                    valid = data.gaiaUsers.nodeCount === 0
                  }
                }
                return valid
              }
            )
            .test(
              'isNotRepeat',
              'Parent emails must not match subject emails',
              (value, context) => {
                let valid = true
                if (value) {
                  context.parent.parents.forEach((parent) => {
                    if (
                      value === parent.email ||
                      value === parent.secondaryEmail
                    ) {
                      valid = false
                    }
                  })
                }
                return valid
              }
            )
            .test('isEmail', 'Invalid email', (value) => {
              let valid = true
              if (value && !validator.isEmail(value)) {
                valid = false
              }
              return valid
            }),
          smsNotificationsEnabled: Yup.bool().required('Required'),
          emailNotificationsEnabled: Yup.bool().required('Required'),
          skipWelcomeEmail: Yup.bool().nullable(),
          active: Yup.bool().nullable(),
          removeParents: Yup.array().of(Yup.string()),
          removeColleagues: Yup.array().of(Yup.string()),
          parents: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().nullable(),
              firstName: Yup.string().nullable(),
              lastName: Yup.string().nullable(),
              password: Yup.string().nullable(),
              active: Yup.string().nullable(),
              skipWelcomeEmail: Yup.string().nullable(),
              confirmPassword: Yup.string()
                .nullable()
                .oneOf(
                  [Yup.ref('password')],
                  'Password and confirm password do not match'
                ),
              email: Yup.string()
                .required('required')
                .test('isEmail', 'Invalid email', (value) => {
                  let valid = true
                  if (value && !validator.isEmail(value)) {
                    valid = false
                  }
                  return valid
                }),
              phoneNumber: Yup.string()
                .nullable()
                .test('isPhoneNumber', 'Invalid phone number', (value) => {
                  let valid = true
                  if (value && !validator.isMobilePhone(value, 'en-US')) {
                    valid = false
                  }
                  return valid
                }),
              secondaryEmail: Yup.string()
                .nullable()
                .test(
                  'isNotRepeat',
                  'Emails must be unique',
                  (value, context) => {
                    let valid = true
                    if (value && value === context.parent.email) {
                      valid = false
                    }
                    return valid
                  }
                )
                .test('isEmail', 'Invalid email', (value) => {
                  let valid = true
                  if (value && !validator.isEmail(value)) {
                    valid = false
                  }
                  return valid
                }),
              secondaryPhoneNumber: Yup.string()
                .nullable()
                .test(
                  'isNotRepeat',
                  'Phone numbers must be unique',
                  (value, context) => {
                    let valid = true
                    if (value && value === context.parent.phoneNumber) {
                      valid = false
                    }
                    return valid
                  }
                )
                .test('isPhoneNumber', 'Invalid phone number', (value) => {
                  let valid = true
                  if (value && !validator.isMobilePhone(value, 'en-US')) {
                    valid = false
                  }
                  return valid
                }),
              smsNotificationsEnabled: Yup.bool().required('required'),
              emailNotificationsEnabled: Yup.bool().required('required'),
            })
          ),
          colleagues: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().nullable(),
              firstName: Yup.string().nullable(),
              lastName: Yup.string().nullable(),
              email: Yup.string()
                .required('required')
                .test(
                  'isUnique',
                  'Emails associated to employees cannot be used for subject colleagues',
                  async (value) => {
                    let valid = true
                    if (value) {
                      const { data } = await client.query({
                        query: EMAIL_CHECK,
                        fetchPolicy: 'network-only',
                        variables: {
                          emailIexact: value,
                        },
                      })
                      valid = data.gaiaUsers.nodeCount === 0
                    }
                    return valid
                  }
                )
                .test('isEmail', 'Invalid email', (value) => {
                  let valid = true
                  if (value && !validator.isEmail(value)) {
                    valid = false
                  }
                  return valid
                }),
              phoneNumber: Yup.string()
                .nullable()
                .test('isPhoneNumber', 'Invalid phone number', (value) => {
                  let valid = true
                  if (value && !validator.isMobilePhone(value, 'en-US')) {
                    valid = false
                  }
                  return valid
                }),
              secondaryEmail: Yup.string()
                .nullable()
                .test(
                  'isNotRepeat',
                  'Emails must be unique',
                  (value, context) => {
                    let valid = true
                    if (value && value === context.parent.email) {
                      valid = false
                    }
                    return valid
                  }
                )
                .test('isEmail', 'Invalid email', (value) => {
                  let valid = true
                  if (value && !validator.isEmail(value)) {
                    valid = false
                  }
                  return valid
                }),
              secondaryPhoneNumber: Yup.string()
                .nullable()
                .test(
                  'isNotRepeat',
                  'Phone numbers must be unique',
                  (value, context) => {
                    let valid = true
                    if (value && value === context.parent.phoneNumber) {
                      valid = false
                    }
                    return valid
                  }
                )
                .test('isPhoneNumber', 'Invalid phone number', (value) => {
                  let valid = true
                  if (value && !validator.isMobilePhone(value, 'en-US')) {
                    valid = false
                  }
                  return valid
                }),
              smsNotificationsEnabled: Yup.bool().required('required'),
              emailNotificationsEnabled: Yup.bool().required('required'),
            })
          ),
          subjectGroups: Yup.array().of(
            Yup.object().shape({
              id: Yup.string().nullable(),
            })
          ),
        })}
        onSubmit={async (values) => {
          let stripeResponse
          setSubmitting(true)
          if (!stripeCardElementEmpty) {
            const cardElement = stripeElements.getElement(CardElement)
            stripeResponse = await stripe.createPaymentMethod({
              type: 'card',
              card: cardElement,
            })
            if (stripeResponse.error) {
              setStripeCardElementErrors(stripeResponse.error.message)
              setSubmitting(false)
            }
          }

          if (stripeCardElementEmpty || !stripeResponse?.error) {
            if (!updateSubject) {
              const subjectInput = {
                sharedCanSeeFiles: values.sharedCanSeeFiles,
                sharedCanCreateFiles: values.sharedCanCreateFiles,
                sharedCanCreateFolders: values.sharedCanCreateFolders,
                studentId: values.studentId ? values.studentId : null,
                notes: values.notes,
                skipWelcomeEmail: values.skipWelcomeEmail,
                customOne: values.customOne,
                customTwo: values.cucustomTwostomOne,
                customThree: values.customThree,
                customFour: values.customFour,
                customFive: values.customFive,
                stripePaymentMethodId: stripeResponse
                  ? stripeResponse.paymentMethod.id
                  : null,
                newGaiaUser: {
                  metadata: JSON.stringify(values.metadata),
                  firstName: values.firstName,
                  lastName: values.lastName,
                  jobTitle: values.jobTitle,
                  email: values.email,
                  active: values.active,
                  secondaryEmail: values.secondaryEmail
                    ? values.secondaryEmail
                    : null,
                  phoneNumber: values.phoneNumber ? values.phoneNumber : null,
                  secondaryPhoneNumber: values.secondaryPhoneNumber
                    ? values.secondaryPhoneNumber
                    : null,
                  password: values.password,
                  emailConfirmed: values.emailConfirmed,
                  emailNotificationsEnabled: values.emailNotificationsEnabled,
                  smsNotificationsEnabled: values.smsNotificationsEnabled,
                },
              }

              subjectInput.parents = values.parents.map((parent) => {
                return {
                  firstName: parent.firstName,
                  active: parent.active,
                  skipWelcomeEmail: parent.skipWelcomeEmail,
                  lastName: parent.lastName,
                  email: parent.email,
                  phoneNumber: parent.phoneNumber ? parent.phoneNumber : null,
                  secondaryPhoneNumber: parent.secondaryPhoneNumber
                    ? parent.secondaryPhoneNumber
                    : null,
                  secondaryEmail: parent.secondaryEmail
                    ? parent.secondaryEmail
                    : null,
                  smsNotificationsEnabled: parent.smsNotificationsEnabled,
                  emailNotificationsEnabled: parent.emailNotificationsEnabled,
                  password: parent.password,
                }
              })

              subjectInput.colleagues = values.colleagues.map((colleagues) => {
                return {
                  firstName: colleagues.firstName,
                  lastName: colleagues.lastName,
                  email: colleagues.email,
                  phoneNumber: colleagues.phoneNumber
                    ? colleagues.phoneNumber
                    : null,
                  secondaryPhoneNumber: colleagues.secondaryPhoneNumber
                    ? colleagues.secondaryPhoneNumber
                    : null,
                  secondaryEmail: colleagues.secondaryEmail
                    ? colleagues.secondaryEmail
                    : null,
                  smsNotificationsEnabled: colleagues.smsNotificationsEnabled,
                  emailNotificationsEnabled:
                    colleagues.emailNotificationsEnabled,
                }
              })

              if (values.addressLineOne) {
                subjectInput.subjectLocationInput = {
                  addressLineOne: values.addressLineOne,
                  addressLineTwo: values.addressLineTwo
                    ? values.addressLineTwo
                    : null,
                  city: values.city,
                  state: values.state,
                  zipCode: values.zipCode,
                }
              }

              if (values.organizationId) {
                subjectInput.organizationId = values.organizationId
              }

              subjectInput.subjectGroupIds = values.subjectGroups.map(
                (subjectGroup) => subjectGroup.id
              )
              if (
                subjectGroupId &&
                !subjectInput.subjectGroupIds.includes(subjectGroupId)
              ) {
                subjectInput.subjectGroupIds.push(subjectGroupId)
              }
              createSubjectMutation({
                variables: {
                  createSubjectInput: {
                    subjectInput,
                  },
                },
              })
            } else {
              const subjectInput = {
                id: updateSubject.id,
                sharedCanSeeFiles: values.sharedCanSeeFiles,
                sharedCanCreateFiles: values.sharedCanCreateFiles,
                sharedCanCreateFolders: values.sharedCanCreateFolders,
                notes: values.notes,
                jobTitle: values.jobTitle,
                customTwo: values.cucustomTwostomOne,
                customThree: values.customThree,
                customFour: values.customFour,
                active: values.active,
                customFive: values.customFive,
                metadata: JSON.stringify(values.metadata),
                studentId: values.studentId ? values.studentId : null,
                stripePaymentMethodId: stripeResponse
                  ? stripeResponse.paymentMethod?.id
                  : null,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                secondaryEmail: values.secondaryEmail
                  ? values.secondaryEmail
                  : null,
                phoneNumber: values.phoneNumber ? values.phoneNumber : null,
                secondaryPhoneNumber: values.secondaryPhoneNumber
                  ? values.secondaryPhoneNumber
                  : null,
                emailConfirmed: values.emailConfirmed,
                emailNotificationsEnabled: values.emailNotificationsEnabled,
                smsNotificationsEnabled: values.smsNotificationsEnabled,
              }

              if (values.password) {
                subjectInput.password = values.password
              }

              subjectInput.addParents = []
              subjectInput.updateParents = []
              values.parents.forEach((parent) => {
                if (parent.id) {
                  subjectInput.updateParents.push({
                    subjectId: parent.id,
                    active: parent.active,
                    firstName: parent.firstName,
                    lastName: parent.lastName,
                    email: parent.email,
                    phoneNumber: parent.phoneNumber ? parent.phoneNumber : null,
                    secondaryPhoneNumber: parent.secondaryPhoneNumber
                      ? parent.secondaryPhoneNumber
                      : null,
                    secondaryEmail: parent.secondaryEmail
                      ? parent.secondaryEmail
                      : null,
                    smsNotificationsEnabled: parent.smsNotificationsEnabled,
                    emailNotificationsEnabled: parent.emailNotificationsEnabled,
                    password: parent.password,
                  })
                } else {
                  subjectInput.addParents.push({
                    firstName: parent.firstName,
                    lastName: parent.lastName,
                    active: parent.active,
                    skipWelcomeEmail: parent.skipWelcomeEmail,
                    email: parent.email,
                    phoneNumber: parent.phoneNumber ? parent.phoneNumber : null,
                    secondaryPhoneNumber: parent.secondaryPhoneNumber
                      ? parent.secondaryPhoneNumber
                      : null,
                    secondaryEmail: parent.secondaryEmail
                      ? parent.secondaryEmail
                      : null,
                    smsNotificationsEnabled: parent.smsNotificationsEnabled,
                    emailNotificationsEnabled: parent.emailNotificationsEnabled,
                    password: parent.password,
                  })
                }
              })
              subjectInput.removeParentIds = values.removeParents.map(
                (subjectId) => subjectId
              )
              subjectInput.addColleagues = []
              subjectInput.updateColleagues = []
              values.colleagues.forEach((colleague) => {
                if (colleague.id) {
                  subjectInput.updateColleagues.push({
                    subjectId: colleague.id,
                    firstName: colleague.firstName,
                    lastName: colleague.lastName,
                    email: colleague.email,
                    phoneNumber: colleague.phoneNumber
                      ? colleague.phoneNumber
                      : null,
                    secondaryPhoneNumber: colleague.secondaryPhoneNumber
                      ? colleague.secondaryPhoneNumber
                      : null,
                    secondaryEmail: colleague.secondaryEmail
                      ? colleague.secondaryEmail
                      : null,
                    smsNotificationsEnabled: colleague.smsNotificationsEnabled,
                    emailNotificationsEnabled:
                      colleague.emailNotificationsEnabled,
                  })
                } else {
                  subjectInput.addColleagues.push({
                    firstName: colleague.firstName,
                    lastName: colleague.lastName,
                    email: colleague.email,
                    phoneNumber: colleague.phoneNumber
                      ? colleague.phoneNumber
                      : null,
                    secondaryPhoneNumber: colleague.secondaryPhoneNumber
                      ? colleague.secondaryPhoneNumber
                      : null,
                    secondaryEmail: colleague.secondaryEmail
                      ? colleague.secondaryEmail
                      : null,
                    smsNotificationsEnabled: colleague.smsNotificationsEnabled,
                    emailNotificationsEnabled:
                      colleague.emailNotificationsEnabled,
                  })
                }
              })
              subjectInput.removeColleagueIds = values.removeColleagues.map(
                (subjectId) => subjectId
              )
              if (values.addressLineOne) {
                subjectInput.subjectLocationInput = {
                  addressLineOne: values.addressLineOne,
                  addressLineTwo: values.addressLineTwo
                    ? values.addressLineTwo
                    : null,
                  city: values.city,
                  state: values.state,
                  zipCode: values.zipCode,
                }
              }
              if (values.organizationId) {
                subjectInput.organizationId = values.organizationId
              }

              let addSubjectGroupIds = []
              let removeSubjectGroupIds = []
              values.subjectGroups.map(({ name, id }) => {
                if (
                  !updateSubject?.subjectGroups?.edges.find(
                    (subjectGroup) => subjectGroup.node.id === id
                  )
                ) {
                  addSubjectGroupIds.push(id)
                }
              })

              updateSubject?.subjectGroups?.edges.map((subjectGroup) => {
                if (
                  !values.subjectGroups.find(
                    ({ name, id }) => id === subjectGroup.node.id
                  )
                ) {
                  removeSubjectGroupIds.push(subjectGroup.node.id)
                }
              })

              subjectInput.addSubjectGroupIds = addSubjectGroupIds
              subjectInput.removeSubjectGroupIds = removeSubjectGroupIds
              updateSubjectMutation({
                variables: {
                  updateSubjectInput: {
                    subjectInput,
                  },
                },
              })
            }
          }
        }}
      >
        {(formik) => {
          return (
            <Form onSubmit={formik.handleSubmit}>
              <div className={showFormBorder ? 'border rounded p-3' : ''}>
                <Form.Row>
                  <Form.Group as={Col} md={4}>
                    <Form.Label>First Name</Form.Label>
                    <Field
                      name="firstName"
                      className="form-control form-control-sm"
                      disabled={cantUpdate}
                    />
                    <ErrorMessage name="firstName">
                      {(msg) => <span className="text-danger">{msg}</span>}
                    </ErrorMessage>
                  </Form.Group>
                  <Form.Group as={Col} md={4}>
                    <Form.Label>Last Name</Form.Label>
                    <Field
                      name="lastName"
                      className="form-control form-control-sm"
                      disabled={studentViewing || cantUpdate}
                    />
                    <ErrorMessage name="lastName">
                      {(msg) => <span className="text-danger">{msg}</span>}
                    </ErrorMessage>
                  </Form.Group>
                  {!cantSeeMetadata && !cantUpdate && (
                    <Form.Group>
                      <Form.Label className="mb-2">Login Access</Form.Label>
                      <Form.Row>
                        <Col xs={12} md={4} style={{ marginLeft: '35px' }}>
                          <Form.Check
                            name="active"
                            disabled={cantUpdate}
                            style={{ width: '20px', height: '20px' }}
                            checked={formik.values.active}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.active}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.active}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Row>
                    </Form.Group>
                  )}
                </Form.Row>
                {studentViewing || !subjectViewing ? (
                  <Form.Row>
                    <Form.Group as={Col} md={4}>
                      <Form.Label>
                        {updateSubject?.organization?.category === 'SCHOOL' ||
                        formik.values.schoolOrganization
                          ? 'Student ID'
                          : 'ID Number'}
                      </Form.Label>
                      <Field
                        name="studentId"
                        className="form-control form-control-sm"
                        disabled={studentViewing || cantUpdate}
                      />
                      <ErrorMessage name="studentId">
                        {(msg) => <span className="text-danger">{msg}</span>}
                      </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} md={4}>
                      <Form.Label>Job Title</Form.Label>
                      <Field
                        name="jobTitle"
                        className="form-control form-control-sm"
                        disabled={studentViewing || cantUpdate}
                      />
                      <ErrorMessage name="jobTitle">
                        {(msg) => <span className="text-danger">{msg}</span>}
                      </ErrorMessage>
                    </Form.Group>
                  </Form.Row>
                ) : null}
                {(studentViewing || !subjectViewing) &&
                  !cantSeeMetadata &&
                  updateSubject &&
                  !bookSessionFlow && (
                    <>
                      <Form.Group>
                        <Form.Row>
                          <Col xs={12} md={4}>
                            <Form.Label className="mb-0">Last Login</Form.Label>
                            <Form.Control
                              disabled={true}
                              value={
                                updateSubject.gaiaUser.lastLogin
                                  ? formatDateTimeToString(
                                      moment(
                                        updateSubject.gaiaUser.lastLogin
                                      ).toDate()
                                    )
                                  : 'Never'
                              }
                            />
                          </Col>
                          <Col xs={12} md={4}>
                            <Form.Label className="mb-0">
                              Last Login Location
                            </Form.Label>
                            <Form.Control
                              disabled={true}
                              value={
                                updateSubject.gaiaUser.loginLocation
                                  ? updateSubject.gaiaUser.loginLocation
                                  : ''
                              }
                            />
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </>
                  )}
                <Form.Row>
                  <Form.Group as={Col} md={4}>
                    <Form.Label>Email</Form.Label>
                    <Field
                      name="email"
                      autoComplete="off"
                      className="form-control form-control-sm"
                      disabled={cantUpdate}
                    />
                    <ErrorMessage name="email">
                      {(msg) => <span className="text-danger">{msg}</span>}
                    </ErrorMessage>
                  </Form.Group>
                  <Form.Group as={Col} md={4}>
                    <Form.Label>Secondary Email</Form.Label>
                    <Field
                      name="secondaryEmail"
                      className="form-control form-control-sm"
                      disabled={cantUpdate}
                    />
                    <ErrorMessage name="secondaryEmail">
                      {(msg) => <span className="text-danger">{msg}</span>}
                    </ErrorMessage>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={4}>
                    <Form.Label>Phone Number</Form.Label>
                    <PhoneInput
                      inputClass="w-100"
                      country="us"
                      name="phoneNumber"
                      regions={['north-america']}
                      value={formik.values.phoneNumber}
                      disabled={cantUpdate}
                      placeholder=""
                      onChange={(pValue) =>
                        formik.setFieldValue(`phoneNumber`, pValue)
                      }
                    />
                    <ErrorMessage name="phoneNumber">
                      {(msg) => <span className="text-danger">{msg}</span>}
                    </ErrorMessage>
                  </Form.Group>
                  <Form.Group as={Col} md={4}>
                    <Form.Label>Secondary Phone Number</Form.Label>
                    <PhoneInput
                      inputClass="w-100"
                      country="us"
                      name="secondaryPhoneNumber"
                      regions={['north-america']}
                      placeholder=""
                      value={formik.values.secondaryPhoneNumber}
                      disabled={cantUpdate}
                      onChange={(pValue) =>
                        formik.setFieldValue(`secondaryPhoneNumber`, pValue)
                      }
                    />
                    <ErrorMessage name="secondaryPhoneNumber">
                      {(msg) => <span className="text-danger">{msg}</span>}
                    </ErrorMessage>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <>
                    <Form.Group as={Col} md={4}>
                      <Form.Label>Password</Form.Label>
                      <Field
                        name="password"
                        autoComplete="new-password"
                        disabled={cantUpdate}
                        className="form-control form-control-sm"
                        type="password"
                      />
                      <ErrorMessage name="password">
                        {(msg) => <span className="text-danger">{msg}</span>}
                      </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} md={4}>
                      <Form.Label>Confirm Password</Form.Label>
                      <Field
                        name="confirmPassword"
                        disabled={cantUpdate}
                        className="form-control form-control-sm"
                        type="password"
                      />
                      <ErrorMessage name="confirmPassword">
                        {(msg) => <span className="text-danger">{msg}</span>}
                      </ErrorMessage>
                    </Form.Group>
                  </>
                </Form.Row>
                {(settings?.showLocationBooking ||
                  (!bookSessionFlow && !location.pathname !== '/book')) && (
                  <>
                    <Form.Row>
                      <Form.Group as={Col} md={4}>
                        <Form.Label>Address Line One</Form.Label>
                        <Field
                          name="addressLineOne"
                          disabled={cantUpdate}
                          className="form-control form-control-sm"
                        />
                        <ErrorMessage name="addressLineOne">
                          {(msg) => <span className="text-danger">{msg}</span>}
                        </ErrorMessage>
                      </Form.Group>

                      <Form.Group as={Col} md={4}>
                        <Form.Label>Address Line Two</Form.Label>
                        <Field
                          name="addressLineTwo"
                          disabled={cantUpdate}
                          className="form-control form-control-sm"
                        />
                        <ErrorMessage name="addressLineTwo">
                          {(msg) => <span className="text-danger">{msg}</span>}
                        </ErrorMessage>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md={4}>
                        <Form.Label>City</Form.Label>
                        <Field
                          name="city"
                          disabled={cantUpdate}
                          className="form-control form-control-sm"
                        />
                        <ErrorMessage name="city">
                          {(msg) => <span className="text-danger">{msg}</span>}
                        </ErrorMessage>
                      </Form.Group>
                      <Form.Group as={Col} md={4}>
                        <Form.Label>
                          {settings.locationFormat === 'US'
                            ? 'State'
                            : 'Province'}
                        </Form.Label>
                        <select
                          className="form-select form-select-sm"
                          name="state"
                          disabled={cantUpdate}
                          onChange={formik.handleChange}
                          value={formik.values.state ?? ''}
                        >
                          <option></option>
                          {settings.locationFormat === 'US'
                            ? US_STATES.map((state) => (
                                <option key={state} value={state}>
                                  {state}
                                </option>
                              ))
                            : CANADIAN_PROVINCES.map((province) => (
                                <option key={province} value={province}>
                                  {province}
                                </option>
                              ))}
                        </select>
                        <ErrorMessage name="state">
                          {(msg) => <span className="text-danger">{msg}</span>}
                        </ErrorMessage>
                      </Form.Group>
                      <Form.Group as={Col} md={2}>
                        <Form.Label>Zip Code</Form.Label>
                        <Field
                          name="zipCode"
                          disabled={cantUpdate}
                          className="form-control form-control-sm"
                        />
                        <ErrorMessage name="zipCode">
                          {(msg) => <span className="text-danger">{msg}</span>}
                        </ErrorMessage>
                      </Form.Group>
                    </Form.Row>
                  </>
                )}

                {!studentViewing && !subjectViewing && !contact && (
                  <>
                    <Form.Label
                      className={
                        !studentViewing && !subjectViewing ? '' : 'd-none'
                      }
                    >
                      Notes
                    </Form.Label>
                    <Form.Row
                      className={
                        !studentViewing && !subjectViewing ? '' : 'd-none'
                      }
                    >
                      <Form.Group as={Col} md={10}>
                        <Field
                          name="notes"
                          as="textarea"
                          disabled={cantUpdate}
                          className="form-control form-control-sm"
                        />
                        <ErrorMessage name="notes">
                          {(msg) => <span className="text-danger">{msg}</span>}
                        </ErrorMessage>
                      </Form.Group>
                    </Form.Row>
                  </>
                )}

                <>
                  {!organization && (
                    <>
                      <Form.Label
                        className={
                          !studentViewing && !subjectViewing ? '' : 'd-none'
                        }
                      >
                        Organization
                      </Form.Label>
                      <Form.Row
                        className={
                          !studentViewing && !subjectViewing ? '' : 'd-none'
                        }
                      >
                        <Form.Group as={Col} md={4}>
                          <Form.Control
                            value={formik.values.organizationName}
                            onChange={(e) => handleOrgChange(formik, e)}
                            disabled={
                              subjectViewing ||
                              cantUpdate ||
                              Boolean(formik.values.organizationId) ||
                              loggedInUser.permissions.isOrgContact
                            }
                            placeholder="Search Organizations"
                            className={
                              formik.values.organizationId
                                ? ' border border-success form-control form-control-sm'
                                : 'form-control form-control-sm'
                            }
                          />
                          {showOrgResults ? (
                            <Table size="sm" hover>
                              <tbody>
                                {formik.values.organizationName &&
                                  organizationData?.organizations.edges.map(
                                    (organization) => {
                                      const { node } = organization
                                      return (
                                        <tr
                                          onMouseDown={() =>
                                            handleOrgClick(formik, node)
                                          }
                                          key={node.id}
                                          className="hover text-decoration-none"
                                        >
                                          <td>
                                            <small>{node.name}</small>
                                          </td>
                                        </tr>
                                      )
                                    }
                                  )}
                              </tbody>
                            </Table>
                          ) : null}
                          <ErrorMessage name="organization">
                            {(msg) => (
                              <span className="text-danger">{msg}</span>
                            )}
                          </ErrorMessage>
                        </Form.Group>
                        {formik.values.organizationId &&
                          !organization &&
                          !contact &&
                          !cantUpdate && (
                            <Col style={{ marginTop: '4px' }}>
                              <button
                                type="button"
                                className="p-0 mr-1 btn-link"
                                onClick={() => {
                                  handleOrganizationXClick(formik)
                                }}
                              >
                                <Trash />
                              </button>
                            </Col>
                          )}
                      </Form.Row>
                    </>
                  )}

                  {!subjectGroupId && (
                    <>
                      <Form.Label
                        className={
                          !studentViewing && !subjectViewing ? '' : 'd-none'
                        }
                      >
                        Subject Groups
                      </Form.Label>
                      <Form.Row
                        className={
                          (!studentViewing && !subjectViewing) || cantUpdate
                            ? ''
                            : 'd-none'
                        }
                      >
                        <Form.Group as={Col} md={4}>
                          <Form.Control
                            as="select"
                            value={'def'}
                            className={'form-control-sm'}
                            name="subjectGroups"
                            disabled={!formik.values.organizationId}
                            onChange={(e) => handleSubjectGroupClick(e, formik)}
                          >
                            <option key="def" value="def">
                              Select Subject Groups
                            </option>
                            {subjectGroups
                              .filter(
                                (subjectGroup) =>
                                  !formik.values.subjectGroups.some(
                                    (selectedGroup) =>
                                      selectedGroup.id === subjectGroup.id
                                  )
                              )
                              .map((subjectGroup) => {
                                return (
                                  <option
                                    key={subjectGroup.id}
                                    value={subjectGroup.id}
                                  >
                                    {subjectGroup.name}
                                  </option>
                                )
                              })}
                          </Form.Control>
                          <div className="mt-2">
                            {formik.values.subjectGroups?.length > 0 && (
                              <>
                                {formik.values.subjectGroups?.map(
                                  ({ name, id }, i) => (
                                    <span
                                      style={{
                                        padding: '5px',
                                        border: '.5px solid #6c757d',
                                        borderRadius: '0.25rem',
                                        marginLeft: i > 0 ? '0.5rem' : '0',
                                      }}
                                      key={id}
                                    >
                                      <Trash
                                        className="mr-2 btn-link"
                                        onClick={() => {
                                          deleteSubjectGroupClick(id, formik)
                                        }}
                                      />
                                      {name}
                                    </span>
                                  )
                                )}
                              </>
                            )}
                            {formik.values.subjectGroups?.length === 0 && (
                              <>- - -</>
                            )}
                          </div>
                        </Form.Group>
                      </Form.Row>
                    </>
                  )}

                  <Form.Label className={displayBilling ? '' : 'd-none'}>
                    Billing
                  </Form.Label>
                  {updateSubject && stripePaymentMethodResource && (
                    <Row className={displayBilling ? 'mb-3' : 'd-none'}>
                      <Col xs={12} md={4}>
                        <div className="d-block mt-1">
                          <span className="text-uppercase mr-2 font-weight-bold">
                            {stripePaymentMethodResource.card.brand
                              .charAt(0)
                              .toUpperCase() +
                              stripePaymentMethodResource.card.brand.slice(1)}
                          </span>
                          <span className="mr-2">
                            ending in {stripePaymentMethodResource.card.last4}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {!cantUpdate && (
                    <>
                      {displayBilling && stripePaymentMethodResource && (
                        <Form.Label>New Payment Method</Form.Label>
                      )}
                      <Form.Row className={displayBilling ? '' : 'd-none'}>
                        <Col md={6}>
                          {!hideCardElement && (
                            <CardElement
                              onChange={(event) => {
                                setStripeCardElementEmpty(event.empty)
                                if (stripeCardElementErrors) {
                                  setStripeCardElementErrors()
                                }
                              }}
                              options={{
                                style: {
                                  base: {
                                    fontSize: '18px',
                                  },
                                },
                              }}
                            />
                          )}
                          {stripeCardElementErrors ? (
                            <Row className="mt-3">
                              <Col xs={12} md={{ span: 8 }}>
                                <p
                                  style={{
                                    color: '#dc3545',
                                  }}
                                >
                                  {stripeCardElementErrors}
                                </p>
                              </Col>
                            </Row>
                          ) : null}
                        </Col>
                      </Form.Row>
                    </>
                  )}
                  <Form.Label className="mt-2">Notifications</Form.Label>
                  <Form.Row>
                    <Form.Group as={Col}>
                      {!subjectId && (
                        <Form.Check
                          inline
                          label="Receive Welcome Email"
                          checked={!formik.values.skipWelcomeEmail}
                          disabled={cantUpdate}
                          onChange={(e) =>
                            formik.setFieldValue(
                              'skipWelcomeEmail',
                              !e.target.checked
                            )
                          }
                        />
                      )}

                      <Form.Check
                        inline
                        label="Receive SMS"
                        checked={formik.values.smsNotificationsEnabled}
                        disabled={cantUpdate}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'smsNotificationsEnabled',
                            e.target.checked
                          )
                        }
                      />

                      <Form.Check
                        inline
                        label="Receive Email"
                        checked={formik.values.emailNotificationsEnabled}
                        disabled={cantUpdate}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'emailNotificationsEnabled',
                            e.target.checked
                          )
                        }
                      />
                    </Form.Group>
                  </Form.Row>
                  {loggedInUser.permissions.isEmployee && (
                    <>
                      <Row className="mt-1">
                        <Col>
                          <Form.Label>Files</Form.Label>
                        </Col>
                      </Row>
                      <Row style={{ marginLeft: '-10px' }}>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Check
                              name="sharedCanSeeFiles"
                              type="checkbox"
                              label="Subject Can See Files"
                              disabled={cantUpdate}
                              checked={formik.values.sharedCanSeeFiles}
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  `sharedCanSeeFiles`,
                                  e.target.checked
                                )
                              }}
                            />
                          </Form.Group>
                        </Col>
                        {formik.values.sharedCanSeeFiles && (
                          <>
                            <Col md={3}>
                              <Form.Group>
                                <Form.Check
                                  name="sharedCanCreateFiles"
                                  type="checkbox"
                                  disabled={cantUpdate}
                                  label="Subject Can Create Files"
                                  checked={formik.values.sharedCanCreateFiles}
                                  onChange={(e) => {
                                    formik?.setFieldValue(
                                      `sharedCanCreateFiles`,
                                      e.target.checked
                                    )
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group>
                                <Form.Check
                                  name="sharedCanCreateFolders"
                                  type="checkbox"
                                  disabled={cantUpdate}
                                  label="Subject Can Create Folders"
                                  checked={formik.values.sharedCanCreateFolders}
                                  onChange={(e) => {
                                    formik?.setFieldValue(
                                      `sharedCanCreateFolders`,
                                      e.target.checked
                                    )
                                  }}
                                />
                              </Form.Group>
                            </Col>
                          </>
                        )}
                      </Row>
                    </>
                  )}

                  {(studentViewing || !subjectViewing) && (
                    <>
                      {formik.values.parents.length > 0 && <h5>Contacts</h5>}
                      <FieldArray
                        name="parents"
                        render={(arrayHelpers) => (
                          <div>
                            {formik.values.parents.map((parent, index) => (
                              <div
                                key={index}
                                className="border border-secondary rounded mt-3 p-3"
                              >
                                <Form.Row>
                                  <Form.Group as={Col} md={4}>
                                    <Form.Label>First Name</Form.Label>
                                    <Field
                                      disabled={cantUpdate}
                                      name={`parents[${index}].firstName`}
                                      className="form-control form-control-sm"
                                    />
                                    <ErrorMessage
                                      name={`parents[${index}].firstName`}
                                    >
                                      {(msg) => (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )}
                                    </ErrorMessage>
                                  </Form.Group>
                                  <Form.Group as={Col} md={4}>
                                    <Form.Label>Last Name</Form.Label>
                                    <Field
                                      disabled={cantUpdate}
                                      name={`parents[${index}].lastName`}
                                      className="form-control form-control-sm"
                                    />
                                    <ErrorMessage
                                      name={`parents[${index}].lastName`}
                                    >
                                      {(msg) => (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )}
                                    </ErrorMessage>
                                  </Form.Group>
                                  {!cantSeeMetadata && !cantUpdate && (
                                    <>
                                      <Form.Group>
                                        <Form.Label className="mb-2">
                                          Login Access
                                        </Form.Label>
                                        <Form.Row>
                                          <Col
                                            xs={12}
                                            md={4}
                                            style={{ marginLeft: '35px' }}
                                          >
                                            <Form.Check
                                              name={`parents[${index}].active`}
                                              disabled={cantUpdate}
                                              style={{
                                                width: '20px',
                                                height: '20px',
                                              }}
                                              checked={
                                                formik.values.parents[index]
                                                  .active
                                              }
                                              onChange={(e) => {
                                                formik.setFieldValue(
                                                  `parents[${index}].active`,
                                                  e.target.checked
                                                )
                                              }}
                                            />
                                          </Col>
                                        </Form.Row>
                                      </Form.Group>
                                    </>
                                  )}
                                </Form.Row>

                                <Form.Row>
                                  <Form.Group as={Col} md={4}>
                                    <Form.Label>Email</Form.Label>
                                    <Field
                                      name={`parents[${index}].email`}
                                      className="form-control form-control-sm"
                                      disabled={cantUpdate}
                                    />
                                    <ErrorMessage
                                      name={`parents[${index}].email`}
                                    >
                                      {(msg) => (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )}
                                    </ErrorMessage>
                                  </Form.Group>
                                  <Form.Group as={Col} md={4}>
                                    <Form.Label>Secondary Email</Form.Label>
                                    <Field
                                      name={`parents[${index}].secondaryEmail`}
                                      className="form-control form-control-sm"
                                      disabled={cantUpdate}
                                    />
                                    <ErrorMessage
                                      name={`parents[${index}].secondaryEmail`}
                                    >
                                      {(msg) => (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )}
                                    </ErrorMessage>
                                  </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                  <Form.Group as={Col} md={4}>
                                    <Form.Label>Phone Number</Form.Label>
                                    <PhoneInput
                                      inputClass="w-100"
                                      country="us"
                                      disabled={cantUpdate}
                                      placeholder=""
                                      name={`parents[${index}].phoneNumber`}
                                      regions={['north-america']}
                                      value={
                                        formik.values.parents[index].phoneNumber
                                      }
                                      onChange={(pValue) =>
                                        formik.setFieldValue(
                                          `parents[${index}].phoneNumber`,
                                          pValue
                                        )
                                      }
                                    />
                                    <ErrorMessage
                                      name={`parents[${index}].phoneNumber`}
                                    >
                                      {(msg) => (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )}
                                    </ErrorMessage>
                                  </Form.Group>
                                  <Form.Group as={Col} md={4}>
                                    <Form.Label>
                                      Secondary Phone Number
                                    </Form.Label>
                                    <PhoneInput
                                      inputClass="w-100"
                                      country="us"
                                      disabled={cantUpdate}
                                      placeholder=""
                                      name="secondaryPhoneNumber"
                                      regions={['north-america']}
                                      value={
                                        formik.values.parents[index]
                                          .secondaryPhoneNumber
                                      }
                                      onChange={(pValue) =>
                                        formik.setFieldValue(
                                          `parents[${index}].secondaryPhoneNumber`,
                                          pValue
                                        )
                                      }
                                    />
                                    <ErrorMessage
                                      name={`parents[${index}].secondaryPhoneNumber`}
                                    >
                                      {(msg) => (
                                        <span className="text-danger">
                                          {msg}
                                        </span>
                                      )}
                                    </ErrorMessage>
                                  </Form.Group>
                                </Form.Row>

                                {!subjectViewing &&
                                !studentViewing &&
                                !cantUpdate ? (
                                  <>
                                    <Row className="mt-2">
                                      <Form.Group as={Col} md={4}>
                                        <Form.Label>Password</Form.Label>
                                        <Field
                                          name={`parents[${index}].password`}
                                          className="form-control form-control-sm"
                                          type="password"
                                        />
                                        <ErrorMessage
                                          name={`parents[${index}].password`}
                                        >
                                          {(msg) => (
                                            <span className="text-danger">
                                              {msg}
                                            </span>
                                          )}
                                        </ErrorMessage>
                                      </Form.Group>
                                      <Form.Group as={Col} md={4}>
                                        <Form.Label>
                                          Confirm Password
                                        </Form.Label>
                                        <Field
                                          name={`parents[${index}].confirmPassword`}
                                          className="form-control form-control-sm"
                                          type="password"
                                        />
                                        <ErrorMessage
                                          name={`parents[${index}].confirmPassword`}
                                        >
                                          {(msg) => (
                                            <span className="text-danger">
                                              {msg}
                                            </span>
                                          )}
                                        </ErrorMessage>
                                      </Form.Group>
                                    </Row>
                                  </>
                                ) : null}
                                <Form.Label>Notification</Form.Label>
                                <Form.Row>
                                  <Form.Group as={Col}>
                                    {!formik.values.parents[index].id && (
                                      <Form.Check
                                        inline
                                        label="Receive Welcome Email"
                                        checked={
                                          !formik.values.parents[index]
                                            .skipWelcomeEmail
                                        }
                                        disabled={cantUpdate}
                                        onChange={(e) => {
                                          formik.setFieldValue(
                                            `parents[${index}].skipWelcomeEmail`,
                                            !e.target.checked
                                          )
                                        }}
                                      />
                                    )}

                                    <Form.Check
                                      inline
                                      label="Receive SMS"
                                      disabled={cantUpdate}
                                      checked={
                                        formik.values.parents[index]
                                          .smsNotificationsEnabled
                                      }
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          `parents[${index}].smsNotificationsEnabled`,
                                          e.target.checked
                                        )
                                      }
                                    />

                                    <Form.Check
                                      inline
                                      label="Receive Email"
                                      disabled={cantUpdate}
                                      checked={
                                        formik.values.parents[index]
                                          .emailNotificationsEnabled
                                      }
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          `parents[${index}].emailNotificationsEnabled`,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </Form.Group>
                                </Form.Row>
                                {!cantUpdate && (
                                  <div className="d-flex align-items-center">
                                    <div
                                      className="hover btn-link"
                                      onClick={() => {
                                        if (
                                          updateSubject &&
                                          formik.values.parents[index].id
                                        ) {
                                          const removeParents =
                                            formik.values.removeParents
                                          removeParents.push(
                                            formik.values.parents[index].id
                                          )
                                          formik.setFieldValue(
                                            'removeParents',
                                            removeParents
                                          )
                                        }
                                        arrayHelpers.remove(index)
                                      }}
                                    >
                                      <Trash className="mr-2" />
                                      <small>Remove</small>
                                    </div>
                                    {location.pathname.includes('/subject/') &&
                                      location.pathname !==
                                        '/subject/settings' &&
                                      formik.values.parents[index].id && (
                                        <div
                                          className="btn-link ml-2"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            history.push(
                                              `/subject/${formik.values.parents[index].id}`
                                            )
                                          }}
                                        >
                                          <Person size={18} className="mr-1" />
                                          <small>Open Subject</small>
                                        </div>
                                      )}
                                  </div>
                                )}
                              </div>
                            ))}
                            {!cantUpdate && (
                              <Button
                                variant="link"
                                className={
                                  formik.values &&
                                  formik.values.parents.length > 0
                                    ? 'mt-3'
                                    : ''
                                }
                                onClick={() =>
                                  arrayHelpers.push({
                                    firstName: '',
                                    lastName: '',
                                    email: '',
                                    secondaryEmail: '',
                                    phoneNumber: '',
                                    active: true,
                                    secondaryPhoneNumber: '',
                                    password: '',
                                    confirmPassword: '',
                                    smsNotificationsEnabled: true,
                                    emailNotificationsEnabled: true,
                                  })
                                }
                              >
                                <PlusCircle
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  className="mr-2"
                                />
                                Add Contact
                              </Button>
                            )}
                          </div>
                        )}
                      />
                    </>
                  )}
                </>
              </div>
              {!cantSeeMetadata && !bookSessionFlow && (
                <>
                  <Row>
                    <Col md={6}>
                      <MetaDataTable
                        metaData={formik.values.metadata}
                        setMetaData={(d) => formik.setFieldValue('metadata', d)}
                        noMessage
                        edit={!cantUpdate}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                {!cantUpdate && (
                  <Col md={bookSessionFlow ? 3 : 2}>
                    <Button
                      onClick={() => {
                        if (
                          (contact &&
                            (subjectGroups.length === 0 ||
                              formik.values.subjectGroups.length > 0)) ||
                          !contact
                        ) {
                          formik.handleSubmit()
                          if (
                            formik.errors &&
                            Object.values(formik.errors).length !== 0
                          ) {
                            toast.error('Form Fields Invalid')
                          }
                        } else {
                          setShowConfirmCreateSubjectModal(true)
                        }
                      }}
                      variant="outline-primary"
                      block
                      className="my-4"
                      disabled={submitting}
                    >
                      {bookSessionFlow ? (
                        <>
                          <ArrowRightCircle className="mr-2" />
                          Book Session
                        </>
                      ) : (
                        <>
                          <PlusCircle className="mr-2" />
                          Save
                        </>
                      )}
                    </Button>
                  </Col>
                )}
                {displayMerge && canMutation && (
                  <Col md={2}>
                    <Button
                      variant="outline-primary"
                      className="my-4"
                      block
                      onClick={() => toggleMergeIntoSubjectModal()}
                    >
                      <SignMergeLeft className="mr-2" />
                      Merge
                    </Button>
                  </Col>
                )}
                {allowDelete && !cantDelete && (
                  <Col md={2}>
                    <Button
                      variant="outline-danger"
                      className="my-4"
                      block
                      onClick={() => toggleDeleteModal()}
                    >
                      <Trash className="mr-2" />
                      Delete
                    </Button>
                  </Col>
                )}
              </Row>
              {(submitting && newSubject) || updateSubjectLoading ? (
                <Row>
                  <Col>
                    <Loading
                      message={bookSessionFlow ? '' : 'Saving Subject...'}
                    />
                  </Col>
                </Row>
              ) : null}
              {showConfirmCreateSubjectModal && (
                <ConfirmCreateSubjectModal
                  toggleModal={() => setShowConfirmCreateSubjectModal(false)}
                  showModal={showConfirmCreateSubjectModal}
                  handleSubmit={() => {
                    setShowConfirmCreateSubjectModal(false)
                    formik.handleSubmit()
                  }}
                  subjectGroupName={subjectGroups[0].name}
                />
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
