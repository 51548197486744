import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap'
import { PlusCircle, Trash } from 'react-bootstrap-icons'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import DeleteTagModal from './DeleteTagModal'
import TagModal from './TagModal'

const TagTable = (props) => {
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [checkedIds, setCheckedIds] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [tag, setTag] = useState()
  const defaultOrderBy = 'name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedIds((prevState) => prevState.filter((id) => id !== row.node.id))
    }
  }

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query Tags($cursor: String, $searchTerm: String, $orderBy: String) {
        tags(
          first: 50
          after: $cursor
          orderBy: $orderBy
          name_Icontains: $searchTerm
        ) {
          nodeCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              name
              taggitTaggeditemItems {
                nodeCount
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      query({
        variables: {
          orderBy: defaultOrderBy,
        },
      })
    }
  }, [initialQueryRun, setInitialQueryRun])

  useEffect(() => {
    if (queryData?.tags) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.tags?.pageInfo?.endCursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    queryFetchMore({
      variables,
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setLoadingSearch(true)
    const variables = {
      searchTerm: currentSearchTerm,
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    query({
      variables,
    })
  }

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    query({ variables })
  }

  const onCellClick = (cell) => {
    toggleModal(cell.row.original.node)
  }

  const toggleModal = (node = null) => {
    if (node) {
      setTag(node)
    } else {
      setTag()
    }
    if (showForm) {
      setShowForm(false)
    } else {
      setShowForm(true)
    }
  }

  if (!initialQueryRun) return <></>
  if (queryError) return <>Error loading</>
  return (
    <>
      <>
        <Row className="mb-1">
          <Col>
            <div>
              <Button
                variant="link"
                onClick={() => {
                  toggleModal()
                }}
              >
                <PlusCircle className="mr-2" />
                New Tag
              </Button>
              {checkedIds.length > 0 && (
                <Button
                  variant="link"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                >
                  <Trash className="mr-2" />
                  {checkedIds.length === 1 ? <>Delete Tag</> : <>Delete Tags</>}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={'Search Tags'}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-2 mb-3">
          <Col md="6">
            <SortableInfiniteTable
              loading={loadingSearch || !queryData}
              tableData={queryData?.tags?.edges ? queryData.tags.edges : []}
              loadingMessage="Loading Tags..."
              tableColumns={[
                {
                  Header: 'Name',
                  id: 'name',
                  accessor: 'node.name',
                  serverSort: true,
                },
                {
                  Header: 'Associated Records',
                  id: 'recordCount',
                  serverSort: false,
                  accessor: 'node.taggitTaggeditemItems.nodeCount',
                },
                {
                  disableSortBy: true,
                  Header: (
                    <>
                      <Form.Group
                        as={ButtonGroup}
                        className="align-items-center"
                      >
                        <Form.Check
                          className="ml-2 mt-2"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              const appendIds = []
                              queryData.tags.edges.forEach((tag) => {
                                if (!checkedIds.includes(tag.node.id)) {
                                  appendIds.push(tag.node.id)
                                }
                              })
                              setCheckedIds((prevState) => {
                                return [...prevState, ...appendIds]
                              })
                            } else {
                              setCheckedIds([])
                            }
                          }}
                        />
                        {checkedIds.length > 0 && (
                          <span style={{ fontSize: '14px', marginTop: '5px' }}>
                            ({checkedIds.length})
                          </span>
                        )}
                      </Form.Group>
                    </>
                  ),
                  id: 'actions',
                  accessor: (row) => {
                    return (
                      <>
                        <Form.Group
                          as={ButtonGroup}
                          className="align-items-center"
                        >
                          <Form.Check
                            className="ml-2 mt-2"
                            type="checkbox"
                            checked={checkedIds.includes(row.node.id)}
                            onChange={(e) => handleCheck(e, row)}
                          />
                        </Form.Group>
                      </>
                    )
                  },
                },
              ]}
              fetchMoreTableData={fetchMore}
              hasMoreTableData={queryData?.tags?.pageInfo?.hasNextPage}
              onTdClicks={{
                name: (cell) => onCellClick(cell),
                recordCount: (cell) => onCellClick(cell),
              }}
              tableHeight={700}
              rowPointer
              hideGlobalFilter
              handleSortByChange={handleSortByChange}
            />
          </Col>
        </Row>
      </>
      {showForm && (
        <TagModal showModal={showForm} tag={tag} toggleModal={toggleModal} />
      )}
      <DeleteTagModal
        showModal={showDeleteModal}
        toggleModal={() => {
          setShowDeleteModal(false)
        }}
        setCheckedIds={setCheckedIds}
        ids={checkedIds.length > 1 ? checkedIds : null}
        id={checkedIds.length === 1 ? checkedIds[0] : null}
      />
    </>
  )
}

export default TagTable
