import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'

const EmployeeJobSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    setAdditionalFields,
    formatDescription,
    placeholder,
    error,
    query,
    variables,
    dropdown,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="employeeJob"
      searchDescription={
        searchDescription ? searchDescription : 'Employee Jobs'
      }
      nodeNamePlural="employeeJobs"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      placeholder={placeholder}
      setAdditionalFields={setAdditionalFields}
      variables={variables}
      dropdown={dropdown}
      gql={
        query
          ? query
          : gql`
              query EmployeeJobs(
                $first: Int
                $after: String
                $search: String
                $job: ID
              ) {
                employeeJobs(
                  first: $first
                  after: $after
                  search: $search
                  orderBy: "job__start_date_time"
                  job: $job
                ) {
                  nodeCount
                  pageInfo {
                    hasNextPage
                    endCursor
                  }
                  edges {
                    node {
                      id
                      description
                    }
                  }
                }
              }
            `
      }
      formatDescription={(node) => {
        if (formatDescription) {
          return formatDescription(node)
        } else {
          return node.description
        }
      }}
    />
  )
}

export default EmployeeJobSearchInput
