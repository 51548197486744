import React, { useState } from 'react'
import { Importer, ImporterField } from 'react-csv-importer'
import 'react-csv-importer/dist/index.css'
import { v4 as uuidv4 } from 'uuid'

export default function OrganizationCsvImporter(props) {
  const {
    setRowFatalError,
    rowFatalError,
    setRowsMissingInfo,
    updateCsvData,
    importerKey,
  } = props

  const [transformedData, setTransformedData] = useState([])

  const validatePhone = (phone) => {
    let cleanPhone = phone.replace(/[^\w]/g, '')
    if (cleanPhone.length == 10) {
      cleanPhone = `${1}${cleanPhone}`
    }
    if (isNaN(parseInt(cleanPhone)) || cleanPhone.charAt(0) != 1) {
      cleanPhone = false
    }
    return cleanPhone
  }

  const isValidHexColor = (hex) => {
    const hexPattern = /^#([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$/i
    return hexPattern.test(hex)
  }

  return (
    <>
      <Importer
        key={importerKey}
        defaultNoHeader={false}
        restartable={false}
        dataHandler={(rows, { startIndex }) => {
          const batchTransformedData = []
          let allErrors = []
          const seenEmails = []
          const organizationStage = {}
          const stageColorObj = {}
          rows.forEach((row, i) => {
            const errors = []
            let contactPhone
            let contactSecondaryPhone
            let organizationName = row.organizationName
              .replace(/\s+/g, ' ')
              .trim()
            let fatal = false
            const uuid = uuidv4()
            if (!organizationName) {
              errors.push({
                fatal: true,
                row: startIndex + i + 1,
                column: 'Organization Name',
                error: 'Required',
              })
              fatal = true
              if (!rowFatalError) {
                setRowFatalError(true)
              }
            }
            if (row.contactPhone) {
              contactPhone = validatePhone(row.contactPhone)
              if (!contactPhone) {
                errors.push({
                  fatal: false,
                  row: startIndex + i + 1,
                  column: 'Contact Phone Number',
                  error:
                    'Phone number must be either 10 or 11 digits, and can only contain numbers, spaces, - and ()',
                })
              }
            }
            if (row.contactSecondaryPhone) {
              contactSecondaryPhone = validatePhone(row.contactSecondaryPhone)
              if (!contactSecondaryPhone) {
                errors.push({
                  fatal: false,
                  row: startIndex + i + 1,
                  column: 'Contact Secondary Phone Number',
                  error:
                    'Phone number must be either 10 or 11 digits, and can only contain numbers, spaces, - and ()',
                })
              }
            }
            if (row.contactEmail) {
              if (seenEmails.includes(row.contactEmail)) {
                errors.push({
                  fatal: false,
                  row: startIndex + i + 1,
                  column: 'Contact Email',
                  error:
                    'This email was found assocaited to another contact email in the upload.',
                })
                row.contactEmail = null
              } else {
                seenEmails.push(row.contactEmail)
              }
            }
            if (row.stageColor && !row.stageName) {
              errors.push({
                fatal: true,
                row: startIndex + i + 1,
                column: 'Stage Name',
                error: 'Required for Stage',
              })
              fatal = true
              if (!rowFatalError) {
                setRowFatalError(true)
              }
            }
            if (organizationName in organizationStage) {
              if (organizationStage[organizationName] != row.stageName) {
                errors.push({
                  fatal: true,
                  row: startIndex + i + 1,
                  column: 'Stage Name',
                  error: 'One organization can have only one stage',
                })
                fatal = true
                if (!rowFatalError) {
                  setRowFatalError(true)
                }
              }
            } else {
              organizationStage[organizationName] = row.stageName || ''
            }
            if (row.stageColor) {
              if (!isValidHexColor(row.stageColor)) {
                errors.push({
                  fatal: true,
                  row: startIndex + i + 1,
                  column: 'Stage Color',
                  error: 'Invalid hex color code.',
                })
                fatal = true
                if (!rowFatalError) {
                  setRowFatalError(true)
                }
              } else if (row.stageName) {
                if (row.stageName in stageColorObj) {
                  if (stageColorObj[row.stageName] != row.stageColor) {
                    errors.push({
                      fatal: true,
                      row: startIndex + i + 1,
                      column: 'Stage Color',
                      error: 'One stage can have only one hex color code.',
                    })
                    fatal = true
                    if (!rowFatalError) {
                      setRowFatalError(true)
                    }
                  }
                } else {
                  stageColorObj[row.stageName] = row.stageColor || ''
                }
              }
            }
            const contact = {
              uuid,
              organizationName: organizationName,
              contactFirstName: row.contactFirstName || null,
              contactLastName: row.contactLastName || null,
              contactJobTitle: row.contactJobTitle || null,
              contactEmail: row.contactEmail || null,
              contactSecondaryEmail: row.contactSecondaryEmail || null,
              contactPhone: row.contactPhone || null,
              contactSecondaryPhone: row.contactSecondaryPhone || null,
              contactPassword: row.contactPassword || null,
              contactLoginAccess: row.contactLoginAccess || null,
              stageColor: row.stageColor || null,
              stageName: row.stageName || null,
              addressLineOne: row.addressLineOne || null,
              addressLineTwo: row.addressLineTwo || null,
              city: row.city || null,
              state: row.state || null,
              zip: row.zip || null,
              taskCollectionName: row.taskCollectionName || null,
            }
            if (!fatal) {
              batchTransformedData.push(contact)
            }
            if (errors.length) {
              allErrors = [...allErrors, ...errors]
            }
          })
          if (allErrors.length > 0) {
            setRowsMissingInfo((prevState) => [...prevState, ...allErrors])
          }
          setTransformedData((prevState) => [
            ...prevState,
            ...batchTransformedData,
          ])
        }}
        onComplete={() => {
          updateCsvData(transformedData)
          setTransformedData([])
        }}
        skipEmptyLines
      >
        <ImporterField
          name="organizationName"
          label="organization name"
          optional
        />
        <ImporterField
          name="contactFirstName"
          label="contact first name"
          optional
        />
        <ImporterField
          name="contactLastName"
          label="contact last name"
          optional
        />
        <ImporterField
          name="contactJobTitle"
          label="contact job title"
          optional
        />
        <ImporterField name="contactEmail" label="contact email" optional />
        <ImporterField
          name="contactSecondaryEmail"
          label="contact secondary email"
          optional
        />
        <ImporterField name="contactPhone" label="contact phone" optional />
        <ImporterField
          name="contactSecondaryPhone"
          label="contact secondary phone"
          optional
        />
        <ImporterField
          name="contactPassword"
          label="contact password"
          optional
        />
        <ImporterField
          name="contactLoginAccess"
          label="contact login access"
          optional
        />
        <ImporterField name="stageName" label="stage name" optional />
        <ImporterField name="stageColor" label="stage color" optional />
        <ImporterField
          name="addressLineOne"
          label="address line one"
          optional
        />
        <ImporterField
          name="addressLineTwo"
          label="address line two"
          optional
        />
        <ImporterField name="city" label="city" optional />
        <ImporterField name="state" label="state" optional />
        <ImporterField name="zip" label="zip" optional />
        <ImporterField
          name="taskCollectionName"
          label="task collection name"
          optional
        />
      </Importer>
    </>
  )
}
