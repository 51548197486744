import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql, useMutation } from '@apollo/client'
import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import ContactModal from './ContactModal'
import { loggedInUserVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import {
  CloudArrowDown,
  PersonRolodex,
  PlusCircle,
  Trash,
} from 'react-bootstrap-icons'
import DeleteContactModal from './DeleteContactModal'
import Loading from '../common/Loading'
import { useDownloadFile } from '../../libs/downloadFile'
import toast from 'react-hot-toast'

const Contacts = (props) => {
  const { organization } = props
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const { downloadAndDeleteFile } = useDownloadFile()
  const [checkedIds, setCheckedIds] = useState([])
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const canMutate =
    ['Administrator', 'Scheduling Manager', 'Organization Contact'].includes(
      loggedInUser?.permissions?.group
    ) || loggedInUser.permissions.isOrgContact
  const defaultOrderBy = 'full_name'
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [cursor, setCursor] = useState()
  const [updateContact, setUpdateContact] = useState()
  const [contacts, setContacts] = useState([])
  const [initialQueryRun, setInitialQueryRun] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showContactModal, setShowContactModal] = useState(false)
  const [downloadingPdf, setDownloadingPdf] = useState(false)
  const [downloadingExcel, setDownloadingExcel] = useState(false)

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedIds((prevState) => prevState.filter((id) => id !== row.node.id))
    }
  }

  const [downloadContacts] = useMutation(
    gql`
      mutation DownloadContacts($input: DownloadContactsInput!) {
        downloadContacts(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.downloadContacts.file.fileName,
          data.downloadContacts.file.displayName,
          data.downloadContacts.file.id,
          () => {
            if (downloadingPdf) {
              toast.success(`PDF Downloaded`)
              setDownloadingPdf(false)
            }
            if (downloadingExcel) {
              toast.success(`Excel Downloaded`)
              setDownloadingExcel(false)
            }
          }
        )
      },
      errorPolicy: 'all',
    }
  )

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query OrgContacts(
        $cursor: String
        $searchTerm: String
        $organization: [ID]
        $orderBy: String
      ) {
        gaiaUsers(
          organizationContacts: $organization
          organizationContacts_Isnull: false
          first: 20
          after: $cursor
          fullNameEmailOrganization: $searchTerm
          orderBy: $orderBy
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              contentType {
                model
                id
              }
              emailNotificationsEnabled
              smsNotificationsEnabled
              notes
              receiveInvoiceEmails
              email
              firstName
              lastName
              secondaryEmail
              fullName
              phoneNumber
              jobTitle
              id
              secondaryPhoneNumber
              isActive
              addressLineOne
              addressLineTwo
              city
              state
              zipCode
              tasks {
                edges {
                  node {
                    description
                    taskCollection {
                      name
                    }
                    dueDate
                    eventDate
                    finishedDate
                    organization {
                      name
                      id
                    }
                    employees {
                      edges {
                        node {
                          gaiaUser {
                            fullName
                            email
                          }
                        }
                      }
                    }
                    contacts {
                      edges {
                        node {
                          fullName
                          email
                        }
                      }
                    }
                    status {
                      name
                      id
                    }
                  }
                }
              }
              organizationContacts {
                nodeCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (!initialQueryRun) {
      setInitialQueryRun(true)
      const variables = { orderBy: defaultOrderBy }
      if (organization) {
        variables.organization = [organization.id]
      }
      query({ variables })
    }
  }, [initialQueryRun])

  const handleSortByChange = (currentOrderBy) => {
    if (currentOrderBy === '' && orderBy === defaultOrderBy) return

    currentOrderBy = currentOrderBy ? currentOrderBy : defaultOrderBy
    setOrderBy(currentOrderBy)
    const variables = {
      orderBy: currentOrderBy,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (organization) {
      variables.organization = [organization.id]
    }
    query({ variables })
  }

  useEffect(() => {
    if (queryData?.gaiaUsers) {
      if (loadingSearch) {
        setLoadingSearch(false)
      }
      if (queryData.gaiaUsers.pageInfo.endCursor) {
        setCursor(queryData.gaiaUsers.pageInfo.endCursor)
      }
      setHasMoreResults(queryData.gaiaUsers.pageInfo.hasNextPage)
      const _contacts = []
      setContacts(() => {
        const seenContacts = []
        queryData.gaiaUsers.edges.forEach((contact) => {
          const gaiaUserNode = contact.node
          if (!seenContacts.includes(gaiaUserNode.id)) {
            seenContacts.push(gaiaUserNode.id)
            let email = ''
            if (gaiaUserNode.email) {
              email = gaiaUserNode.email
            }
            if (gaiaUserNode.secondaryEmail) {
              if (gaiaUserNode.email) {
                email = `${email}, ${gaiaUserNode.secondaryEmail}`
              } else {
                email = gaiaUserNode.secondaryEmail
              }
            }
            let phone = ''
            if (gaiaUserNode.phoneNumber) {
              phone = gaiaUserNode.phoneNumber
            }
            if (gaiaUserNode.secondaryPhoneNumber) {
              if (gaiaUserNode.phoneNumber) {
                phone = `${phone}, ${gaiaUserNode.secondaryPhoneNumber}`
              } else {
                phone = gaiaUserNode.secondaryPhoneNumber
              }
            }
            _contacts.push({
              node: gaiaUserNode,
              name: `${gaiaUserNode.fullName}`,
              email,
              phone,
              receiveInvoiceEmails: gaiaUserNode.receiveInvoiceEmails,
              jobTitle: gaiaUserNode.jobTitle,
              id: gaiaUserNode.id,
            })
          }
        })
        return _contacts
      })
    }
  }, [queryData])

  const fetchMore = () => {
    const variables = {
      cursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (organization) {
      variables.organization = [organization.id]
    }
    if (orderBy) {
      variables.orderBy = orderBy
    }
    queryFetchMore({
      variables,
    })
  }

  const handleSearchTermChange = (event) => {
    const currentSearchTerm = event.target.value
    setSearchTerm(currentSearchTerm)
    setCursor(null)
    const variables = {
      cursor,
    }
    if (searchTerm) {
      variables.searchTerm = searchTerm
    }
    if (organization) {
      variables.organization = [organization.id]
    }
    setLoadingSearch(true)
    if (orderBy) {
      variables.orderBy = orderBy
    }
    query({ variables })
  }

  const openContact = (cell) => {
    toggleContactModal()
    setUpdateContact(cell.row.original.node)
  }

  const toggleContactModal = () => {
    if (updateContact) {
      setUpdateContact()
    }
    setShowContactModal((prevShowOrgModal) => !prevShowOrgModal)
  }

  const tableColumns = [
    {
      Header: 'Job Title',
      id: 'jobTitle',
      accessor: 'jobTitle',
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: 'name',
      serverSort: true,
      orderBy: 'full_name',
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: 'email',
      serverSort: true,
    },
    {
      Header: 'Phone',
      id: 'phone',
      accessor: 'phone',
    },
    {
      Header: 'Receive Invoice Emails',
      id: 'invoiceEmails',
      accessor: (row) => {
        if (row.receiveInvoiceEmails) {
          return 'Yes'
        } else {
          return 'No'
        }
      },
    },
  ]
  if (!organization) {
    tableColumns.push({
      Header: 'Organizations',
      id: 'organization',
      accessor: (row) => {
        let organizations = null
        row.node.organizationContacts.edges.forEach((org) => {
          const node = org.node
          if (organizations === null) {
            organizations = `${node.name}`
          } else {
            organizations = `${organizations}, ${node.name}`
          }
        })
        return organizations
      },
    })
  }
  if (canMutate) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  contacts.forEach((contact) => {
                    if (!checkedIds.includes(contact.node.id)) {
                      appendIds.push(contact.node.id)
                    }
                  })
                  setCheckedIds((prevState) => {
                    return [...prevState, ...appendIds]
                  })
                } else {
                  setCheckedIds([])
                }
              }}
            />
          </Form.Group>
        </>
      ),
      id: 'actions',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedIds.includes(row.node.id)}
                onChange={(e) => handleCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }

  if (!initialQueryRun && !queryData) return <></>
  if (queryError) return <>Error loading contacts</>

  return (
    <>
      {showContactModal ? (
        <ContactModal
          showModal={showContactModal}
          toggleModal={toggleContactModal}
          updateContact={updateContact}
          organization={organization}
        />
      ) : null}
      <div>
        <Row>
          <Col md={4}>
            <Form.Group>
              <Form.Control
                type="text"
                name="searchTerm"
                className="form-control-sm"
                placeholder={'Search Contacts'}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </Form.Group>
          </Col>
          {canMutate && (
            <>
              <Col className="d-flex justify-content-end align-items-center">
                <Button variant="link" onClick={toggleContactModal}>
                  <PlusCircle className="mr-2" />
                  New Contact
                </Button>
                <Button
                  variant="link"
                  disabled={downloadingExcel || downloadingPdf}
                  onClick={() => {
                    setDownloadingPdf(true)
                    downloadContacts({
                      variables: {
                        input: {
                          organizationId: organization ? organization.id : null,
                          fileType: 'pdf',
                        },
                      },
                    })
                  }}
                >
                  {downloadingPdf ? (
                    <Loading height={'20'} />
                  ) : (
                    <>
                      <CloudArrowDown className="mr-2" />
                      <span>Download PDF</span>
                    </>
                  )}
                </Button>
                <Button
                  variant="link"
                  disabled={downloadingExcel || downloadingPdf}
                  onClick={() => {
                    setDownloadingExcel(true)
                    downloadContacts({
                      variables: {
                        input: {
                          organizationId: organization ? organization.id : null,
                          fileType: 'xlsx',
                        },
                      },
                    })
                  }}
                >
                  {downloadingExcel ? (
                    <Loading height={'20'} />
                  ) : (
                    <>
                      <CloudArrowDown className="mr-2" />
                      <span>Download Excel</span>
                    </>
                  )}
                </Button>
              </Col>
            </>
          )}
          {canMutate && checkedIds.length > 0 && (
            <Col
              md={1}
              className="d-flex justify-content-end align-items-center ml-5"
            >
              <Button variant="link" onClick={setShowDeleteModal}>
                <Trash className="mr-2" />
                {checkedIds.length === 1 ? (
                  <>Delete Contact</>
                ) : (
                  <>Delete Contacts</>
                )}
              </Button>
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          <Col>
            <SortableInfiniteTable
              tableColumns={tableColumns}
              loading={loadingSearch}
              tableData={contacts}
              fetchMoreTableData={fetchMore}
              loadingMessage="Loading Contacts..."
              hasMoreTableData={hasMoreResults}
              onTdClicks={{
                jobTitle: (cell) => openContact(cell),
                name: (cell) => openContact(cell),
                email: (cell) => openContact(cell),
                phone: (cell) => openContact(cell),
                secondaryEmail: (cell) => openContact(cell),
                secondaryPhone: (cell) => openContact(cell),
                organization: (cell) => openContact(cell),
                invoiceEmails: (cell) => openContact(cell),
              }}
              tableHeight={700}
              rowPointer
              hideGlobalFilter
              handleSortByChange={handleSortByChange}
            />
          </Col>
        </Row>

        <DeleteContactModal
          ids={checkedIds.length > 1 ? checkedIds : null}
          id={checkedIds.length === 1 ? checkedIds[0] : null}
          setCheckedIds={setCheckedIds}
          showModal={showDeleteModal}
          toggleModal={setShowDeleteModal}
        />
      </div>
    </>
  )
}
export default Contacts
