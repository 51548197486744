import React, { useState } from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import toast from 'react-hot-toast'
import { Archive } from 'react-bootstrap-icons'
import Loading from '../common/Loading'

const ArchiveTaskModal = (props) => {
  const { showModal, toggleModal, taskId, taskIds, onComplete, archive } = props
  const [submitting, setSubmitting] = useState(false)

  const [update] = useMutation(
    gql`
      mutation UpdateTask($input: UpdateTaskInput!) {
        updateTask(input: $input) {
          task {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (taskIds) {
          toast.success('Tasks Archived')
        } else {
          toast.success('Task Archived')
        }
        toggleModal()
        if (onComplete) {
          onComplete()
        }
      },
      errorPolicy: 'all',
      refetchQueries: [
        'Organization',
        'Tasks',
        'OrganizationDetail',
        'SubjectGroupsQuery',
        'JobDetailQuery',
      ],
    }
  )

  const handleArchiveClick = () => {
    setSubmitting(true)
    update({
      variables: {
        input: {
          taskInput: {
            ids: taskId ? [taskId] : taskIds,
            archive,
          },
        },
      },
    })
  }

  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">
              {archive ? 'Archive' : 'Unarchive'} Task{taskIds && <>s</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="text-center">
                <p style={{ fontSize: '1rem' }}>
                  Are you sure you want to {archive ? 'archive' : 'unarchive'}{' '}
                  the task
                  {taskIds && <>s</>}?
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <Button
                  variant={archive ? 'outline-danger' : 'outline-primary'}
                  size="sm"
                  block
                  disabled={submitting}
                  onClick={handleArchiveClick}
                >
                  <Archive className="mr-2" />
                  {archive ? 'Archive' : 'Unarchive'}
                </Button>
              </Col>
            </Row>
            {submitting && <Loading />}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default ArchiveTaskModal
