import React from 'react'
import { gql } from '@apollo/client'
import SearchInput from './SearchInput'
import { DateTime } from 'luxon'

const JobSearchInput = (props) => {
  const {
    formik,
    disabled,
    formikValue,
    searchDescription,
    setAdditionalFields,
    error,
    placeholder,
    query,
    variables,
    dropdown,
  } = props
  return (
    <SearchInput
      formik={formik}
      nodeName="job"
      placeholder={placeholder}
      searchDescription={searchDescription ? searchDescription : 'Jobs'}
      nodeNamePlural="jobs"
      formikValue={formikValue}
      disabled={disabled}
      error={error}
      setAdditionalFields={setAdditionalFields}
      variables={variables}
      dropdown={dropdown}
      gql={
        query
          ? query
          : gql`
              query Jobs(
                $first: Int
                $after: String
                $search: String
                $startDateTime: DateTime
              ) {
                jobs(
                  first: $first
                  after: $after
                  orderBy: "name"
                  name_Icontains: $search
                  startDateTime_Date: $startDateTime
                ) {
                  nodeCount
                  pageInfo {
                    hasNextPage
                    endCursor
                  }
                  edges {
                    node {
                      id
                      name
                      startDateTime
                      endDateTime
                      description
                    }
                  }
                }
              }
            `
      }
      formatDescription={(node) => {
        return node.description
      }}
    />
  )
}

export default JobSearchInput
