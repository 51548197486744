import React, { useState, useEffect } from 'react'
import { useQuery, gql, useReactiveVar } from '@apollo/client'
import { Row, Col, Table } from 'react-bootstrap'
import { DateTime } from 'luxon'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { CaretDown, CaretRight } from 'react-bootstrap-icons'
import { settingsVar } from '../../libs/apollo'
import moment from 'moment'

const SessionHistory = (props) => {
  const { sessionId, onlyTable, onDisplay } = props
  const [display, setDisplay] = useState(false)
  const [sessionHistory, setSessionHistory] = useState([])
  const settings = useReactiveVar(settingsVar)
  const { data: session } = useQuery(
    gql`
      query SessionQuery($sessionId: ID!) {
        session(id: $sessionId) {
          allFutureSessions {
            history {
              id
              timestamp
              objectPk
              changes
              action
              objectRepr
              actor {
                fullName
                email
                subject {
                  id
                }
                employee {
                  id
                }
                organizationContacts {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
              contentType {
                model
                id
              }
            }
          }
          allPreviousSessions {
            history {
              id
              timestamp
              objectPk
              changes
              action
              objectRepr
              actor {
                fullName
                email
                subject {
                  id
                }
                employee {
                  id
                }
                organizationContacts {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
              contentType {
                model
                id
              }
            }
          }
          history {
            id
            timestamp
            objectPk
            changes
            action
            objectRepr
            apiCalls {
              edges {
                node {
                  id
                  apiKey {
                    name
                  }
                }
              }
            }
            actor {
              fullName
              email
              subject {
                id
              }
              employee {
                id
              }
              organizationContacts {
                edges {
                  node {
                    id
                  }
                }
              }
            }
            contentType {
              model
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        sessionId,
      },
    }
  )

  useEffect(() => {
    if (onDisplay) {
      onDisplay(display)
    }
  }, [display])

  useEffect(() => {
    if (session) {
      const history = []
      session.session.allPreviousSessions.forEach((previousSession) => {
        previousSession.history.forEach((log) => {
          history.push(log)
        })
      })
      session.session.history.forEach((log) => {
        history.push(log)
      })
      session.session.allFutureSessions.forEach((futureSession) => {
        futureSession.history.forEach((log) => {
          history.push(log)
        })
      })
      history.sort(
        (a, b) =>
          moment(b.timestamp).toDate() - new moment(a.timestamp).toDate()
      )
      setSessionHistory(history)
    }
  }, [session])
  if (!session) return <></>
  return (
    <>
      <div>
        {!onlyTable && (
          <Row className="mb-1 mt-4">
            <Col className="d-flex align-items-center">
              <button
                type="button"
                onClick={() => setDisplay(!display)}
                className="px-0 btn-link mr-1"
              >
                <>
                  {display ? (
                    <>
                      <CaretDown size={18} />
                    </>
                  ) : (
                    <>
                      <CaretRight size={18} />
                    </>
                  )}
                </>
              </button>
              <h5 className="mb-0">History</h5>
            </Col>
          </Row>
        )}

        {(onlyTable || display) && (
          <>
            <Row className="mt-4">
              <Col>
                <SortableInfiniteTable
                  tableColumns={[
                    {
                      Header: 'Time',
                      accessor: (row) => {
                        const dateTime = DateTime.fromISO(row.timestamp)
                        return dateTime
                          .setZone(settings.timezone)
                          .toFormat('MMMM dd yyyy hh:mma')
                      },
                    },
                    {
                      Header: 'Action',
                      accessor: (row) => {
                        let action
                        if (row.action.includes('0')) {
                          action = 'Create'
                        } else if (row.action.includes('1')) {
                          action = 'Update'
                        } else if (row.action.includes('2')) {
                          action = 'Delete'
                        } else if (row.action.includes('3')) {
                          action = 'Access'
                        }
                        return action
                      },
                    },
                    {
                      Header: 'User',
                      accessor: (row) => {
                        return row.actor?.fullName
                      },
                    },
                    {
                      Header: 'User Type',
                      accessor: (row) => {
                        let type
                        if (row.actor?.employee?.id) {
                          type = 'Employee'
                        } else if (row.actor?.subject?.id) {
                          type = 'Subject'
                        } else if (
                          row.node?.actor?.organizationContacts?.edges?.length >
                          0
                        ) {
                          type = 'Organization Contact'
                        } else if (row.node?.apiCalls?.edges.length > 0) {
                          type = 'API'
                        } else {
                          type = 'System'
                        }
                        return type
                      },
                    },
                    {
                      Header: 'Changes',
                      accessor: (row) => {
                        if (row.changes) {
                          let action
                          if (row.action.includes('0')) {
                            action = 'Create'
                          } else if (row.action.includes('1')) {
                            action = 'Update'
                          } else if (row.action.includes('2')) {
                            action = 'Delete'
                          } else if (row.action.includes('3')) {
                            action = 'Access'
                          }
                          if (action === 'Create') {
                            return (
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Field</th>
                                    <th>Value</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(JSON.parse(row.changes)).map(
                                    (values, i) => {
                                      let field = values[0]
                                      if (field === 'gaia_user') {
                                        field = 'user'
                                      }
                                      return (
                                        <tr key={i}>
                                          <td>{field}</td>
                                          <td>{values[1][1]}</td>
                                        </tr>
                                      )
                                    }
                                  )}
                                </tbody>
                              </Table>
                            )
                          } else if (action === 'Update') {
                            return (
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Field</th>
                                    <th>Intial</th>
                                    <th>Update</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(JSON.parse(row.changes)).map(
                                    (values, i) => {
                                      let field = values[0]
                                      if (field === 'gaia_user') {
                                        field = 'user'
                                      }
                                      return (
                                        <tr key={i}>
                                          <td>{field}</td>
                                          <td>{values[1][0]}</td>
                                          <td>{values[1][1]}</td>
                                        </tr>
                                      )
                                    }
                                  )}
                                </tbody>
                              </Table>
                            )
                          }
                        }
                      },
                    },
                  ]}
                  tableData={sessionHistory}
                  hasMoreTableData={false}
                  tableHeight={500}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  )
}
export default SessionHistory
