import React, { useState, useEffect } from 'react'
import { Survey } from 'survey-react-ui'
import { Model } from 'survey-core'
import 'survey-core/defaultV2.min.css'
import { useParams } from 'react-router-dom'
import { useLazyQuery, gql, useMutation, useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import { useHistory } from 'react-router-dom'

const SubmitForm = () => {
  const { formId, records } = useParams()
  const [formModel, setFormModel] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const history = useHistory()

  const onComplete = (sender) => {
    createSubmitForm({
      variables: {
        input: {
          submitFormInput: {
            formId: formId,
            submission: JSON.stringify(sender.data),
            records: records,
          },
        },
      },
    })
  }

  const [createSubmitForm] = useMutation(
    gql`
      mutation CreateSubmitForm($input: CreateSubmitFormInput!) {
        createSubmitForm(input: $input) {
          submitForm {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setFormSubmitted(true)
      },
    }
  )

  const [query] = useLazyQuery(
    gql`
      query Form($id: ID!) {
        form(id: $id) {
          id
          form
          public
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onCompleted: (data) => {
        if (!data.form.public && !loggedInUser) {
          history.push('/')
        }
        let form_ = new Model(data.form.form)
        form_.onComplete.add(onComplete)
        setFormModel(form_)
      },
    }
  )

  useEffect(() => {
    query({ variables: { id: formId } })
  }, [formId])

  if (!formModel) return <></>

  return (
    <div>
      {!formSubmitted && <Survey model={formModel} />}
      {formSubmitted && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
            textAlign: 'center',
            marginTop: '20px',
            marginBottom: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '10px',
            padding: '20px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            color: '#555',
          }}
        >
          Thank you for your submission!
        </div>
      )}
    </div>
  )
}

export default SubmitForm
