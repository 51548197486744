import React, { useState, useEffect } from 'react'
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client'
import {
  Search,
  Trash,
  PlusCircle,
  PersonWorkspace,
  ArrowLeft,
} from 'react-bootstrap-icons'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import validator from 'validator'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import PhoneInput from 'react-phone-input-2'
import { US_STATES } from '../../libs/constant'
import { client, loggedInUserVar } from '../../libs/apollo'
import Loading from '../common/Loading'
import PermissionTable from './PermissionTable'

const NewEmployeeModal = (props) => {
  const { showModal, toggleModal } = props
  let roles = []
  let groups = []
  const [submitting, setSubmitting] = useState(false)
  const [showPermissions, setShowPermissions] = useState(false)
  const [smtpConnector, setSmtpConnector] = useState()

  const { loading: loadingSubscription, data: subscriptionData } = useQuery(
    gql`
      query TenantSubscriptionUsageQuery {
        tenantSubscriptionUsage {
          subscription
          usedUsers
          includedUsers
          usedSessionPackages
          includedSessionPackages
          usedNotifications
          includedNotifications
          usedFileStorage
          includedFileStorage
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const EMAIL_CHECK = gql`
    query NewSubjectFormGaiaUsersQuery($emailIexact: String) {
      gaiaUsers(email_Iexact: $emailIexact) {
        nodeCount
        edges {
          node {
            email
          }
        }
      }
    }
  `
  const ROLES_QUERY = gql`
    query RolesQuery {
      roles(archived: false) {
        edges {
          node {
            id
            name
            hourlyRate
          }
        }
      }
    }
  `
  const GROUPS_QUERY = gql`
    query GroupsQuery {
      groups {
        edges {
          node {
            name
            id
          }
        }
      }
    }
  `
  const CREATE_EMPLOYEE_MUTATION = gql`
    mutation CreateEmployee($createEmployeeInput: CreateEmployeeInput!) {
      createEmployee(input: $createEmployeeInput) {
        employee {
          id
        }
      }
    }
  `
  const EMPLOYEES_QUERY = gql`
    query EmployeesQuery($cursor: String) {
      employees(first: 20, after: $cursor, orderBy: "gaia_user__full_name") {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            gaiaUser {
              firstName
              lastName
            }
            defaultRole {
              name
            }
            internalEmployeeId
          }
        }
      }
    }
  `
  const [rolesQuery, { error: rolesQueryError, data: rolesQueryData }] =
    useLazyQuery(ROLES_QUERY, {
      fetchPolicy: 'network-only',
    })
  const [groupsQuery, { data: groupsQueryData }] = useLazyQuery(GROUPS_QUERY, {
    fetchPolicy: 'network-only',
  })
  const [createEmployeeMutation] = useMutation(CREATE_EMPLOYEE_MUTATION, {
    onCompleted: (data) => {
      setSubmitting(false)
      innerToggleModel()
      toast.success(`Employee Created`)
    },
    onError: (data) => {
      formik.setErrors({
        email: `User with email ${formik.values.email} already exists`,
      })
    },
    refetchQueries: [
      {
        query: EMPLOYEES_QUERY,
        fetchPolicy: 'network-only',
      },
    ],
  })
  const handleRolesQueryData = () => {
    const currentRoles = []
    rolesQueryData.roles.edges.forEach((role) => {
      currentRoles.push(role.node)
    })
    return currentRoles
  }
  const handleGroupsQueryData = () => {
    const currentGroups = []
    groupsQueryData.groups.edges.forEach((group) => {
      if (
        group.node.name !== 'Subject' &&
        group.node.name !== 'Organization Contact'
      ) {
        currentGroups.push(group.node)
      }
    })
    return currentGroups
  }
  const [selectedRoles, setSelectedRoles] = useState([])

  function handleRoleClick(e) {
    const selectedValue = e.target.value
    if (!selectedRoles.find((role) => role.id === selectedValue)) {
      const roleData = roles.find((role) => role.id === selectedValue)
      setSelectedRoles([...selectedRoles, roleData])
    }
  }

  function deleteRoleClick(id) {
    if (selectedRoles.find((role) => role.id === id)) {
      const filteredArray = selectedRoles.filter((role) => role.id !== id)
      setSelectedRoles(filteredArray)
    }
  }

  const { data: smtpData } = useQuery(
    gql`
      query SMTPConnector {
        smtpConnectors {
          edges {
            node {
              customHost
              customPort
              customUser
              customTls
              customSsl
              customPassword
              customValid
              customSendgridSender
              customGmail
              customGmailAuth
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (showModal) {
      rolesQuery()
      groupsQuery()
    }
  }, [showModal])

  useEffect(() => {
    if (smtpData) {
      const smtpConnectorData = smtpData.smtpConnectors.edges[0].node
      formik.setFieldValue('smtpHost', smtpConnectorData.customHost)
      formik.setFieldValue('smtpTls', smtpConnectorData.customTls)
      formik.setFieldValue('smtpSsl', smtpConnectorData.customSsl)
      formik.setFieldValue('smtpPort', smtpConnectorData.customPort)
      formik.setFieldValue('smtpGmail', smtpConnectorData.customGmail)
      if (smtpConnectorData.customSendgridSender) {
        formik.setFieldValue('smtpUser', smtpConnectorData.customUser)
        formik.setFieldValue('smtpPassword', smtpConnectorData.customPassword)
      }
      setSmtpConnector(smtpConnectorData)
    }
  }, [smtpData])

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      active: true,
      phoneNumber: '',
      internalEmployeeId: '',
      smsNotificationsEnabled: true,
      emailNotificationsEnabled: true,
      password: '',
      confirmPassword: '',
      defaultRoleId: '',
      groupId: '',
      addressLineOne: '',
      addressLineTwo: '',
      city: '',
      state: 'Illinois',
      zipCode: '',
      hourlyPay: null,
      salesPercent: 0,
      rainPercent: 0,
      taskPercent: 0,
      servicePercent: 0,
      photoPercent: 0,
      payCommission: 0,
      smtpHost: '',
      smtpPort: 0,
      smtpUser: '',
      smtpTls: false,
      smtpPassword: '',
      smtpGmail: false,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .min(2, 'First name is too short')
        .max(100, 'First name  is too long')
        .required('First name  is required'),
      lastName: Yup.string()
        .min(2, 'Last name  is too short')
        .max(100, 'Last name  is too long')
        .required('Last name  is required'),
      abbrevietedName: Yup.string()
        .min(2, 'Abbrevieted name  is too short')
        .max(100, 'Abbrevieted name  is too long')
        .nullable(),
      email: Yup.string()
        .min(2, 'Email  is too short')
        .max(100, 'Email  is too long')
        .required('Email  is required')
        .test('isEmail', 'Invalid email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        })
        .test(
          'isUnique',
          'Account with this email already exists',
          async (value) => {
            let valid = true
            if (value) {
              const { data } = await client.query({
                query: EMAIL_CHECK,
                fetchPolicy: 'network-only',
                variables: {
                  emailIexact: value,
                },
              })
              valid = data.gaiaUsers.nodeCount === 0
            }
            return valid
          }
        )
        .test('isEmail', 'Invalid email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
      smsNotificationsEnabled: Yup.bool().required('Required'),
      emailNotificationsEnabled: Yup.bool().required('Required'),
      secondaryEmail: Yup.string()
        .min(2, 'Email  is too short')
        .max(100, 'Email  is too long')
        .nullable()
        .test('isEmail', 'Invalid email', (value) => {
          let valid = true
          if (value && !validator.isEmail(value)) {
            valid = false
          }
          return valid
        }),
      secondaryPhoneNumber: Yup.string()
        .min(2, 'Phone number is too short')
        .max(100, 'Phone number is too long')
        .nullable()
        .test('isPhoneNumber', 'Invalid phone number', (value) => {
          let valid = true
          if (value && !validator.isMobilePhone(value, 'en-US')) {
            valid = false
          }
          return valid
        }),
      phoneNumber: Yup.string()
        .min(2, 'Phone number is too short')
        .max(100, 'Phone number is too long')
        .nullable()
        .test('isPhoneNumber', 'Invalid phone number', (value) => {
          let valid = true
          if (value && !validator.isMobilePhone(value)) {
            valid = false
          }
          return valid
        }),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
          'Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
        )
        .max(100, 'Password is too long')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .min(2, 'Confirm password is too short')
        .max(100, 'Confirm password is too long')
        .required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      addressLineOne: Yup.string()
        .min(1, 'Address line one is too short')
        .max(100, 'Address line one is too long')
        .nullable(),
      addressLineTwo: Yup.string()
        .min(1, 'Address line two is too short')
        .max(100, 'Address line two is too long')
        .nullable(),
      city: Yup.string()
        .min(1, 'City is too short')
        .max(100, 'City is too long')
        .nullable(),
      state: Yup.string()
        .min(3, 'State is too short')
        .max(100, 'State is too long')
        .nullable()
        .test('isState', 'Invalid state', (value) => {
          let valid = true
          if (value && !US_STATES.includes(value)) {
            valid = false
          }
          return valid
        }),
      zipCode: Yup.string()
        .min(3, 'Zip code is too short')
        .max(100, 'Zip code is too long')
        .nullable(),
      groupId: Yup.string()
        .required('Permissions are required')
        .test('isGroup', 'Invalid permissions', (value) => {
          let valid = false
          if (value) {
            groups.map((group) => {
              if (!valid && value === group.id) {
                valid = true
              }
            })
          }
          return valid
        }),
      active: Yup.bool().required('Enablement status is required'),
      smtpTls: Yup.boolean().nullable(),
      smtpHost: Yup.string().nullable(),
      smtpPort: Yup.number().nullable(),
      smtpUser: Yup.string().nullable(),
      smtpSsl: Yup.bool().nullable(),
      smtpSendgridSender: Yup.string().nullable(),
      hourlyPay: Yup.mixed().nullable(),
      salesPercent: Yup.number().integer().nullable().min(0).max(100),
      photoPercent: Yup.number().integer().nullable().min(0).max(100),
      payCommission: Yup.number().integer().nullable().min(0).max(100),
      rainPercent: Yup.number().integer().nullable().min(0).max(100),
      taskPercent: Yup.number().integer().nullable().min(0).max(100),
      servicePercent: Yup.number().integer().nullable().min(0).max(100),
      smtpGmail: Yup.boolean().nullable(),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      if (
        tenantSubscriptionUsage.usedUsers >=
        tenantSubscriptionUsage.includedUsers
      ) {
        toast.error(
          `You have reached the ${tenantSubscriptionUsage.includedUsers} maximum number of employee users for your ${tenantSubscriptionUsage.subscription} subscriptionplan. Upgrade your plan to add more users.`
        )
        setSubmitting(false)
        return
      }
      setSubmitting(true)
      if (
        values.smtpHost === 'smtp.gmail.com' &&
        !smtpConnector.customGmail &&
        !smtpConnector.customGmailAuth
      ) {
        toast.error(
          'Google workspace must be configured in global email settings.'
        )
        setSubmitting(false)
        return
      }
      createEmployeeMutation({
        variables: {
          createEmployeeInput: {
            employeeInput: {
              gaiaUserActive: values.active,
              gaiaUserFirstName: values.firstName,
              gaiaUserLastName: values.lastName,
              gaiaUserAbbreviatedName: values.abbrevietedName,
              gaiaUserEmail: values.email,
              gaiaUserSecondaryEmail: values.secondaryEmail,
              gaiaUserSecondaryPhoneNumber: values.secondaryPhoneNumber,
              gaiaUserPassword: values.password,
              gaiaUserPhoneNumber: values.phoneNumber,
              gaiaUserEmailNotificationsEnabled:
                values.emailNotificationsEnabled,
              gaiaUserSmsNotificationsEnabled: values.smsNotificationsEnabled,
              gaiaUserAddressLineOne: values.addressLineOne,
              gaiaUserAddressLineTwo: values.addressLineTwo,
              gaiaUserCity: values.city,
              gaiaUserState: values.state,
              gaiaUserZipCode: values.zipCode,
              salesPercent: values.salesPercent,
              photoPercent: values.photoPercent,
              payCommission: values.payCommission,
              rainPercent: values.rainPercent,
              taskPercent: values.taskPercent,
              servicePercent: values.servicePercent,
              smtpUser: values.smtpUser,
              smtpHost: values.smtpGmail ? null : values.smtpHost,
              smtpPort: values.smtpGmail ? null : values.smtpPort,
              smtpTls: values.smtpGmail ? null : values.smtpTls,
              smtpSsl: values.smtpSsl,
              smtpSendgridSender:
                values.smtpHost === 'smtp.sendgrid.net'
                  ? values.smtpSendgridSender
                  : null,
              smtpPassword: values.smtpGmail ? null : values.smtpPassword,
              groupId: values.groupId,
              hourlyPay: values.hourlyPay,
              roleIds: selectedRoles.map((role) => role.id),
              smtpGmail: values.smtpGmail,
            },
          },
        },
      })
    },
  })
  const innerToggleModel = () => {
    toggleModal()
    formik.resetForm()
  }
  if (rolesQueryError) return <>Error loading roles</>
  if (rolesQueryError) return <>Error loading permissions</>
  if (
    !rolesQueryData ||
    !groupsQueryData ||
    !showModal ||
    !subscriptionData ||
    !smtpData
  )
    return <Loading />
  roles = handleRolesQueryData()
  groups = handleGroupsQueryData()
  const { tenantSubscriptionUsage } = subscriptionData
  return (
    <>
      <Modal size="lg" show={showModal} onHide={innerToggleModel}>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {showPermissions ? (
                'Permissions'
              ) : (
                <>
                  <PersonWorkspace className="mr-2" />
                  New Employee
                </>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!showPermissions ? (
              <>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={4}>
                      <Form.Label className="mb-0">First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.firstName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.firstName}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label className="mb-0">Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.lastName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.lastName}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label className="mb-0">Abbreviated Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="abbrevietedName"
                        value={formik.values.abbrevietedName}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.abbrevietedName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.abbrevietedName}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        autoComplete="off"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.email}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Secondary Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="secondaryEmail"
                        value={formik.values.secondaryEmail}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.secondaryEmail}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.secondaryEmail}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Phone Number</Form.Label>
                      <PhoneInput
                        country="us"
                        name="phoneNumber"
                        placeholder=""
                        regions={['north-america']}
                        value={formik.values.phoneNumber}
                        onChange={(phone, country) =>
                          formik.setFieldValue('phoneNumber', phone)
                        }
                      />
                      {formik.errors.phoneNumber ? (
                        <span className="text-danger">
                          {formik.errors.phoneNumber}
                        </span>
                      ) : null}
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Secondary Number</Form.Label>
                      <PhoneInput
                        country="us"
                        placeholder=""
                        name="secondaryPhoneNumber"
                        regions={['north-america']}
                        value={formik.values.secondaryPhoneNumber}
                        onChange={(phone, country) =>
                          formik.setFieldValue('secondaryPhoneNumber', phone)
                        }
                      />
                      {formik.errors.secondaryPhoneNumber ? (
                        <span className="text-danger">
                          {formik.errors.secondaryPhoneNumber}
                        </span>
                      ) : null}
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={4}>
                      <Form.Label>Hourly Pay</Form.Label>
                      <Form.Control
                        type="number"
                        step="0.01"
                        min="0"
                        name="hourlyPay"
                        value={formik.values.hourlyPay}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.hourlyPay}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.hourlyPay}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label>Address Line One</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLineOne"
                        value={formik.values.addressLineOne}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.addressLineOne}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.addressLineOne}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label>Address Line two</Form.Label>
                      <Form.Control
                        type="text"
                        name="addressLineTwo"
                        value={formik.values.addressLineTwo}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.addressLineTwo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.addressLineTwo}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={4}>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.city}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.city}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label>State</Form.Label>

                      <Form.Control
                        name="state"
                        as="select"
                        onChange={formik.handleChange}
                        value={formik.values.state}
                        isInvalid={formik.errors.state}
                      >
                        {US_STATES.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.state}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Label>Zip Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="zipCode"
                        value={formik.values.zipCode}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.zipCode}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.zipCode}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Row>
                  <Col md={12}>
                    <p className="text-secondary title-space mb-1">
                      ACCESS RIGHTS
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <hr />
                  </Col>
                </Row>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        autoComplete="new-password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.password}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Confirm Password</Form.Label>

                      <Form.Control
                        type="password"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.confirmPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.confirmPassword}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Label className="mb-0">Roles</Form.Label>

                      <Form.Control
                        as="select"
                        value={'def'}
                        name="roles"
                        onChange={handleRoleClick}
                      >
                        <option key="def" value="def">
                          Select roles
                        </option>
                        {roles.map((role) => (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        ))}
                      </Form.Control>
                      <div className="mt-3">
                        {selectedRoles?.map(({ name, id }, i) => (
                          <span
                            style={{
                              padding: '5px',
                              border: '.5px solid #6c757d',
                              borderRadius: '0.25rem',
                              marginLeft: i > 0 ? '0.5rem' : '0',
                            }}
                            key={i}
                          >
                            <Trash
                              onClick={() => {
                                deleteRoleClick(id)
                              }}
                              className="mr-2 btn-link"
                            />
                            {name}
                          </span>
                        ))}
                      </div>
                    </Col>
                    <Col xs={12} md={6} style={{ marginTop: '-10px' }}>
                      <Form.Label className="mb-0">Permissions</Form.Label>
                      <Button
                        type="button"
                        onClick={() => setShowPermissions(!showPermissions)}
                        className="px-0 btn-link ml-2"
                      >
                        <>
                          <Search size={15} />
                        </>
                      </Button>
                      <Form.Control
                        style={{ marginTop: '-10px' }}
                        name="groupId"
                        as="select"
                        value={formik.values.groupId}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.groupId}
                      >
                        <option>Select permissions</option>
                        {groups.map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.groupId}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Form.Row>
                    <Col xs={12} md={2} className="d-flex align-items-center">
                      <Form.Label className="mb-0">Enabled</Form.Label>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Check
                        name="active"
                        style={{
                          width: '20px',
                          height: '20px',
                        }}
                        checked={formik.values.active}
                        onChange={(e) =>
                          formik.setFieldValue('active', e.target.checked)
                        }
                        isInvalid={formik.errors.active}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.active}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={{ span: 10, offset: 2 }}>
                      <Form.Label>
                        {formik.values.active ? (
                          <>
                            Enabled employees can login, be assigned to jobs and
                            will receive notifications
                          </>
                        ) : (
                          <>
                            Disabled employees cannot login or be assigned to
                            jobs, and will not receive notifications
                          </>
                        )}
                      </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.active}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={12}>
                      <p className="text-secondary title-space mb-1">
                        NOTIFCATIONS
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <hr />
                    </Col>
                  </Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Check
                        inline
                        label="Receive SMS"
                        checked={formik.values.smsNotificationsEnabled}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'smsNotificationsEnabled',
                            e.target.checked
                          )
                        }
                      />

                      <Form.Check
                        inline
                        label="Receive Email"
                        checked={formik.values.emailNotificationsEnabled}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'emailNotificationsEnabled',
                            e.target.checked
                          )
                        }
                      />
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
                {!loggedInUserVar}
                <Row className="mt-3">
                  <Col md={12}>
                    <p className="text-secondary title-space mb-1">
                      PERSONAL EMAIL SENDER
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <hr />
                  </Col>
                </Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Check
                      inline
                      label="Gmail"
                      checked={formik.values.smtpGmail}
                      onChange={(e) =>
                        formik.setFieldValue('smtpGmail', e.target.checked)
                      }
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Group>
                  <Row>
                    <Col xs={12} md={4}>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="string"
                        name="smtpUser"
                        value={formik.values.smtpUser}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.smtpUser}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.smtpUser}
                      </Form.Control.Feedback>
                    </Col>
                    {!formik.values.smtpGmail && (
                      <>
                        <Col xs={12} md={4}>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="smtpPassword"
                            value={formik.values.smtpPassword}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.smtpPassword}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.smtpPassword}
                          </Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={2}>
                          <Form.Label>Use TLS</Form.Label>

                          <Form.Check
                            name="smtpTls"
                            type="checkbox"
                            checked={formik.values.smtpTls}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.smtpTls}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.smtpTls}
                          </Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={2}>
                          <Form.Label>Use SSL</Form.Label>
                          <Form.Check
                            name="smtpSsl"
                            type="checkbox"
                            checked={formik.values.smtpSsl}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.smtpSsl}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.smtpSsl}
                          </Form.Control.Feedback>
                        </Col>
                      </>
                    )}
                  </Row>
                </Form.Group>
                {!formik.values.smtpGmail && (
                  <Form.Group>
                    <Row>
                      <Col
                        xs={12}
                        md={
                          formik.values.smtpHost === 'smtp.sendgrid.net' ? 4 : 6
                        }
                      >
                        <Form.Label>Email Server Host</Form.Label>
                        <Form.Control
                          type="string"
                          name="smtpHost"
                          value={formik.values.smtpHost}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.smtpHost}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.smtpHost}
                        </Form.Control.Feedback>
                      </Col>
                      <Col
                        xs={12}
                        md={
                          formik.values.smtpHost === 'smtp.sendgrid.net' ? 4 : 6
                        }
                      >
                        <Form.Label>Email Server Port</Form.Label>
                        <Form.Control
                          type="number"
                          name="smtpPort"
                          value={formik.values.smtpPort}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.smtpPort}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.smtpPort}
                        </Form.Control.Feedback>
                      </Col>
                      {formik.values.smtpHost === 'smtp.sendgrid.net' && (
                        <Col xs={12} md={4}>
                          <Form.Label>SendGrid Email</Form.Label>
                          <Form.Control
                            type="text"
                            name="smtpSendgridSender"
                            value={formik.values.smtpSendgridSender}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.smtpSendgridSender}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.smtpSendgridSender}
                          </Form.Control.Feedback>
                        </Col>
                      )}
                    </Row>
                  </Form.Group>
                )}
                {window.location.hostname.includes('srp.sbx') && (
                  <>
                    <Row className="mt-3">
                      <Col md={12}>
                        <p className="text-secondary title-space mb-1">
                          PROFIT SHARING
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <hr />
                      </Col>
                    </Row>
                    <Form.Group>
                      <Row>
                        <Col xs={12} md={4}>
                          <Form.Label>Photo Percent</Form.Label>
                          <Form.Control
                            type="number"
                            name="photoPercent"
                            value={formik.values.photoPercent}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.photoPercent}
                            step="any"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.photoPercent}
                          </Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Label>Sales Percent</Form.Label>

                          <Form.Control
                            type="number"
                            name="salesPercent"
                            value={formik.values.salesPercent}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.salesPercent}
                            step="any"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.salesPercent}
                          </Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Label>Rain Percent</Form.Label>
                          <Form.Control
                            type="number"
                            name="rainPercent"
                            value={formik.values.rainPercent}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.rainPercent}
                            step="any"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.rainPercent}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row>
                        <Col xs={12} md={4}>
                          <Form.Label>Service Percent</Form.Label>
                          <Form.Control
                            type="number"
                            name="servicePercent"
                            value={formik.values.servicePercent}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.servicePercent}
                            step="any"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.servicePercent}
                          </Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Label>Task Percent</Form.Label>
                          <Form.Control
                            type="number"
                            name="taskPercent"
                            value={formik.values.taskPercent}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.taskPercent}
                            step="any"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.taskPercent}
                          </Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Label>Pay Commission</Form.Label>

                          <Form.Control
                            type="number"
                            name="payCommission"
                            value={formik.values.payCommission}
                            onChange={formik.handleChange}
                            isInvalid={formik.errors.payCommission}
                            step="any"
                          />
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.payCommission}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>
                  </>
                )}
                <Row>
                  <Col md={3}>
                    <Button
                      type="submit"
                      block
                      variant="outline-primary"
                      className="mt-2"
                      disabled={submitting}
                    >
                      <PlusCircle className="mr-2" />
                      Save
                    </Button>
                  </Col>
                </Row>

                {submitting && (
                  <Row>
                    <Col>
                      <Loading message="Saving Employee..." />
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <>
                <Button
                  type="submit"
                  variant="outline-primary"
                  className="mt-1 mb-3"
                  onClick={() => {
                    setShowPermissions(false)
                  }}
                >
                  <ArrowLeft className="mr-2" />
                  Back
                </Button>
                <PermissionTable />
              </>
            )}
          </Modal.Body>
        </Form>
      </Modal>
    </>
  )
}

export default NewEmployeeModal
