import React, { useEffect, useState } from 'react'
import { Row, Col, Button, ButtonGroup, Form } from 'react-bootstrap'
import { useQuery, gql } from '@apollo/client'
import SortableInfiniteTable from '../common/SortableInfiniteTable'
import { PlusCircle, Dot, Trash } from 'react-bootstrap-icons'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import Loading from '../common/Loading'
import TaskCollectionModal from './TaskCollectionModal'
import DeleteTaskCollectionModal from './DeleteTaskCollectionModal'

const TaskCollections = () => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const canMutate = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const [editTaskCollection, setEditTaskCollection] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [checkedIds, setCheckedIds] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const { loading, error, data } = useQuery(
    gql`
      query TaskCollectionsQuery {
        taskCollections(orderBy: "name", first: 250) {
          edges {
            node {
              id
              name
              contentType {
                model
              }
              collectionType
              taskCollectionTasks(orderBy: "description") {
                edges {
                  node {
                    id
                    notes
                    description
                    dueDateDelta
                    contentType {
                      model
                    }
                    sendOverdueNotification
                    sharedCanCreateFiles
                    sharedCanCreateFolders
                    sharedCanSeeFiles
                    dueDateDeltaBeforeAfter
                    employees {
                      edges {
                        node {
                          id
                          gaiaUser {
                            fullName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    { fetchPolicy: 'network-only' }
  )
  const toggleModal = () => {
    if (canMutate) {
      if (showModal) {
        setEditTaskCollection(null)
      }
      setShowModal((prevState) => !prevState)
    }
  }

  const handleCheck = (e, row) => {
    if (e.target.checked) {
      setCheckedIds((prevState) => [...prevState, row.node.id])
    } else {
      setCheckedIds((prevState) => prevState.filter((id) => id !== row.node.id))
    }
  }

  useEffect(() => {
    let updatedData = { ...data }
    let updatedEditTaskCollection = updatedData?.taskCollections?.edges.filter(
      (node) => {
        return node.node.id === editTaskCollection?.id
      }
    )
    if (updatedEditTaskCollection && updatedEditTaskCollection.length > 0) {
      setEditTaskCollection(updatedEditTaskCollection[0].node)
    }
  }, [data])

  const tableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: 'node.name',
      serverSort: true,
      orderBy: 'name',
    },
    {
      Header: 'Type',
      id: 'type',
      serverSort: true,
      orderBy: 'collectionType',
      accessor: (row) => {
        if (row.node.collectionType === 'JOB') {
          return 'Job'
        } else if (row.node.collectionType === 'ORGANIZATION') {
          return 'Organization'
        } else if (row.node.collectionType === 'MISC') {
          return 'Miscellaneous'
        } else if (row.node.collectionType === 'SUBJECT_GROUP') {
          return 'Subject Group'
        }
      },
    },
    {
      Header: 'Tasks',
      id: 'tasks',
      accessor: (row) => {
        return (
          <div
            style={{
              maxHeight: '100px',
              overflowY: 'scroll',
              overflowX: 'hidden',
              fontSize: '12px',
            }}
            className="mt-2"
          >
            {row.node.taskCollectionTasks.edges.map((edge, i) => (
              <>
                {i > 0 && <br />}
                <Dot />
                {edge.node.description}
              </>
            ))}
          </div>
        )
      },
    },
  ]

  if (canMutate) {
    tableColumns.push({
      disableSortBy: true,
      Header: (
        <>
          <Form.Group as={ButtonGroup} className="align-items-center">
            <Form.Check
              className="ml-2 mt-2"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  const appendIds = []
                  if (data?.taskCollections) {
                    data?.taskCollections?.edges.forEach((edge) => {
                      if (!checkedIds.includes(edge.node.id)) {
                        appendIds.push(edge.node.id)
                      }
                    })
                    setCheckedIds((prevState) => {
                      return [...prevState, ...appendIds]
                    })
                  }
                } else {
                  setCheckedIds([])
                }
              }}
            />
            {checkedIds.length > 0 && (
              <span style={{ fontSize: '14px', marginTop: '5px' }}>
                ({checkedIds.length})
              </span>
            )}
          </Form.Group>
        </>
      ),
      id: 'delete',
      accessor: (row) => {
        return (
          <>
            <Form.Group as={ButtonGroup} className="align-items-center">
              <Form.Check
                className="ml-2 mt-2"
                type="checkbox"
                checked={checkedIds.includes(row.node.id)}
                onChange={(e) => handleCheck(e, row)}
              />
            </Form.Group>
          </>
        )
      },
    })
  }

  const onTdClick = (cell) => {
    setEditTaskCollection(cell.row.original.node)
    toggleModal()
  }

  if (loading)
    return (
      <Row className="mt-4">
        <Col md={3}>
          <Loading message="Loading Task Templates..." />
        </Col>
      </Row>
    )
  if (error) return <p>Error loading task types</p>
  console.log(data)
  return (
    <>
      <div className="taskCollections">
        {canMutate && (
          <Row>
            <Col className="mt-2">
              <div>
                <Button variant="link" onClick={toggleModal}>
                  <PlusCircle className="mr-2" /> New Task Collection
                </Button>
                {checkedIds.length > 0 && canMutate && (
                  <>
                    <Button
                      variant="link"
                      onClick={() => {
                        setShowDeleteModal(true)
                      }}
                    >
                      <Trash className="mr-2" />
                      {checkedIds.length === 1 ? (
                        <>Delete Task Collection</>
                      ) : (
                        <>Delete Task Collections</>
                      )}
                    </Button>
                  </>
                )}
              </div>
            </Col>
          </Row>
        )}
        <Row className="mt-2">
          <Col md={6}>
            <SortableInfiniteTable
              tableData={data?.taskCollections?.edges || []}
              loadingMessage="Loading Task Collection..."
              searchPlaceholder={'Search Task Collections'}
              tableColumns={tableColumns}
              hasMoreTableData={false}
              tableHeight={800}
              onTdClicks={{
                downloads: onTdClick,
                name: onTdClick,
                type: onTdClick,
                tasks: onTdClick,
              }}
              rowPointer={canMutate}
              searchWidth={12}
            />
          </Col>
        </Row>
        <TaskCollectionModal
          taskCollection={editTaskCollection}
          showModal={showModal}
          toggleModal={toggleModal}
        />
        <DeleteTaskCollectionModal
          showModal={showDeleteModal}
          toggleModal={() => {
            setShowDeleteModal(false)
          }}
          setIds={setCheckedIds}
          ids={checkedIds.length > 1 ? checkedIds : null}
          id={checkedIds.length === 1 ? checkedIds[0] : null}
        />
      </div>
    </>
  )
}

export default TaskCollections
