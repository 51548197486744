import React, { useState } from 'react'
import { ClockHistory, FileEarmarkText, Search } from 'react-bootstrap-icons'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { Form, Button, Row, Col, Modal, Tab } from 'react-bootstrap'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import {
  CloudArrowDown,
  Trash,
  PlusCircle,
  CaretRight,
  CaretDown,
} from 'react-bootstrap-icons'
import { FilePond } from 'react-filepond'
import AuditLog from '../audit_log/AuditLog'
import Loading from '../common/Loading'
import { useAWSS3, downloadS3Object } from '../../libs/aws'
import TagSearchInput from '../common/node_search_input/TagSearchInput'
import { loggedInUserVar } from '../../libs/apollo'
import { useUpload } from './UploadContext'
import Tabs from '../common/Tabs'
import FilePreview from './FilePreview'

const FileModal = (props) => {
  const { folderId, showModal, toggleModal, file, parentShared } = props
  const [submitting, setSubmitting] = useState(false)
  const [displayPreview, setDisplayPreview] = useState(false)
  const [displayHistory, setDisplayHistory] = useState(false)
  const { startUpload } = useUpload()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const awsS3 = useAWSS3()
  const [deleteFile] = useMutation(
    gql`
      mutation DeleteFile($input: DeleteFileInput!) {
        deleteFile(input: $input) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success('File Deleted')
        innerToggleModal()
      },
      refetchQueries: ['Folders'],
    }
  )

  const [updateFile] = useMutation(
    gql`
      mutation UpdateFile($input: UpdateFileInput!) {
        updateFile(input: $input) {
          file {
            id
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data.updateFile.file?.id) {
          setSubmitting(false)
          toast.success('File Saved')
          innerToggleModal()
        }
      },
      refetchQueries: ['Folders'],
    }
  )

  const formik = useFormik({
    initialValues: file
      ? {
          tags: file.tags.map((tag) => ({
            description: tag.name,
            id: tag.id,
          })),
          folderId,
          displayName: file.displayName.split('.')[0],
          employeeId: file.employee?.id,
          employeeDescription: file.employee?.gaiaUser.fullName,
          cantDelete: file.cantDelete,
          cantRename: file.cantRename,
          externalCantDelete: file.externalCantDelete,
          externalCantRename: file.externalCantRename,
          externallyVisible: file.externallyVisible,
          externalCantUpdateFile: file.externalCantUpdateFile,
        }
      : {
          tags: [],
          displayName: '',
          folderId,
          cantDelete: false,
          cantRename: false,
          externalCantDelete: false,
          externalCantRename: false,
          externallyVisible: true,
          externalCantUpdateFile: false,
        },
    validationSchema: Yup.object().shape({
      displayName: Yup.string()
        .nullable()
        .test('required', 'Required', (value) => {
          if (file && !value) {
            return false
          }
          return true
        }),
      tags: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          description: Yup.string().nullable(),
        })
      ),
      file: Yup.mixed()
        .nullable()
        .test('file', 'File Required', (value) => {
          return file || value
        }),
      externallyVisible: Yup.mixed(),
    }),
    validateOnChange: false,
    onSubmit: async (values) => {
      setSubmitting(true)
      const variables = {
        displayName: values.displayName,
        folderId: folderId,
        cantDelete: values.cantDelete,
        cantRename: values.cantRename,
        externalCantUpdateFile: values.externalCantUpdateFile,
        externalCantDelete: values.externalCantDelete,
        externalCantRename: values.externalCantRename,
        jobId: null,
        organizationId: null,
        subjectGroupId: null,
        subjectId: null,
        employeeId: null,
        taskId: null,
        tags: values.tags.map((tag) => tag.description),
        externallyVisible: values.externallyVisible,
        uploadSessionUuid: uuidv4(),
        fileUuid: null,
        fileName: null,
        fileContent: null,
        fileSize: null,
        isFinalChunk: null,
        isFinalFile: null,
        order: null,
        totalChunks: null,
        zipped: true,
      }
      if (file) {
        variables.id = file.id
        if (values.file && values.file.length) {
          variables.update = true
          innerToggleModal()
          await startUpload(values.file, variables)
        } else {
          const keysToRemove = [
            'uploadSessionUuid',
            'fileUuid',
            'fileName',
            'fileContent',
            'fileSize',
            'fileChunkSize',
            'isFinalChunk',
            'isFinalFile',
            'order',
            'totalChunks',
            'zipped',
          ]
          keysToRemove.forEach((key) => delete variables[key])
          updateFile({
            variables: {
              input: {
                fileInput: variables,
              },
            },
          })
        }
      } else {
        innerToggleModal()
        await startUpload(values.file, variables)
      }
    },
  })

  const innerToggleModal = () => {
    setDisplayPreview(false)
    setDisplayHistory(false)
    toggleModal()
  }
  if (!showModal) return null
  return (
    <Modal size="lg" show={showModal} onHide={innerToggleModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FileEarmarkText className="mr-2" />
          {file ? <>Edit File</> : <>New Files</>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group as={Row}>
            {file && (
              <Col md={6}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  as="input"
                  name="displayName"
                  disabled={
                    !loggedInUser.permissions.isEmployee &&
                    formik.values.externalCantRename
                  }
                  className="form-control-sm"
                  value={formik.values.displayName}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.displayName}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.displayName}
                </Form.Control.Feedback>
              </Col>
            )}
            {loggedInUser.permissions.isEmployee && (
              <Col md={6}>
                <Form.Label>Tags</Form.Label>
                <TagSearchInput formik={formik} multiple create dropdown />
              </Col>
            )}
          </Form.Group>
          {parentShared && loggedInUser.permissions.isEmployee && (
            <Form.Group as={Row}>
              <Col md={2}>
                <Form.Label style={{ fontSize: '12px' }}>
                  {!formik.values.externallyVisible ? 'Not ' : ''}Shared Visible
                </Form.Label>
                <Form.Check
                  onChange={(e) =>
                    formik.setFieldValue('externallyVisible', e.target.checked)
                  }
                  checked={formik.values.externallyVisible}
                />
              </Col>
              {formik.values.externallyVisible && (
                <>
                  <Col md={2}>
                    <Form.Label style={{ fontSize: '12px' }}>
                      Shared Can{formik.values.externalCantDelete ? 't' : ''}{' '}
                      Delete
                    </Form.Label>
                    <Form.Check
                      onChange={(e) =>
                        formik.setFieldValue(
                          'externalCantDelete',
                          e.target.checked
                        )
                      }
                      checked={formik.values.externalCantDelete}
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Label style={{ fontSize: '12px' }}>
                      Shared Can{formik.values.externalCantRename ? 't' : ''}{' '}
                      Rename
                    </Form.Label>
                    <Form.Check
                      onChange={(e) =>
                        formik.setFieldValue(
                          'externalCantRename',
                          e.target.checked
                        )
                      }
                      checked={formik.values.externalCantRename}
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Label style={{ fontSize: '12px' }}>
                      Shared Can
                      {formik.values.externalCantUpdateFile ? 't' : ''} Update
                      File
                    </Form.Label>
                    <Form.Check
                      onChange={(e) =>
                        formik.setFieldValue(
                          'externalCantUpdateFile',
                          e.target.checked
                        )
                      }
                      checked={formik.values.externalCantUpdateFile}
                    />
                  </Col>
                </>
              )}
            </Form.Group>
          )}
          {(loggedInUser.permissions.isEmployee ||
            !formik.values.externalCantUpdateFile ||
            !file) && (
            <Form.Group as={Row}>
              <Col>
                <FilePond
                  allowMultiple={!file}
                  maxFileSize="250MB"
                  allowFileSizeValidation
                  allowImagePreview={false}
                  allowImageExifOrientation={false}
                  files={formik.values.file}
                  allowDirectories={!file}
                  maxFiles={file ? 1 : null}
                  labelMaxFileSize="Maximum file size is {filesize}"
                  onupdatefiles={(file) => formik.setFieldValue('file', file)}
                  labelIdle='Drag and drop files or <span class="filepond--label-action">Browse</span>'
                />
                {formik.errors.file && (
                  <div>
                    <small className="text-danger">{formik.errors.file}</small>
                  </div>
                )}
              </Col>
            </Form.Group>
          )}
          {file && (
            <>
              <Row className="mt-3 mb-4">
                <Col>
                  <button
                    type="button"
                    onClick={() => setDisplayPreview(!displayPreview)}
                    className="px-0 btn-link mr-1"
                  >
                    <>
                      {displayPreview ? (
                        <>
                          <CaretDown size={17} />
                        </>
                      ) : (
                        <>
                          <CaretRight size={17} />
                        </>
                      )}
                    </>
                  </button>
                  <Form.Label className="mb-0">View File</Form.Label>
                </Col>
              </Row>
              {displayPreview && (
                <Row>
                  <Col>
                    <FilePreview file={file} />
                  </Col>
                </Row>
              )}
              {loggedInUser.permissions.isEmployee && (
                <>
                  <Row className="mt-3 mb-4">
                    <Col>
                      <button
                        type="button"
                        onClick={() => setDisplayHistory(!displayHistory)}
                        className="px-0 btn-link mr-1"
                      >
                        <>
                          {displayHistory ? (
                            <>
                              <CaretDown size={17} />
                            </>
                          ) : (
                            <>
                              <CaretRight size={17} />
                            </>
                          )}
                        </>
                      </button>
                      <Form.Label className="mb-0">History</Form.Label>
                    </Col>
                  </Row>
                  {displayHistory && (
                    <AuditLog
                      contentType={file.contentType.model}
                      id={file.id}
                      tableHeight={400}
                      fetchPolicy="no-cache"
                    />
                  )}
                </>
              )}
            </>
          )}
          <div className="footer-modal">
            <Form.Row className="mt-2">
              {(loggedInUser.permissions.isEmployee ||
                !formik.values.externalCantRename ||
                !formik.values.externalCantUpdateFile ||
                !file) && (
                <Col md={3}>
                  <Button
                    type="submit"
                    block
                    variant="outline-primary"
                    disabled={submitting}
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Col>
              )}

              {file && (
                <>
                  <Col md={3}>
                    <Button
                      variant="outline-primary"
                      block
                      disabled={submitting}
                      onClick={() => {
                        setSubmitting(true)
                        downloadS3Object(
                          awsS3,
                          file.fileName,
                          file.displayName,
                          () => {
                            setSubmitting(false)
                            toast.success(`File Downloaded`)
                          }
                        )
                      }}
                    >
                      <CloudArrowDown className="mr-2" />
                      Download
                    </Button>
                  </Col>
                  {(loggedInUser.permissions.isEmployee ||
                    !formik.values.externalCantDelete) &&
                    !file.cantDelete && (
                      <Col md={3}>
                        <Button
                          variant="outline-danger"
                          block
                          onClick={() => {
                            setSubmitting(true)
                            deleteFile({
                              variables: {
                                input: {
                                  fileIds: file.id,
                                },
                              },
                            })
                          }}
                          disabled={submitting}
                        >
                          <Trash className="mr-2" />
                          Delete
                        </Button>
                      </Col>
                    )}
                </>
              )}
            </Form.Row>
            {submitting && (
              <div className="mt-2">
                <Loading />
              </div>
            )}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default FileModal
