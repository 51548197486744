import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useLazyQuery, gql, useMutation } from '@apollo/client'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { DateTime } from 'luxon'
import SubjectGroupForm from './SubjectGroupForm'
import { client } from '../../libs/apollo'
import toast from 'react-hot-toast'
import { useInterval } from '../../libs/utils'
import validator from 'validator'
import Loading from '../common/Loading'
import { useFolderConfiguration } from '../../libs/folderConfiguration'
import moment from 'moment'
export default function SubjectGroup(props) {
  const { subjectGroupId, organization, toggleModal } = props
  const [updatingSubjectGroup, setUpdatingSubjectGroup] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [csvSubjects, setCsvSubjects] = useState([])
  const [csvRecordSync, setCsvRecordSync] = useState()
  const folderConfiguration = useFolderConfiguration()

  useInterval(() => {
    document
      .querySelectorAll('.CSVImporter_IconButton')
      .forEach((importerButton) => {
        if (!importerButton.hasAttribute('type')) {
          importerButton.setAttribute('type', 'button')
        }
      })
    document
      .querySelectorAll('.CSVImporter_TextButton')
      .forEach((importerTextButton) => {
        if (importerTextButton.innerHTML == 'Import') {
          importerTextButton.innerHTML = 'Validate Import'
        }
      })
    document
      .querySelectorAll('.CSVImporter_ProgressDisplay__status')
      .forEach((importerTextButton) => {
        if (importerTextButton.innerHTML == 'Complete') {
          importerTextButton.innerHTML = ''
        }
      })
  }, 100)

  const [createSubjectGroup] = useMutation(
    gql`
      mutation CreateSubjectGroupMutations(
        $createSubjectGroupInput: CreateSubjectGroupInput!
      ) {
        createSubjectGroup(input: $createSubjectGroupInput) {
          subjectGroup {
            id
            name
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      refetchQueries: [
        'SubjectGroupsQuery',
        'OrganizationSubjectGroupsQuery',
        'SubjectsQuery',
      ],
      onCompleted(data) {
        if (csvSubjects.length > 0) {
          processSubjectGroupCsv({
            variables: {
              processSubjectGroupCsvInput: {
                subjectGroupInput: {
                  id: data.createSubjectGroup.subjectGroup.id,
                  recordSync: csvRecordSync,
                  csvSubjects,
                },
              },
            },
          })
        }
        setSubmitting(false)
        toast.success('Subject Group Saved')
        toggleModal()
      },
    }
  )

  const [processSubjectGroupCsv] = useMutation(
    gql`
      mutation ProcessSubjectGroupCsv(
        $processSubjectGroupCsvInput: ProcessSubjectGroupCsvInput!
      ) {
        processSubjectGroupCsv(input: $processSubjectGroupCsvInput) {
          subjectGroup {
            id
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
    }
  )

  const [
    updateSubjectGroup,
    { loading: updateSubjectGroupLoading, data: updateSubjectGroupData },
  ] = useMutation(
    gql`
      mutation UpdateSubjectGroupMutations(
        $updateSubjectGroupInput: UpdateSubjectGroupInput!
      ) {
        updateSubjectGroup(input: $updateSubjectGroupInput) {
          subjectGroup {
            id
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      refetchQueries: ['SubjectGroupsQuery', 'NotificationsQuery'],
      onCompleted(data) {
        if (csvSubjects.length > 0) {
          processSubjectGroupCsv({
            variables: {
              processSubjectGroupCsvInput: {
                subjectGroupInput: {
                  id: data.updateSubjectGroup.subjectGroup.id,
                  recordSync: csvRecordSync,
                  csvSubjects,
                },
              },
            },
          })
        }
        setSubmitting(false)
        toast.success('Subject Group Saved')
        toggleModal()
      },
    }
  )

  const [getSubjectGroup, { data: getSubjectGroupData }] = useLazyQuery(
    gql`
      query SubjectGroupQuery($subjectGroupId: ID!) {
        subjectGroup(id: $subjectGroupId) {
          name
          lastDateToPickPose
          lastDateForRetouch
          lastDateToBePhotographed
          metadata
          resitFeePaidSessions
          notes
          sharedCanSeeFiles
          sharedCanCreateFiles
          sharedCanCreateFolders
          fotomerchantClientSessions {
            edges {
              node {
                fotomerchantClientSessionTemplate {
                  fotomerchantId
                }
              }
            }
          }
          resitFeeFreeSessions
          resitFee
          skipWelcomeEmail
          category
          subjects {
            edges {
              node {
                id
                studentId
                gaiaUser {
                  id
                  fullName
                  email
                  phoneNumber
                }
              }
            }
          }
          hidePromoCodes
          name
          resitsAvailable
          refundPolicy
          applyNoShowPolicyFree
          applyNoShowPolicyPaid
          applyPolicyFree
          applyPolicyPaid
          timeRefundHours
          timeRefundFee
          timeRefundSessionPackageCost
          resitsIncludeFreeSessions
          noShowFee
          freePackageResitFee
          usingNoShowPolicy
          usingCancellationPolicy
          usingResitPolicy
          id
          canDelete
          organization {
            id
            name
          }
          subjectsPaying
          startDateTime
          endDateTime
          sessionPackages {
            edges {
              node {
                id
                title
                price
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    if (subjectGroupId) {
      getSubjectGroup({
        variables: {
          subjectGroupId,
        },
      })
      setUpdatingSubjectGroup(true)
    }
  }, [subjectGroupId])

  const validatePhone = (phone) => {
    let cleanPhone = phone.replace(/[^\w]/g, '')
    if (cleanPhone.length == 10) {
      cleanPhone = `${1}${cleanPhone}`
    }
    if (isNaN(parseInt(cleanPhone)) || cleanPhone.charAt(0) != 1) {
      cleanPhone = false
    }
    return cleanPhone
  }

  if (subjectGroupId && !getSubjectGroupData)
    return (
      <>
        <Loading />
      </>
    )

  let fotomerchantClientSessionTemplateId
  if (
    getSubjectGroupData &&
    getSubjectGroupData.subjectGroup.fotomerchantClientSessions.edges.length > 0
  ) {
    fotomerchantClientSessionTemplateId =
      getSubjectGroupData.subjectGroup.fotomerchantClientSessions.edges[0].node
        .fotomerchantClientSessionTemplate?.fotomerchantId
  }
  if (!folderConfiguration) return <></>
  return (
    <Formik
      validateOnChange={false}
      initialValues={
        updatingSubjectGroup
          ? {
              category: getSubjectGroupData.subjectGroup
                ? getSubjectGroupData.subjectGroup.category
                : '',
              name: getSubjectGroupData.subjectGroup
                ? getSubjectGroupData.subjectGroup.name
                : '',
              sharedCanSeeFiles:
                getSubjectGroupData.subjectGroup.sharedCanSeeFiles,
              sharedCanCreateFiles:
                getSubjectGroupData.subjectGroup.sharedCanCreateFiles,
              sharedCanCreateFolders:
                getSubjectGroupData.subjectGroup.sharedCanCreateFolders,
              resitsAvailable: getSubjectGroupData.subjectGroup.resitsAvailable,
              allowPromoCodes: !getSubjectGroupData.subjectGroup.hidePromoCodes,
              freePackageResitFee:
                getSubjectGroupData.subjectGroup.freePackageResitFee,
              resitsIncludeFreeSessions:
                getSubjectGroupData.subjectGroup.resitsIncludeFreeSessions,
              resitFee: getSubjectGroupData.subjectGroup.resitFee,
              metadata: getSubjectGroupData.subjectGroup.metadata,
              notes: getSubjectGroupData.subjectGroup.notes,
              startDateTime: moment(
                getSubjectGroupData.subjectGroup.startDateTime
              ).toDate(),
              endDateTime: moment(
                getSubjectGroupData.subjectGroup.endDateTime
              ).toDate(),
              lastDateToPickPose: getSubjectGroupData.subjectGroup
                .lastDateToPickPose
                ? moment(
                    getSubjectGroupData.subjectGroup.lastDateToPickPose
                  ).toDate()
                : null,
              lastDateForRetouch: getSubjectGroupData.subjectGroup
                .lastDateForRetouch
                ? moment(
                    getSubjectGroupData.subjectGroup.lastDateForRetouch
                  ).toDate()
                : null,
              lastDateToBePhotographed: getSubjectGroupData.subjectGroup
                .lastDateToBePhotographed
                ? moment(
                    getSubjectGroupData.subjectGroup.lastDateToBePhotographed
                  ).toDate()
                : null,
              databaseSubjects: [],
              newSubjects: [],
              csvSubjects: [],
              refundPolicy: getSubjectGroupData?.subjectGroup.refundPolicy,
              resitFeeFreeSessions:
                getSubjectGroupData?.subjectGroup.resitFeeFreeSessions,
              applyNoShowPolicyFree:
                getSubjectGroupData?.subjectGroup.applyNoShowPolicyFree,
              applyNoShowPolicyPaid:
                getSubjectGroupData?.subjectGroup.applyNoShowPolicyPaid,
              applyPolicyFree:
                getSubjectGroupData?.subjectGroup.applyPolicyFree,
              applyPolicyPaid:
                getSubjectGroupData?.subjectGroup.applyPolicyPaid,
              timeRefundHours:
                getSubjectGroupData?.subjectGroup.timeRefundHours,
              timeRefundFee: getSubjectGroupData?.subjectGroup.timeRefundFee,
              timeRefundSessionPackageCost:
                getSubjectGroupData?.subjectGroup.timeRefundSessionPackageCost,
              noShowFee: getSubjectGroupData?.subjectGroup.noShowFee,
              usingNoShowPolicy:
                getSubjectGroupData?.subjectGroup.usingNoShowPolicy,
              usingCancellationPolicy:
                getSubjectGroupData?.subjectGroup.usingCancellationPolicy,
              resitFeePaidSessions:
                getSubjectGroupData?.subjectGroup.resitFeePaidSessions,
              usingResitPolicy:
                getSubjectGroupData?.subjectGroup.usingResitPolicy,
              removeSubjects: [],
              subjectsPaying: getSubjectGroupData.subjectGroup.subjectsPaying,
              organization: {
                id: getSubjectGroupData.subjectGroup.organization?.id,
                name: getSubjectGroupData.subjectGroup.organization?.name,
              },
              fotomerchantClientSessionTemplateId,
              sessionPackages:
                getSubjectGroupData.subjectGroup.sessionPackages.edges.length >
                0
                  ? getSubjectGroupData.subjectGroup.sessionPackages.edges.map(
                      (pack) => ({
                        id: pack.node.id,
                        title: pack.node.title,
                      })
                    )
                  : [],
              subjectsDatabase: [],
              skipWelcomeEmail:
                getSubjectGroupData.subjectGroup.skipWelcomeEmail,
              notificationCopySg: { id: '', name: '' },
              existingSubjects:
                getSubjectGroupData.subjectGroup.subjects.edges.length > 0
                  ? getSubjectGroupData.subjectGroup.subjects.edges.map(
                      (subj) => {
                        const { node } = subj
                        let email
                        if (!node.gaiaUser.dummyUsername) {
                          if (node.gaiaUser.email) {
                            email = node.gaiaUser.email
                          }
                        }
                        return {
                          id: node.id,
                          fullName: node.gaiaUser.fullName
                            ? node.gaiaUser.fullName
                            : '',
                          email,
                          phoneNumber: node.gaiaUser.phoneNumber
                            ? node.gaiaUser.phoneNumber
                            : '',
                          studentId: node.studentId ? node.studentId : '',
                        }
                      }
                    )
                  : [],
            }
          : {
              name: '',
              sharedCanCreateFiles: organization
                ? organization.subjectGroupSharedCanCreateFiles
                : folderConfiguration?.subjectGroupSharedCanCreateFiles,
              sharedCanCreateFolders: organization
                ? organization.subjectGroupSharedCanCreateFolders
                : folderConfiguration?.subjectGroupSharedCanCreateFolders,
              sharedCanSeeFiles: organization
                ? organization.subjectGroupSharedCanSeeFiles
                : folderConfiguration?.subjectGroupSharedCanSeeFiles,
              category: 'School',
              resitsAvailable: true,
              allowPromoCodes: true,
              startDateTime: new Date(),
              endDateTime: new Date(),
              metadata: {},
              notes: '',
              fotomerchantClientSessionTemplateId: null,
              lastDateToPickPose: null,
              lastDateForRetouch: null,
              lastDateToBePhotographed: null,
              skipWelcomeEmail: true,
              resitFeePaidSessions: true,
              databaseSubjects: [],
              newSubjects: [],
              resitFee: 0,
              subjectsPaying: true,
              csvSubjects: [],
              organization: organization
                ? { id: organization.id, name: organization.name }
                : { id: '', name: '' },
              notificationCopySg: { id: '', name: '' },
              sessionPackages: [],
              existingSubjects: [],
              removeSubjects: [],
              refundPolicy: 'TIME',
              resitFeeFreeSessions: true,
              applyNoShowPolicyFree: true,
              applyNoShowPolicyPaid: true,
              applyPolicyFree: true,
              applyPolicyPaid: true,
              timeRefundHours: 24,
              timeRefundFee: 0,
              resitsIncludeFreeSessions: true,
              timeRefundSessionPackageCost: true,
              noShowFee: 0,
              freePackageResitFee: 0,
              usingNoShowPolicy: 'ORGANIZATION',
              usingCancellationPolicy: 'ORGANIZATION',
              usingResitPolicy: 'ORGANIZATION',
            }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('Required')
          .test(
            'isUnique',
            'Name must be unique per organization',
            async (value, context) => {
              let valid = true
              if (value && context.parent.organization?.id) {
                const { data } = await client.query({
                  query: gql`
                    query SubjectGroupName($organizationId: ID, $name: String) {
                      subjectGroups(
                        organization: $organizationId
                        name_Iexact: $name
                      ) {
                        nodeCount
                        edges {
                          node {
                            id
                          }
                        }
                      }
                    }
                  `,
                  fetchPolicy: 'no-cache',
                  variables: {
                    name: value,
                    organizationId: context.parent.organization?.id,
                  },
                })
                if (!updatingSubjectGroup && data.subjectGroups.nodeCount > 0) {
                  valid = false
                } else if (
                  updatingSubjectGroup &&
                  data.subjectGroups.nodeCount > 0 &&
                  data.subjectGroups.edges[0].node.id !=
                    getSubjectGroupData.subjectGroup.id
                ) {
                  valid = false
                }
              }
              return valid
            }
          ),
        category: Yup.string().required('Required'),
        fotomerchantClientSessionTemplateId: Yup.string().nullable(),
        resitsAvailable: Yup.bool().required('Required'),
        resitsIncludeFreeSessions: Yup.bool().required('Required'),
        sharedCanCreateFiles: Yup.bool().required('Required'),
        sharedCanCreateFolders: Yup.bool().required('Required'),
        sharedCanSeeFiles: Yup.bool().required('Required'),
        startDateTime: Yup.date()
          .required('Start date required')
          .test(
            'isLessThanEndDate',
            'Start date must not exceed end date',
            (value, context) => {
              let valid = true
              if (value && context.parent.endDateTime) {
                const startDate = new Date(value)
                const endDate = new Date(context.parent.endDateTime)
                endDate.setHours(23)
                endDate.setMinutes(59)
                endDate.setSeconds(59)
                if (endDate < startDate) {
                  valid = false
                }
              }
              return valid
            }
          ),
        skipWelcomeEmail: Yup.bool().nullable(),
        endDateTime: Yup.date().required('End date required'),
        lastDateToPickPose: Yup.date().nullable(),
        lastDateForRetouch: Yup.date().nullable(),
        lastDateToBePhotographed: Yup.date().nullable(),
        notes: Yup.string().nullable(),
        sessionPackages: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            title: Yup.string(),
          })
        ),
        removeSubjects: Yup.array().of(Yup.string()),
        databaseSubjects: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            fullName: Yup.string(),
          })
        ),
        subjectsDatabase: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            fullName: Yup.string(),
          })
        ),
        newSubjects: Yup.array().of(
          Yup.object().shape({
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            notes: Yup.string().nullable(),
            emailConfirmed: Yup.bool(),
            phoneNumber: Yup.string()
              .nullable()
              .test(
                'isNotRepeat',
                'Parent phone numbers must not match subject phone number',
                (value, context) => {
                  let valid = true
                  if (value) {
                    context.parent.parents.forEach((parent) => {
                      if (
                        value === parent.phoneNumber ||
                        value === parent.secondaryPhoneNumber
                      ) {
                        valid = false
                      }
                    })
                  }
                  return valid
                }
              )
              .test('isPhoneNumber', 'Invalid phone number', (value) => {
                let valid = true
                if (value && !validatePhone(value)) {
                  valid = false
                }
                return valid
              }),
            studentId: Yup.string().nullable(),
            addressLineOne: Yup.string()
              .test('a1-val', 'required address field', (value, context) => {
                if (
                  !value &&
                  (context.parent.city ||
                    context.parent.state ||
                    context.parent.zipCode)
                ) {
                  return false
                } else {
                  return true
                }
              })
              .nullable(),
            addressLineTwo: Yup.string().nullable(),
            city: Yup.string()
              .test('a1-val', 'required address field', (value, context) => {
                if (
                  !value &&
                  (context.parent.addressLineOne ||
                    context.parent.state ||
                    context.parent.zipCode)
                ) {
                  return false
                } else {
                  return true
                }
              })
              .nullable(),
            state: Yup.string()
              .test('a1-val', 'required address field', (value, context) => {
                if (
                  !value &&
                  (context.parent.city ||
                    context.parent.addressLineOne ||
                    context.parent.zipCode)
                ) {
                  return false
                } else {
                  return true
                }
              })
              .nullable(),
            zipCode: Yup.string()
              .test('a1-val', 'required address field', (value, context) => {
                if (
                  !value &&
                  (context.parent.city ||
                    context.parent.state ||
                    context.parent.addressLineOne)
                ) {
                  return false
                } else {
                  return true
                }
              })
              .nullable(),
            password: Yup.string(),
            confirmPassword: Yup.string().oneOf(
              [Yup.ref('password')],
              'Password and confirm password do not match'
            ),
            secondaryPhoneNumber: Yup.string()
              .nullable()
              .test(
                'isNotRepeat',
                'Phone numbers must be unique',
                (value, context) => {
                  let valid = true
                  if (value && value === context.parent.phoneNumber) {
                    valid = false
                  }
                  return valid
                }
              )
              .test('isPhoneNumber', 'Invalid phone number', (value) => {
                let valid = true
                if (value && !validatePhone(value)) {
                  valid = false
                }
                return valid
              }),
            secondaryEmail: Yup.string()
              .nullable()
              .test(
                'isNotRepeat',
                'Emails must be unique',
                (value, context) => {
                  let valid = true
                  if (value && value === context.parent.email) {
                    valid = false
                  }
                  return valid
                }
              )
              .test(
                'isNotRepeat',
                'Parent emails must not match subject emails',
                (value, context) => {
                  let valid = true
                  if (value) {
                    context.parent.parents.forEach((parent) => {
                      if (
                        value === parent.email ||
                        value === parent.secondaryEmail
                      ) {
                        valid = false
                      }
                    })
                  }
                  return valid
                }
              )
              .test('isEmail', 'Invalid email', (value) => {
                let valid = true
                if (value && !validator.isEmail(value)) {
                  valid = false
                }
                return valid
              }),
            email: Yup.string()
              .nullable()
              .test(
                'isUnique',
                'Account with this email already exists',
                async (value) => {
                  let valid = true
                  if (value) {
                    const { data } = await client.query({
                      query: gql`
                        query NewSubjectFormGaiaUsersQuery(
                          $emailIexact: String
                        ) {
                          gaiaUsers(email_Iexact: $emailIexact) {
                            nodeCount
                            edges {
                              node {
                                email
                              }
                            }
                          }
                        }
                      `,
                      fetchPolicy: 'network-only',
                      variables: {
                        emailIexact: value,
                      },
                    })
                    return (valid = data.gaiaUsers.nodeCount === 0)
                  }
                  return valid
                }
              )
              .test(
                'isNotRepeat',
                'Parent emails must not match subject emails',
                (value, context) => {
                  let valid = true
                  if (value) {
                    context.parent.parents.forEach((parent) => {
                      if (
                        value === parent.email ||
                        value === parent.secondaryEmail
                      ) {
                        valid = false
                      }
                    })
                  }
                  return valid
                }
              )
              .test('isEmail', 'Invalid email', (value) => {
                let valid = true
                if (value && !validator.isEmail(value)) {
                  valid = false
                }
                return valid
              }),
            smsNotificationsEnabled: Yup.bool().required('Required'),
            emailNotificationsEnabled: Yup.bool().required('Required'),
            skipWelcomeEmail: Yup.bool().required('Required'),
            removeParents: Yup.array().of(Yup.string()),
            removeColleagues: Yup.array().of(Yup.string()),
            parents: Yup.array().of(
              Yup.object().shape({
                id: Yup.string().nullable(),
                firstName: Yup.string().nullable(),
                lastName: Yup.string().nullable(),
                email: Yup.string()
                  .required('required')
                  .test('isEmail', 'Invalid email', (value) => {
                    let valid = true
                    if (value && !validator.isEmail(value)) {
                      valid = false
                    }
                    return valid
                  }),
                phoneNumber: Yup.string()
                  .nullable()
                  .test('isPhoneNumber', 'Invalid phone number', (value) => {
                    let valid = true
                    if (value && !validator.isMobilePhone(value, 'en-US')) {
                      valid = false
                    }
                    return valid
                  }),
                secondaryEmail: Yup.string()
                  .nullable()
                  .test(
                    'isNotRepeat',
                    'Emails must be unique',
                    (value, context) => {
                      let valid = true
                      if (value && value === context.parent.email) {
                        valid = false
                      }
                      return valid
                    }
                  )
                  .test('isEmail', 'Invalid email', (value) => {
                    let valid = true
                    if (value && !validator.isEmail(value)) {
                      valid = false
                    }
                    return valid
                  }),
                secondaryPhoneNumber: Yup.string()
                  .nullable()
                  .test(
                    'isNotRepeat',
                    'Phone numbers must be unique',
                    (value, context) => {
                      let valid = true
                      if (value && value === context.parent.phoneNumber) {
                        valid = false
                      }
                      return valid
                    }
                  )
                  .test('isPhoneNumber', 'Invalid phone number', (value) => {
                    let valid = true
                    if (value && !validator.isMobilePhone(value, 'en-US')) {
                      valid = false
                    }
                    return valid
                  }),
                smsNotificationsEnabled: Yup.bool().required('required'),
                emailNotificationsEnabled: Yup.bool().required('required'),
              })
            ),
            colleagues: Yup.array().of(
              Yup.object().shape({
                id: Yup.string().nullable(),
                firstName: Yup.string().nullable(),
                lastName: Yup.string().nullable(),
                email: Yup.string()
                  .required('required')
                  .test(
                    'isUnique',
                    'Emails associated to employees cannot be used for subject colleagues',
                    async (value) => {
                      let valid = true
                      if (value) {
                        const { data } = await client.query({
                          query: gql`
                            query NewSubjectFormGaiaUsersQuery(
                              $emailIexact: String
                            ) {
                              gaiaUsers(email_Iexact: $emailIexact) {
                                nodeCount
                                edges {
                                  node {
                                    email
                                  }
                                }
                              }
                            }
                          `,
                          fetchPolicy: 'network-only',
                          variables: {
                            emailIexact: value,
                          },
                        })
                        valid = data.gaiaUsers.nodeCount === 0
                      }
                      return valid
                    }
                  )
                  .test('isEmail', 'Invalid email', (value) => {
                    let valid = true
                    if (value && !validator.isEmail(value)) {
                      valid = false
                    }
                    return valid
                  }),
                phoneNumber: Yup.string()
                  .nullable()
                  .test('isPhoneNumber', 'Invalid phone number', (value) => {
                    let valid = true
                    if (value && !validator.isMobilePhone(value, 'en-US')) {
                      valid = false
                    }
                    return valid
                  }),
                secondaryEmail: Yup.string()
                  .nullable()
                  .test(
                    'isNotRepeat',
                    'Emails must be unique',
                    (value, context) => {
                      let valid = true
                      if (value && value === context.parent.email) {
                        valid = false
                      }
                      return valid
                    }
                  )
                  .test('isEmail', 'Invalid email', (value) => {
                    let valid = true
                    if (value && !validator.isEmail(value)) {
                      valid = false
                    }
                    return valid
                  }),
                secondaryPhoneNumber: Yup.string()
                  .nullable()
                  .test(
                    'isNotRepeat',
                    'Phone numbers must be unique',
                    (value, context) => {
                      let valid = true
                      if (value && value === context.parent.phoneNumber) {
                        valid = false
                      }
                      return valid
                    }
                  )
                  .test('isPhoneNumber', 'Invalid phone number', (value) => {
                    let valid = true
                    if (value && !validator.isMobilePhone(value, 'en-US')) {
                      valid = false
                    }
                    return valid
                  }),
                smsNotificationsEnabled: Yup.bool().required('required'),
                emailNotificationsEnabled: Yup.bool().required('required'),
              })
            ),
          })
        ),
        noShowFee: Yup.number()
          .nullable()
          .test('required', 'required', (value, context) => {
            let valid = true
            if (
              context.parent.usingNoShowPolicy === 'SUBJECT_GROUP' &&
              !(typeof value === 'number')
            ) {
              valid = false
            }
            return valid
          }),
        resitFeeFreeSessions: Yup.boolean().nullable(),
        resitFee: Yup.number()
          .nullable()
          .test('required', 'required', (value, context) => {
            let valid = true
            if (
              context.parent.usingResitPolicy === 'SUBJECT_GROUP' &&
              !(typeof value === 'number')
            ) {
              valid = false
            }
            return valid
          }),
        freePackageResitFee: Yup.number()
          .nullable()
          .test('required', 'required', (value, context) => {
            let valid = true
            if (
              context.parent.usingResitPolicy === 'SUBJECT_GROUP' &&
              context.parent.resitsIncludeFreeSessions &&
              !(typeof value === 'number')
            ) {
              valid = false
            }
            return valid
          }),
        existingSubjects: Yup.array().of(
          Yup.object().shape({
            id: Yup.string(),
            fullName: Yup.string(),
            studentId: Yup.string(),
            email: Yup.string(),
            phoneNumber: Yup.string(),
          })
        ),
        refundPolicy: Yup.string().nullable(),
        applyNoShowPolicyFree: Yup.boolean().nullable(),
        applyNoShowPolicyPaid: Yup.boolean().nullable(),
        applyPolicyFree: Yup.boolean().nullable(),
        applyPolicyPaid: Yup.boolean().nullable(),
        resitFeePaidSessions: Yup.boolean().nullable(),
        timeRefundHours: Yup.number()
          .nullable()
          .test('required', 'required', (value, context) => {
            let valid = true
            if (
              context.parent.usingCancellationPolicy === 'SUBJECT_GROUP' &&
              context.parent.refundPolicy === 'TIME' &&
              !(typeof value === 'number')
            ) {
              valid = false
            }
            return valid
          }),
        timeRefundFee: Yup.number()
          .nullable()
          .test('required', 'required', (value, context) => {
            let valid = true
            if (
              context.parent.usingCancellationPolicy === 'SUBJECT_GROUP' &&
              context.parent.refundPolicy === 'TIME' &&
              !(typeof value === 'number')
            ) {
              valid = false
            } else if (
              context.parent.usingCancellationPolicy === 'SUBJECT_GROUP' &&
              context.parent.refundPolicy === 'NEVER' &&
              !(typeof value === 'number')
            ) {
              valid = false
            }
            return valid
          }),
        timeRefundSessionPackageCost: Yup.boolean().nullable(),
        usingNoShowPolicy: Yup.string().nullable(),
        usingCancellationPolicy: Yup.string().nullable(),
        usingResitPolicy: Yup.string().nullable(),
        csvSubjects: Yup.array().of(
          Yup.object().shape({
            subjectFirstName: Yup.string().nullable(),
            subjectLastName: Yup.string().nullable(),
            subjectPhone: Yup.string().nullable(),
            subjectEmail: Yup.string().nullable(),
            parentOneFirstName: Yup.string().nullable(),
            parentOneLastName: Yup.string().nullable(),
            parentOnePhone: Yup.string().nullable(),
            parentOneSecondaryPhone: Yup.string().nullable(),
            parentOneEmail: Yup.string().nullable(),
            parentOneSecondaryEmail: Yup.string().nullable(),
            parentTwoFirstName: Yup.string().nullable(),
            parentTwoLastName: Yup.string().nullable(),
            parentTwoPhone: Yup.string().nullable(),
            parentTwoEmail: Yup.string().nullable(),
            addressLineOne: Yup.string().nullable(),
            addressLineTwo: Yup.string().nullable(),
            city: Yup.string().nullable(),
            zipCode: Yup.string().nullable(),
            state: Yup.string().nullable(),
            studentId: Yup.string().nullable(),
            imagequixId: Yup.string().nullable(),
          })
        ),
        organization: Yup.object()
          .shape({
            id: Yup.string().required('Required'),
            name: Yup.string().required('Organization Required'),
            billingInfo: Yup.string(),
          })
          .required('Organization required'),
        notificationCopySg: Yup.object().shape({
          id: Yup.string().nullable(),
          name: Yup.string().nullable(),
        }),
      })}
      onSubmit={(values) => {
        setSubmitting(true)
        const transformedDatabaseSubjects =
          values.databaseSubjects.length === 0
            ? []
            : values.databaseSubjects.map((sub) => sub.id)

        const newSessionPackages =
          values.sessionPackages.length === 0
            ? []
            : values.sessionPackages.map((pack) => pack.id)
        let start = DateTime.utc(
          values.startDateTime.getFullYear(),
          values.startDateTime.getMonth() + 1,
          values.startDateTime.getDate(),
          0,
          0
        )
        start = start.toISO()
        let end = DateTime.utc(
          values.endDateTime.getFullYear(),
          values.endDateTime.getMonth() + 1,
          values.endDateTime.getDate(),
          23,
          59
        )
        end = end.toISO()
        let lastDateToPickPose
        if (values.lastDateToPickPose) {
          let utcLastDateToPickPose = DateTime.utc(
            values.lastDateToPickPose.getFullYear(),
            values.lastDateToPickPose.getMonth() + 1,
            values.lastDateToPickPose.getDate(),
            23,
            59
          )
          lastDateToPickPose = utcLastDateToPickPose.toISO()
        }

        let lastDateForRetouch
        if (values.lastDateForRetouch) {
          let utcLastDateForRetouch = DateTime.utc(
            values.lastDateForRetouch.getFullYear(),
            values.lastDateForRetouch.getMonth() + 1,
            values.lastDateForRetouch.getDate(),
            23,
            59
          )
          lastDateForRetouch = utcLastDateForRetouch.toISO()
        }

        let lastDateToBePhotographed
        if (values.lastDateToBePhotographed) {
          let utcLastDateToBePhotographed = DateTime.utc(
            values.lastDateToBePhotographed.getFullYear(),
            values.lastDateToBePhotographed.getMonth() + 1,
            values.lastDateToBePhotographed.getDate(),
            23,
            59
          )
          lastDateToBePhotographed = utcLastDateToBePhotographed.toISO()
        }

        let csvSubjectsLen = false
        if (values.csvSubjects && values.csvSubjects.length > 0) {
          csvSubjectsLen = values.csvSubjects.length
          setCsvSubjects(values.csvSubjects)
        }
        const manualSubjects = []
        values.newSubjects?.forEach((subject) => {
          const subjectInput = {
            studentId: subject.studentId ? subject.studentId : null,
            stripePaymentMethodId: null,
            newGaiaUser: {
              firstName: subject.firstName,
              lastName: subject.lastName,
              email: subject.email,
              secondaryEmail: subject.secondaryEmail
                ? subject.secondaryEmail
                : null,
              phoneNumber: subject.phoneNumber ? subject.phoneNumber : null,
              secondaryPhoneNumber: subject.secondaryPhoneNumber
                ? subject.secondaryPhoneNumber
                : null,
              password: subject.password,
              emailConfirmed: subject.emailConfirmed,
              emailNotificationsEnabled: subject.emailNotificationsEnabled,
              // skipWelcomeEmail: subject.skipWelcomeEmail,
              smsNotificationsEnabled: subject.smsNotificationsEnabled,
            },
          }
          subjectInput.parents = subject.parents.map((parent) => {
            return {
              firstName: parent.firstName,
              lastName: parent.lastName,
              email: parent.email,
              phoneNumber: parent.phoneNumber ? parent.phoneNumber : null,
              secondaryPhoneNumber: parent.secondaryPhoneNumber
                ? parent.secondaryPhoneNumber
                : null,
              secondaryEmail: parent.secondaryEmail
                ? parent.secondaryEmail
                : null,
              smsNotificationsEnabled: parent.smsNotificationsEnabled,
              emailNotificationsEnabled: parent.emailNotificationsEnabled,
            }
          })
          subjectInput.colleagues = subject.colleagues.map((colleagues) => {
            return {
              firstName: colleagues.firstName,
              lastName: colleagues.lastName,
              email: colleagues.email,
              phoneNumber: colleagues.phoneNumber
                ? colleagues.phoneNumber
                : null,
              secondaryPhoneNumber: colleagues.secondaryPhoneNumber
                ? colleagues.secondaryPhoneNumber
                : null,
              secondaryEmail: colleagues.secondaryEmail
                ? colleagues.secondaryEmail
                : null,
              smsNotificationsEnabled: colleagues.smsNotificationsEnabled,
              emailNotificationsEnabled: colleagues.emailNotificationsEnabled,
            }
          })
          if (subject.addressLineOne) {
            subjectInput.subjectLocationInput = {
              addressLineOne: subject.addressLineOne,
              addressLineTwo: subject.addressLineTwo
                ? subject.addressLineTwo
                : null,
              city: subject.city,
              state: subject.state,
              zipCode: subject.zipCode,
            }
          }
          manualSubjects.push(subjectInput)
        })
        let freePackageResitFee
        if (values.freePackageResitFee || values.freePackageResitFee === 0) {
          freePackageResitFee = values.freePackageResitFee
        }

        let parsedMetadata = JSON.parse(values.metadata)

        if ('' in parsedMetadata) {
          delete parsedMetadata['']
        }
        if (!updatingSubjectGroup) {
          createSubjectGroup({
            variables: {
              createSubjectGroupInput: {
                subjectGroupInput: {
                  category: values.category,
                  csvSubjects: csvSubjectsLen,
                  resitsIncludeFreeSessions: values.resitsIncludeFreeSessions,
                  resitsAvailable: values.resitsAvailable,
                  skipWelcomeEmail: values.skipWelcomeEmail,
                  subjects: transformedDatabaseSubjects,
                  startDateTime: start,
                  sharedCanCreateFiles: values.sharedCanCreateFiles,
                  sharedCanCreateFolders: values.sharedCanCreateFolders,
                  sharedCanSeeFiles: values.sharedCanSeeFiles,
                  refundPolicy: values.refundPolicy,
                  applyNoShowPolicyFree: values.applyNoShowPolicyFree,
                  applyNoShowPolicyPaid: values.applyNoShowPolicyPaid,
                  applyPolicyFree: values.applyPolicyFree,
                  applyPolicyPaid: values.applyPolicyPaid,
                  timeRefundHours: values.timeRefundHours,
                  timeRefundFee: values.timeRefundFee,
                  resitFeePaidSessions: values.resitFeePaidSessions,
                  resitFee: values.resitFee,
                  timeRefundSessionPackageCost:
                    values.timeRefundSessionPackageCost,
                  noShowFee: values.noShowFee,
                  usingNoShowPolicy: values.usingNoShowPolicy,
                  usingCancellationPolicy: values.usingCancellationPolicy,
                  usingResitPolicy: values.usingResitPolicy,
                  hidePromoCodes: !values.allowPromoCodes,
                  freePackageResitFee: freePackageResitFee,
                  subjectsPaying: values.subjectsPaying,
                  endDateTime: end,
                  resitFeeFreeSessions: values.resitFeeFreeSessions,
                  notificationCopySubjectGroupId: values.notificationCopySg.id,
                  fotomerchantClientSessionTemplateId:
                    values.fotomerchantClientSessionTemplateId,
                  lastDateToPickPose: lastDateToPickPose,
                  lastDateForRetouch: lastDateForRetouch,
                  lastDateToBePhotographed: lastDateToBePhotographed,
                  name: values.name,
                  organizationId: values.organization.id,
                  sessionPackages: newSessionPackages,
                  metadata: JSON.stringify(parsedMetadata),
                  notes: values.notes,
                  manualSubjects,
                },
              },
            },
          })
        } else {
          updateSubjectGroup({
            variables: {
              updateSubjectGroupInput: {
                subjectGroupInput: {
                  category: values.category,
                  id: subjectGroupId,
                  sharedCanCreateFiles: values.sharedCanCreateFiles,
                  sharedCanCreateFolders: values.sharedCanCreateFolders,
                  sharedCanSeeFiles: values.sharedCanSeeFiles,
                  csvSubjects: csvSubjectsLen,
                  skipWelcomeEmail: values.skipWelcomeEmail,
                  resitsIncludeFreeSessions: values.resitsIncludeFreeSessions,
                  resitsAvailable: values.resitsAvailable,
                  subjects: transformedDatabaseSubjects,
                  hidePromoCodes: !values.allowPromoCodes,
                  startDateTime: start,
                  resitFeeFreeSessions: values.resitFeeFreeSessions,
                  resitFeePaidSessions: values.resitFeePaidSessions,
                  resitFee: values.resitFee,
                  fotomerchantClientSessionTemplateId:
                    values.fotomerchantClientSessionTemplateId,
                  freePackageResitFee: freePackageResitFee,
                  subjectsPaying: values.subjectsPaying,
                  endDateTime: end,
                  refundPolicy: values.refundPolicy,
                  applyNoShowPolicyFree: values.applyNoShowPolicyFree,
                  applyNoShowPolicyPaid: values.applyNoShowPolicyPaid,
                  applyPolicyFree: values.applyPolicyFree,
                  applyPolicyPaid: values.applyPolicyPaid,
                  timeRefundHours: values.timeRefundHours,
                  timeRefundFee: values.timeRefundFee,
                  timeRefundSessionPackageCost:
                    values.timeRefundSessionPackageCost,
                  noShowFee: values.noShowFee,
                  usingNoShowPolicy: values.usingNoShowPolicy,
                  usingCancellationPolicy: values.usingCancellationPolicy,
                  usingResitPolicy: values.usingResitPolicy,
                  lastDateToPickPose: lastDateToPickPose,
                  lastDateForRetouch: lastDateForRetouch,
                  lastDateToBePhotographed: lastDateToBePhotographed,
                  name: values.name,
                  metadata: JSON.stringify(parsedMetadata),
                  notes: values.notes,
                  organizationId: values.organization.id,
                  sessionPackages: newSessionPackages,
                  removeSubjects: values.removeSubjects,
                  manualSubjects,
                },
              },
            },
          })
        }
      }}
    >
      {(formik) => {
        return (
          <Row>
            <Col>
              <SubjectGroupForm
                organization={organization}
                formik={formik}
                folderConfiguration={folderConfiguration}
                setCsvRecordSync={setCsvRecordSync}
                editSubjectGroupId={subjectGroupId}
                updatingSubjectGroup={updatingSubjectGroup}
                metadata={
                  getSubjectGroupData
                    ? getSubjectGroupData.subjectGroup.metadata
                    : '{}'
                }
                subjectGroupUpdated={
                  updateSubjectGroupData?.updateSubjectGroup?.subjectGroup.id &&
                  !updateSubjectGroupLoading
                }
                subjectViewing={false}
                submitting={submitting}
              />
            </Col>
          </Row>
        )
      }}
    </Formik>
  )
}
