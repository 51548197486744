import React, { useEffect, useState } from 'react'
import { Table, Button } from 'react-bootstrap'
import {
  ArrowUp,
  ArrowDown,
  ArrowUpCircle,
  ArrowDownCircle,
  FilterCircle,
} from 'react-bootstrap-icons'
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table'
import 'bootstrap/dist/css/bootstrap.min.css'
import { settingsVar } from '../../libs/apollo'
import { useReactiveVar } from '@apollo/client'
import './SortableTable.css'
import Loading from './Loading'

/* Enabling server side sorting
  table props:
    handleSortByChange: required function will get orderby variable to pass Query
  
  column props 
    id: string required
    serverSort: true to enable server-side sorting
    orderBy: if provided then it will be call handleSortByChange function with this else it will call using id
*/

const SortableTable = (props) => {
  const {
    data,
    columns,
    onRowClick,
    tdStyle,
    trStyle,
    thStyle,
    onTdClicks,
    tableSize,
    loading,
    displayHeader,
    rowPointer,
    trStyleGenerator,
    tdStyleGenerator,
    setPreGlobalFilteredRows,
    setTableState,
    setSetGlobalFilter,
    hideGlobalFilter,
    hideInfinteGlobalFilter,
    searchWidth,
    searchOverlay,
    tableHeight,
    linkTds,
    discludeTrHover,
    handleSortByChange,
  } = props

  const settings = useReactiveVar(settingsVar)
  const [manualSortBy, setManualSortBy] = useState(false)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      manualSortBy: manualSortBy,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  )

  useEffect(() => {
    let column
    let orderBy = ''
    let currentColumnManualSortBy = false
    if (sortBy.length > 0) {
      currentColumnManualSortBy = sortBy.some((s) =>
        columns.find((col) => {
          if (col.id === s.id && col.serverSort) {
            col['desc'] = s.desc
            column = col
            return true
          }
        })
      )
    }
    setManualSortBy(currentColumnManualSortBy)
    if (currentColumnManualSortBy) {
      const columnName = column.orderBy ? column.orderBy : column.id
      orderBy = `${column.desc ? '-' : ''}${columnName}`
    }
    if (handleSortByChange) {
      handleSortByChange(orderBy)
    }
  }, [sortBy])

  let rowStyle = {}
  if (trStyle) {
    rowStyle = trStyle
  } else {
    rowStyle = {
      margin: 0,
      padding: 0,
      fontSize: '14px',
    }
  }
  let headerStyle = {}
  if (thStyle) {
    headerStyle = thStyle
  } else {
    headerStyle = { margin: 0, fontSize: '14px' }
  }

  if (rowPointer) {
    rowStyle.cursor = 'pointer'
  }

  let sWidth = 6
  if (searchWidth) {
    sWidth = searchWidth
  }

  useEffect(() => {
    if (hideInfinteGlobalFilter === false) {
      setPreGlobalFilteredRows(preGlobalFilteredRows)
      setTableState(state)
      setSetGlobalFilter({
        fn: setGlobalFilter,
      })
    }
  }, [hideInfinteGlobalFilter])

  const constructRow = (row) => {
    prepareRow(row)
    const { key, ...restRowProps } = row.getRowProps()
    if (trStyleGenerator) {
      rowStyle = trStyleGenerator(row)
    }
    return (
      <tr
        key={key}
        id={`tableRow${row.id}`}
        style={rowStyle}
        {...restRowProps}
        onMouseDown={() => {
          if (onRowClick) {
            onRowClick(row)
          }
        }}
        className={
          discludeTrHover ? 'table-row' : 'table-row sortable-table-row-hover'
        }
      >
        {row.cells.map((cell) => {
          const { key, ...restCellProps } = cell.getCellProps()
          let tdStyle_ = tdStyle
          if (tdStyleGenerator) {
            tdStyle_ = tdStyleGenerator(cell)
          }
          return (
            <td
              key={key}
              style={tdStyle_}
              {...restCellProps}
              onMouseDown={() => {
                if (
                  onTdClicks &&
                  Object.keys(onTdClicks).includes(cell.column.id)
                ) {
                  return onTdClicks[cell.column.id](cell)
                }
              }}
              className="align-middle"
            >
              <div className="sortable-table">
                {linkTds && (
                  <div className="sessions-buttons btn-link">
                    {cell.render('Cell')}
                  </div>
                )}
                {!linkTds && <>{cell.render('Cell')}</>}
              </div>
            </td>
          )
        })}
      </tr>
    )
  }

  let style = {
    fontWeight: 'thin',
  }
  if (tableHeight) {
    style = {
      ...style,
      maxHeight: `${tableHeight}px`,
    }
  }
  if (!settings) return <></>
  return (
    <>
      <Table
        className="table-bordered table-rounded"
        style={style}
        size={tableSize ? tableSize : 'sm'}
        responsive
        {...getTableProps()}
      >
        {displayHeader !== false && (
          <thead
            style={{
              backgroundColor: settings.colorScheme,
            }}
          >
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => {
                  if (column.hideHeader) {
                    return null
                  }
                  return (
                    <th
                      key={index}
                      {...(column.serverSort
                        ? column.getHeaderProps(column.getSortByToggleProps())
                        : column.getHeaderProps())}
                      className="align-middle"
                      style={{
                        ...{
                          cursor: column.serverSort ? 'pointer' : 'default',
                        },
                        ...headerStyle,
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {column.render('Header')}
                        <span
                          style={{
                            marginLeft: '8px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {column.serverSort ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <Button variant="link" style={{ padding: 0 }}>
                                  <ArrowUpCircle />
                                </Button>
                              ) : (
                                <Button variant="link" style={{ padding: 0 }}>
                                  <ArrowDownCircle />
                                </Button>
                              )
                            ) : (
                              <></>
                              // <Button variant="link" style={{ padding: 0 }}>
                              //   <FilterCircle />
                              // </Button>
                            )
                          ) : (
                            column.isSorted &&
                            (column.isSortedDesc ? (
                              <ArrowDown className="ml-2 sort-icon" />
                            ) : (
                              <ArrowUp className="ml-2 sort-icon" />
                            ))
                          )}
                        </span>
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
        )}

        <tbody {...getTableBodyProps()}>
          {loading && (
            <tr style={{ border: 'none' }}>
              <td
                colSpan={columns.length}
                style={{ textAlign: 'center', padding: '20px', border: 'none' }}
              >
                <Loading />
              </td>
            </tr>
          )}
          {!loading && (
            <>
              {rows.map((row) => {
                return constructRow(row)
              })}
            </>
          )}
        </tbody>
      </Table>
    </>
  )
}

export default SortableTable
