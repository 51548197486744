import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useFormik, FieldArray, FormikProvider } from 'formik'
import * as Yup from 'yup'
import { client } from '../../libs/apollo'
import toast from 'react-hot-toast'
import {
  PlusCircle,
  Trash,
  Collection,
  CaretRight,
  CaretDown,
} from 'react-bootstrap-icons'
import EmployeeSearchInput from '../common/node_search_input/EmployeeSearchInput'
import Loading from '../common/Loading'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'
import AuditLog from '../audit_log/AuditLog'

const TaskCollectionModal = (props) => {
  const loggedInUser = useReactiveVar(loggedInUserVar)
  const { showModal, toggleModal, taskCollection } = props
  const [loading, setLoading] = useState(false)
  const [displayAuditLog, setDisplayAuditLog] = useState(false)
  const canMutate = ['Administrator', 'Scheduling Manager'].includes(
    loggedInUser?.permissions?.group
  )
  const [createTaskCollection] = useMutation(
    gql`
      mutation CreateTaskCollection($input: CreateTaskCollectionInput!) {
        createTaskCollection(input: $input) {
          taskCollection {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setLoading(false)
        toast.success('Task Collection Created')
        innerToggleModal()
      },
      errorPolicy: 'all',
      refetchQueries: ['TaskCollectionsQuery'],
    }
  )

  const [updateTaskCollection] = useMutation(
    gql`
      mutation UpdateTaskCollection($input: UpdateTaskCollectionInput!) {
        updateTaskCollection(input: $input) {
          taskCollection {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setLoading(false)
        toast.success('Task Collection Updated')
        innerToggleModal()
      },
      errorPolicy: 'all',
      refetchQueries: ['TaskCollectionsQuery'],
    }
  )

  const [deleteMutation] = useMutation(
    gql`
      mutation DeleteTaskCollectionMutations(
        $deleteTaskCollectionInput: DeleteTaskCollectionInput!
      ) {
        deleteTaskCollection(input: $deleteTaskCollectionInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        innerToggleModal()
        toast.success('Task Collection Deleted')
      },
      errorPolicy: 'all',
      refetchQueries: ['TaskCollectionsQuery'],
    }
  )

  const formik = useFormik({
    initialValues: {
      name: '',
      taskCollectionTasks: [
        {
          description: '',
          dueDateDeltaBeforeAfter: 'BEFORE',
          dueDateDelta: 0,
          sendOverdueNotification: true,
          employees: [],
          sharedCanCreateFiles: false,
          sharedCanCreateFolders: false,
          sharedCanSeeFiles: false,
        },
      ],
      collectionType: 'MISC',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      collectionType: Yup.string().required('required'),
      name: Yup.string().required('required'),
      taskCollectionTasks: Yup.array().of(
        Yup.object().shape({
          notes: Yup.string().nullable(),
          sendOverdueNotification: Yup.boolean().nullable(),
          description: Yup.string().required('Required'),
          dueDateDeltaBeforeAfter: Yup.string().nullable(),
          dueDateDelta: Yup.number().nullable().min(0),
          sharedCanCreateFiles: Yup.boolean().nullable(),
          sharedCanCreateFolders: Yup.boolean().nullable(),
          sharedCanSeeFiles: Yup.boolean().nullable(),
          employees: Yup.array().of(
            Yup.object()
              .shape({
                id: Yup.string().required('Required'),
                description: Yup.string().required('Required'),
              })
              .nullable()
          ),
        })
      ),
    }),
    onSubmit: (values) => {
      setLoading(true)
      const taskCollectionTasks = values.taskCollectionTasks.map((item) => {
        const tasks = {
          notes: item.notes,
          description: item.description,
          sendOverdueNotification: item.sendOverdueNotification,
          employeeIds: item.employees.map((employee) => employee.id),
          sharedCanCreateFiles: !item.sharedCanSeeFiles
            ? false
            : item.sharedCanCreateFiles,
          sharedCanCreateFolders: !item.sharedCanSeeFiles
            ? false
            : item.sharedCanCreateFolders,
          sharedCanSeeFiles: item.sharedCanSeeFiles,
        }
        if (values.collectionType === 'JOB') {
          tasks.dueDateDeltaBeforeAfter = item.dueDateDeltaBeforeAfter
          tasks.dueDateDelta = item.dueDateDelta
        }
        if (taskCollection) {
          tasks.id = item.id
        }
        return tasks
      })
      if (taskCollection) {
        updateTaskCollection({
          variables: {
            input: {
              taskCollectionInput: {
                id: taskCollection.id,
                name: values.name,
                collectionType: values.collectionType,
                taskCollectionTasks,
              },
            },
          },
        })
      } else {
        createTaskCollection({
          variables: {
            input: {
              taskCollectionInput: {
                name: values.name,
                collectionType: values.collectionType,
                taskCollectionTasks,
              },
            },
          },
        })
      }
    },
  })

  const innerToggleModal = () => {
    toggleModal()
    formik.resetForm()
    setLoading(false)
    setDisplayAuditLog(false)
  }

  useEffect(() => {
    if (formik && !formik.isSubmitting && showModal && taskCollection) {
      formik.setValues({
        name: taskCollection.name,
        collectionType: taskCollection.collectionType,
        taskCollectionTasks: taskCollection.taskCollectionTasks.edges.map(
          (edge) => ({
            id: edge.node.id,
            notes: edge.node.notes,
            description: edge.node.description,
            sendOverdueNotification: edge.node.sendOverdueNotification,
            dueDateDelta: edge.node.dueDateDelta,
            dueDateDeltaBeforeAfter: edge.node.dueDateDeltaBeforeAfter,
            employees: edge.node.employees.edges.map((employeeEdge) => ({
              id: employeeEdge.node.id,
              description: employeeEdge.node.gaiaUser.fullName,
            })),
            sharedCanCreateFiles: edge.node.sharedCanCreateFiles,
            sharedCanCreateFolders: edge.node.sharedCanCreateFolders,
            sharedCanSeeFiles: edge.node.sharedCanSeeFiles,
          })
        ),
      })
    }
  }, [showModal, taskCollection])

  return (
    <FormikProvider value={formik}>
      <div className="newTaskCollectionModal">
        <Modal
          size={displayAuditLog ? 'xl' : 'lg'}
          show={showModal}
          onHide={innerToggleModal}
          aria-labelledby="taskGroupModal"
        >
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title id="taskGroupModal">
                <Collection className="mr-2" />
                {taskCollection
                  ? 'Update Task Collection'
                  : 'New Task Collection'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group as={Row} className="mb-3 align-items-center">
                <Col sm="6">
                  <Form.Label>Collection Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    disabled={!canMutate}
                    className="form-control-sm"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Col>
                <Col sm="6">
                  <Form.Label>Collection Type</Form.Label>
                  <Form.Control
                    name="collectionType"
                    as="select"
                    disabled={!canMutate}
                    className="form-control form-control-sm"
                    value={formik.values?.collectionType}
                    onChange={(e) => {
                      formik.setFieldValue('collectionType', e.target.value)
                      if (e.target.value === 'JOB') {
                        formik.values.taskCollectionTasks
                          .filter((task) => !task.dueDateDeltaBeforeAfter)
                          .map((task, index) => {
                            formik.setFieldValue(
                              `taskCollectionTasks[${index}].dueDateDeltaBeforeAfter`,
                              'BEFORE'
                            )
                            formik.setFieldValue(
                              `taskCollectionTasks[${index}].dueDateDelta`,
                              0
                            )
                          })
                      } else if (e.target.value === 'SUBJECT_GROUP') {
                        formik.values.taskCollectionTasks.forEach(
                          (task, index) => {
                            formik.setFieldValue(
                              `taskCollectionTasks[${index}].sharedCanCreateFiles`,
                              false
                            )
                            formik.setFieldValue(
                              `taskCollectionTasks[${index}].sharedCanCreateFolders`,
                              false
                            )
                            formik.setFieldValue(
                              `taskCollectionTasks[${index}].sharedCanSeeFiles`,
                              false
                            )
                          }
                        )
                      }
                    }}
                    isInvalid={formik?.errors?.collectionType}
                  >
                    <option value={'MISC'}>Miscellaneous</option>
                    <option value={'JOB'}>Job</option>
                    <option value={'ORGANIZATION'}>Organization</option>
                    <option value={'SUBJECT_GROUP'}>Subject Group</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formik?.errors?.collectionType}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {formik.values.taskCollectionTasks?.length > 0 && (
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Tasks ({formik.values.taskCollectionTasks?.length})
                  </Form.Label>
                </Form.Group>
              )}

              <FieldArray
                name="taskCollectionTasks"
                render={(arrayHelpers) => (
                  <>
                    <div
                      style={{
                        maxHeight: '350px',
                        overflowY: 'auto',
                        marginBottom: '10px',
                      }}
                    >
                      {formik.values.taskCollectionTasks.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            paddingTop:
                              item?.employees.length > 0 ? '0px' : '30px',
                            paddingBottom: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          <Row className=" align-items-center">
                            <Col sm="6">
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                rows={2}
                                name={`taskCollectionTasks[${index}].description`}
                                value={item.description}
                                disabled={!canMutate}
                                onChange={formik.handleChange}
                                isInvalid={
                                  formik.errors.taskCollectionTasks &&
                                  formik.errors.taskCollectionTasks[index]
                                    ?.description
                                }
                                className="form-control-sm"
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.taskCollectionTasks &&
                                  formik.errors.taskCollectionTasks[index]
                                    ?.description}
                              </Form.Control.Feedback>
                            </Col>
                            <Col
                              sm="6"
                              style={
                                item.employees.length > 0
                                  ? { marginTop: '30px' }
                                  : {}
                              }
                            >
                              <Form.Label>Assigned To</Form.Label>
                              <EmployeeSearchInput
                                formik={formik}
                                multiple
                                disabled={!canMutate}
                                formikMultipleValue={`taskCollectionTasks[${index}].employees`}
                                dropdown
                              />
                            </Col>
                          </Row>
                          {formik.values.collectionType === 'JOB' && (
                            <Row className="mt-2 align-items-center">
                              <Col sm="6">
                                <Form.Label>Due</Form.Label>
                                <Form.Control
                                  name={`taskCollectionTasks[${index}].dueDateDeltaBeforeAfter`}
                                  as="select"
                                  disabled={!canMutate}
                                  className="form-control form-control-sm"
                                  value={item.dueDateDeltaBeforeAfter}
                                  onChange={formik?.handleChange}
                                  isInvalid={
                                    formik.errors.taskCollectionTasks &&
                                    formik.errors.taskCollectionTasks[index]
                                      ?.dueDateDeltaBeforeAfter
                                  }
                                >
                                  <option value={'BEFORE'}>
                                    Before Job Date
                                  </option>
                                  <option value={'AFTER'}>
                                    After Job Date
                                  </option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.taskCollectionTasks &&
                                    formik.errors.taskCollectionTasks[index]
                                      ?.dueDateDeltaBeforeAfter}
                                </Form.Control.Feedback>
                              </Col>
                              <Col sm="6">
                                <Form.Label>
                                  Due{' '}
                                  {formik.values.taskCollectionTasks &&
                                    formik.values.taskCollectionTasks[index]
                                      ?.dueDateDelta}{' '}
                                  Days{' '}
                                  {formik.values.taskCollectionTasks &&
                                    formik.values.taskCollectionTasks[
                                      index
                                    ]?.dueDateDeltaBeforeAfter
                                      ?.charAt(0)
                                      .toUpperCase() +
                                      formik.values.taskCollectionTasks[
                                        index
                                      ]?.dueDateDeltaBeforeAfter
                                        ?.slice(1)
                                        .toLowerCase()}{' '}
                                  Job Date
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name={`taskCollectionTasks[${index}].dueDateDelta`}
                                  className="form-control-sm"
                                  disabled={!canMutate}
                                  value={item.dueDateDelta}
                                  onChange={formik.handleChange}
                                  isInvalid={
                                    formik.errors.taskCollectionTasks &&
                                    formik.errors.taskCollectionTasks[index]
                                      ?.dueDateDelta
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formik.errors.taskCollectionTasks &&
                                    formik.errors.taskCollectionTasks[index]
                                      ?.dueDateDelta}
                                </Form.Control.Feedback>
                              </Col>
                            </Row>
                          )}
                          <Row className="mt-3">
                            <Col>
                              <Form.Label>Notes</Form.Label>
                              <Form.Control
                                as="textarea"
                                style={{ height: '80px' }}
                                className="form-control-sm"
                                name={`taskCollectionTasks[${index}].notes`}
                                value={item.notes}
                                onChange={formik.handleChange}
                              />
                            </Col>
                          </Row>
                          {formik.values.collectionType !== 'SUBJECT_GROUP' && (
                            <Row
                              style={{ marginTop: '20px', padding: '0 10px' }}
                            >
                              <Col>
                                <span
                                  onClick={() =>
                                    formik.setFieldValue(
                                      `taskCollectionTasks[${index}].sharedCanSeeFiles`,
                                      !formik.values.taskCollectionTasks[index]
                                        ?.sharedCanSeeFiles
                                    )
                                  }
                                  style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    padding: '5px 10px',
                                  }}
                                >
                                  <Form.Check
                                    inline
                                    name={`taskCollectionTasks[${index}].sharedCanSeeFiles`}
                                    type="switch"
                                    label="Contacts Can See Files"
                                    checked={
                                      formik.values.taskCollectionTasks[index]
                                        ?.sharedCanSeeFiles || false
                                    }
                                    style={{
                                      fontSize: '0.9rem',
                                      color: '#333',
                                    }}
                                  />
                                </span>
                              </Col>

                              {formik.values.taskCollectionTasks[index]
                                ?.sharedCanSeeFiles && (
                                <>
                                  <Col style={{ marginBottom: '5px' }}>
                                    <span
                                      onClick={() =>
                                        formik.setFieldValue(
                                          `taskCollectionTasks[${index}].sharedCanCreateFiles`,
                                          !formik.values.taskCollectionTasks[
                                            index
                                          ]?.sharedCanCreateFiles
                                        )
                                      }
                                      style={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        padding: '5px 10px',
                                      }}
                                    >
                                      <Form.Check
                                        inline
                                        name={`taskCollectionTasks[${index}].sharedCanCreateFiles`}
                                        type="switch"
                                        label="Contacts Can Create Files"
                                        checked={
                                          formik.values.taskCollectionTasks[
                                            index
                                          ]?.sharedCanCreateFiles || false
                                        }
                                        style={{
                                          fontSize: '0.9rem',
                                          color: '#333',
                                        }}
                                      />
                                    </span>
                                  </Col>

                                  <Col>
                                    <span
                                      onClick={() =>
                                        formik.setFieldValue(
                                          `taskCollectionTasks[${index}].sharedCanCreateFolders`,
                                          !formik.values.taskCollectionTasks[
                                            index
                                          ]?.sharedCanCreateFolders
                                        )
                                      }
                                      style={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        padding: '5px 10px',
                                      }}
                                    >
                                      <Form.Check
                                        inline
                                        name={`taskCollectionTasks[${index}].sharedCanCreateFolders`}
                                        type="switch"
                                        label="Contacts Can Create Folders"
                                        checked={
                                          formik.values.taskCollectionTasks[
                                            index
                                          ]?.sharedCanCreateFolders || false
                                        }
                                        style={{
                                          fontSize: '0.9rem',
                                          color: '#333',
                                        }}
                                      />
                                    </span>
                                  </Col>
                                </>
                              )}
                            </Row>
                          )}
                          <Row style={{ padding: '0 10px' }}>
                            <Col style={{ marginBottom: '5px' }}>
                              <span
                                onClick={() =>
                                  formik.setFieldValue(
                                    `taskCollectionTasks[${index}].sendOverdueNotification`,
                                    !formik.values.taskCollectionTasks[index]
                                      ?.sendOverdueNotification
                                  )
                                }
                                style={{
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  padding: '5px 10px',
                                }}
                              >
                                <Form.Check
                                  inline
                                  name={`taskCollectionTasks[${index}].sendOverdueNotification`}
                                  type="switch"
                                  label="Send Notifications When Overdue"
                                  checked={
                                    formik.values.taskCollectionTasks[index]
                                      ?.sendOverdueNotification || false
                                  }
                                  style={{
                                    fontSize: '0.9rem',
                                    color: '#333',
                                  }}
                                />
                              </span>
                            </Col>
                          </Row>
                          {canMutate && (
                            <Row>
                              <Col sm="2" className="text-left">
                                <Button
                                  type="button"
                                  variant="link"
                                  size="sm"
                                  style={{ color: '#007bff' }}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <Trash className="mr-2" />
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </div>
                      ))}
                    </div>
                    {canMutate && (
                      <Button
                        type="button"
                        size="sm"
                        className="btn-link"
                        onClick={() =>
                          arrayHelpers.push({
                            description: '',
                            dueDateDeltaBeforeAfter: 'BEFORE',
                            dueDateDelta: 0,
                            notes: '',
                            sendOverdueNotification: true,
                            sharedCanCreateFiles: false,
                            sharedCanCreateFolders: false,
                            sharedCanSeeFiles: false,
                            employees: [],
                          })
                        }
                      >
                        <PlusCircle className="mr-2" />
                        Add Task
                      </Button>
                    )}
                  </>
                )}
              />
              {canMutate && taskCollection && (
                <>
                  <Row className="mt-3 mb-2">
                    <Col md={12} className="d-flex align-items-center">
                      <button
                        type="button"
                        onClick={() => setDisplayAuditLog(!displayAuditLog)}
                        className="px-0 btn-link mr-1"
                      >
                        <>
                          {displayAuditLog ? (
                            <>
                              <CaretDown size={15} />
                            </>
                          ) : (
                            <>
                              <CaretRight size={15} />
                            </>
                          )}
                        </>
                      </button>
                      <Form.Label className="mb-0">History</Form.Label>
                    </Col>
                  </Row>
                  {displayAuditLog && (
                    <Row>
                      <Col md={12}>
                        {taskCollection.taskCollectionTasks.edges.length >
                          0 && (
                          <AuditLog
                            includeContentType
                            contentTypesRelayIds={{
                              [taskCollection.contentType.model]: [
                                taskCollection.id,
                              ],
                              [taskCollection.taskCollectionTasks.edges[0].node
                                .contentType.model]:
                                taskCollection.taskCollectionTasks.edges.map(
                                  (edge) => edge.node.id
                                ),
                            }}
                          />
                        )}
                        {taskCollection.taskCollectionTasks.edges.length ===
                          0 && (
                          <AuditLog
                            contentType={taskCollection.contentType.model}
                            id={taskCollection.id}
                          />
                        )}
                      </Col>
                    </Row>
                  )}
                </>
              )}
              {canMutate && (
                <Row className="mt-4">
                  <Col md={3}>
                    <Button
                      type="submit"
                      size="sm"
                      block
                      disabled={loading}
                      variant="outline-primary"
                    >
                      <PlusCircle className="mr-2" />
                      Save
                    </Button>
                  </Col>
                  {taskCollection && (
                    <Col md={3}>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        block
                        disabled={loading}
                        onClick={() => {
                          deleteMutation({
                            variables: {
                              deleteTaskCollectionInput: {
                                taskCollectionIds: taskCollection.id,
                              },
                            },
                          })
                        }}
                      >
                        <Trash className="mr-2" />
                        Delete
                      </Button>
                    </Col>
                  )}
                </Row>
              )}

              {loading && <Loading />}
            </Modal.Body>
          </Form>
        </Modal>
      </div>
    </FormikProvider>
  )
}

export default TaskCollectionModal
