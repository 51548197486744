import React, { useState } from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import { useMutation, gql } from '@apollo/client'
import toast from 'react-hot-toast'
import { Trash } from 'react-bootstrap-icons'
import Loading from '../common/Loading'

const DeleteTaskCollectionModal = (props) => {
  const { showModal, toggleModal, id, ids, onComplete, setIds } = props
  const [submitting, setSubmitting] = useState(false)

  const [deleteTaskCollectionMutation] = useMutation(
    gql`
      mutation DeleteTaskCollection(
        $deleteTaskCollectionInput: DeleteTaskCollectionInput!
      ) {
        deleteTaskCollection(input: $deleteTaskCollectionInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        if (ids) {
          toast.success('Task Collections Deleted')
        } else {
          toast.success('Task Collection Deleted')
        }
        setIds([])
        toggleModal()
        if (onComplete) {
          onComplete()
        }
      },
      errorPolicy: 'all',
      refetchQueries: ['TaskCollectionsQuery'],
    }
  )

  const handleDeleteClick = () => {
    setSubmitting(true)
    deleteTaskCollectionMutation({
      variables: {
        deleteTaskCollectionInput: {
          taskCollectionIds: id ? [id] : ids,
        },
      },
    })
  }

  if (!showModal) return <></>
  return (
    <>
      <div className="editSessionResitReasonModal">
        <Modal
          size="md"
          show={showModal}
          onHide={() => toggleModal()}
          aria-labelledby="newResitReason"
          className="invmodal detail"
        >
          <Modal.Header closeButton>
            <Modal.Title id="new-title">Delete Task{ids && <>s</>}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="text-center">
                <p style={{ fontSize: '1rem' }}>
                  Are you sure you want to delete the task collection
                  {ids && <>s</>}?
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  block
                  disabled={submitting}
                  onClick={handleDeleteClick}
                >
                  <Trash className="mr-2" />
                  Delete
                </Button>
              </Col>
            </Row>
            {submitting && <Loading />}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default DeleteTaskCollectionModal
