import React, { useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { Row, Col, Form, Button, Table } from 'react-bootstrap'
import { Person, PlusCircle } from 'react-bootstrap-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import NewSubjectModal from '../subject/NewSubjectModal'
import Loading from '../common/Loading'

export default function TechLookup(props) {
  const { handleUserFound, colMd } = props
  const [lookupValue, setLookupValue] = useState('')
  const [displayResults, setDisplayResults] = useState(false)
  const [displayNewSubModal, setDisplayNewSubModal] = useState(false)

  const [
    getAllSubjects,
    { data: subjectData, fetchMore: fetchMoreAllSubjects },
  ] = useLazyQuery(
    gql`
      query Subjects(
        $after: String
        $first: Int
        $gaiaUserFullNameIcontains: String
      ) {
        subjects(
          after: $after
          first: $first
          gaiaUser_Employee_Id_Isnull: true
          gaiaUser_FullName_Icontains: $gaiaUserFullNameIcontains
        ) {
          nodeCount
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              studentId
              subjectGroupsNotBooked {
                subjectGroup {
                  id
                  name
                  startDateTime
                  endDateTime
                }
              }
              organization {
                id
                name
              }
              gaiaUser {
                id
                fullName
                email
                dummyUsername
                stripeCustomer {
                  stripePaymentMethods(primary: true) {
                    edges {
                      node {
                        stripeResource
                        created
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )
  const handleLookupValueChange = (e) => {
    setLookupValue(e.target.value)
    if (e.target.value !== '') {
      setDisplayResults(true)
      getAllSubjects({
        variables: {
          first: 10,
          after: null,
          gaiaUserFullNameIcontains: e.target.value,
        },
      })
    } else {
      setDisplayResults(false)
    }
  }

  const handleFetchMoreSubjects = () => {
    fetchMoreAllSubjects({
      variables: {
        first: 10,
        after: subjectData.subjects.pageInfo.endCursor,
        gaiaUserFullNameIcontains: lookupValue,
      },
    })
  }

  const handleUserClick = (user) => {
    handleUserFound(user.gaiaUser, user)
    setLookupValue('')
    setDisplayResults(false)
  }

  const handleSubModalChange = () => {
    setDisplayNewSubModal((prevState) => !prevState)
  }

  const handleUserCreate = (data) => {
    handleUserFound(
      data.createSubject.subject.gaiaUser,
      data.createSubject.subject
    )
  }

  return (
    <>
      <Row>
        <Col md={colMd ? colMd : 12}>
          <Form className="mb-2">
            <Form.Label>
              <span style={{ fontSize: '17px' }}>Subject Lookup </span>
            </Form.Label>
            <Button onClick={() => setDisplayNewSubModal(true)} variant="link">
              <PlusCircle className="mr-2" />
              New Subject
            </Button>
            <Form.Control
              value={lookupValue}
              onChange={handleLookupValueChange}
              placeholder="Search Subjects"
              className="form-control-sm"
            />
          </Form>
          <div className={displayResults ? '' : 'd-none'}>
            {subjectData && (
              <InfiniteScroll
                height={150}
                dataLength={subjectData?.subjects?.edges?.length}
                next={handleFetchMoreSubjects}
                hasMore={subjectData?.subjects?.pageInfo?.hasNextPage}
                loader={<Loading />}
              >
                <Table>
                  <tbody>
                    {subjectData.subjects.edges.map((subject) => {
                      const { node } = subject
                      return (
                        <tr
                          style={{
                            cursor: 'pointer',
                          }}
                          key={node.id}
                          onClick={() => handleUserClick(node)}
                        >
                          <td>
                            {node.gaiaUser.fullName}
                            <small className="text-secondary">
                              {node.gaiaUser.email &&
                              !node.gaiaUser.dummyUsername
                                ? ', ' + node.gaiaUser.email
                                : ''}
                              {node.organization?.name
                                ? ', ' + node.organization.name
                                : ''}
                              {node.studentId ? ', ' + node.studentId : ''}
                              {node.gaiaUser.phoneNumber
                                ? ', ' + node.gaiaUser.phoneNumber
                                : ''}
                            </small>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </InfiniteScroll>
            )}
          </div>
        </Col>
      </Row>
      <NewSubjectModal
        showSubjectModal={displayNewSubModal}
        subjectModalShowChange={handleSubModalChange}
        onCompleted={handleUserCreate}
        updateSubject={false}
      />
    </>
  )
}
