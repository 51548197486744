import React from 'react'
import { useEffect, useState } from 'react'
import 'react-csv-importer/dist/index.css'
import { Row, Col, Form, Button, Table } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useLazyQuery, gql, useMutation } from '@apollo/client'
import { Field, ErrorMessage, FieldArray } from 'formik'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useHistory } from 'react-router-dom'
import { US_STATES } from '../../libs/constant'
import {
  Trash,
  PlusCircle,
  ExclamationCircle,
  ExclamationTriangle,
  ArrowCounterclockwise,
  Images,
  Person,
  CloudArrowDown,
  XCircle,
} from 'react-bootstrap-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import SubjectCsvImport from '../subject/SubjectCsvImport'
import Loading from '../common/Loading'
import PhoneInput from 'react-phone-input-2'
import MetaDataTable from '../common/MetaData'
import { useFotomerchantEnabled } from '../../libs/fotomerchant'
import { useImagequixEnabled } from '../../libs/imagequix'
import { useParams } from 'react-router-dom'
import Policies from '../common/Policies'
import { useDownloadFile } from '../../libs/downloadFile'

export default function SubjectGroupForm(props) {
  const {
    formik,
    editSubjectGroupId,
    updatingSubjectGroup,
    submitting,
    metadata,
    folderConfiguration,
    setCsvRecordSync,
    organization,
  } = props
  const { contact } = useParams()
  const iqEnabled = useImagequixEnabled()
  const { downloadAndDeleteFile } = useDownloadFile()
  const [downloadingBlueprintEmails, setDownloadingBlueprintEmails] =
    useState(false)
  const [showPackageResults, setShowPackageResults] = useState(false)
  const [packageResultIndex, setPackageResultIndex] = useState(0)
  const [showSubjectResults, setShowSubjectResults] = useState(false)
  const [subjectResultIndex, setSubjectResultIndex] = useState(0)
  const [csvDataTransformed, setCsvDataTransformed] = useState([])
  const [blueprintEmailsFile, setBlueprintEmailsFile] = useState(null)
  const [displayOrganizationResults, setDisplayOrganizationResults] =
    useState(false)
  const [importerKey, setImporterKey] = useState('5678')
  const [rowsMissingInfo, setRowsMissingInfo] = useState([])
  const [metaData, setMetaData] = useState(
    metadata === '{}'
      ? JSON.parse(metadata)
      : JSON.parse(metadata ? metadata : '{}')
  )
  const [rowFatalError, setRowFatalError] = useState(false)
  const history = useHistory()
  const [fmClientSessionTemplatesPage, setFmClientSessionTemplatesPage] =
    useState(1)
  const [fmApiClientSessionTemplates, setFmApiClientSessionTemplates] =
    useState([])
  const [
    fetchedAllFmClientSessionTemplates,
    setFetchedAllFmClientSessionTemplates,
  ] = useState(false)
  const fotomerchantEnabled = useFotomerchantEnabled()

  const [extractBlueprintEmails] = useMutation(
    gql`
      mutation ExtractBlueprintEmails($input: ExtractBlueprintEmailsInput!) {
        extractBlueprintEmails(input: $input) {
          file {
            id
            fileName
            displayName
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        downloadAndDeleteFile(
          data.extractBlueprintEmails.file.fileName,
          data.extractBlueprintEmails.file.displayName,
          data.extractBlueprintEmails.file.id,
          () => {
            setDownloadingBlueprintEmails(false)
            toast.success(`Blueprint Emails Extracted`)
          }
        )
      },
    }
  )

  const [
    getExistingSubjects,
    { data: existingSubjectsData, fetchMore: fetchMoreExistingSubjects },
  ] = useLazyQuery(
    gql`
      query ExistingSubjectsQuery(
        $subjectsGaiaUserIsActive: Boolean
        $subjectsFirst: Int
        $subjectsAfter: String
        $subjectsSubjectGroups: [ID]
      ) {
        subjects(
          gaiaUser_IsActive: $subjectsGaiaUserIsActive
          first: $subjectsFirst
          after: $subjectsAfter
          subjectGroups: $subjectsSubjectGroups
          orderBy: "gaia_user__full_name"
        ) {
          edges {
            node {
              id
              gaiaUser {
                id
                fullName
                dummyUsername
                phoneNumber
                email
              }
              studentId
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [
    getFmApiClientSessionTemplates,
    { data: fmApiClientSessionTemplatesData },
  ] = useLazyQuery(
    gql`
      query FotomerchantApiClientSessionTemplates($page: Int!) {
        fotomerchantApiClientSessionTemplates(page: $page, perPage: 25) {
          fotomerchantApiClientSessionTemplates
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (editSubjectGroupId) {
      getExistingSubjects({
        variables: {
          subjectsGaiaUserIsActive: true,
          subjectsFirst: 15,
          subjectsSubjectGroups: [editSubjectGroupId],
        },
      })
    }
  }, [editSubjectGroupId])

  const updateCsvData = (transformedData) => {
    setCsvDataTransformed(transformedData)
    formik.setFieldValue('csvSubjects', transformedData)
  }

  const handleFetchMoreExistingSubjects = () => {
    fetchMoreExistingSubjects({
      variables: {
        subjectsGaiaUserIsActive: true,
        subjectsFirst: 15,
        subjectsAfter: existingSubjectsData.subjects.pageInfo.endCursor,
        subjectsSubjectGroups: [editSubjectGroupId],
      },
    })
  }

  const [
    searchOrganizations,
    { data: organizationData, fetchMore: fetchMoreOrganizations },
  ] = useLazyQuery(
    gql`
      query Organizations($after: String, $first: Int, $nameIcontains: String) {
        organizations(
          after: $after
          first: $first
          name_Icontains: $nameIcontains
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
              stripeCustomer {
                stripeId
              }
              subjectGroupSharedCanSeeFiles
              subjectGroupSharedCanCreateFiles
              subjectGroupSharedCanCreateFolders
              subjectGroups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const organizationChange = (e) => {
    setDisplayOrganizationResults(true)
    formik.setFieldValue(`organization.name`, e.target.value)
    searchOrganizations({
      variables: {
        after: null,
        first: 5,
        nameIcontains: e.target.value,
      },
      fetchPolicy: 'network-only',
    })
  }

  const handleSubjectPackageClick = (i, subjectGroupNode) => {
    let findIndex = formik.values.sessionPackages.findIndex(
      (x) => x.id === subjectGroupNode.id
    )
    if (findIndex === -1) {
      formik.setFieldValue(`sessionPackages[${i}]`, {
        id: subjectGroupNode.id,
        title: subjectGroupNode.title,
      })
    } else {
      toast.error('Can not add duplicate Subject Package')
    }
  }

  const handleOrganizationXClick = () => {
    formik.setFieldValue(`organization.id`, '')
    formik.setFieldValue(`organization.name`, '')
    formik.setFieldValue(
      'sharedCanSeeFiles',
      folderConfiguration.subjectGroupSharedCanSeeFiles
    )
    formik.setFieldValue(
      'sharedCanCreate',
      folderConfiguration.subjectGroupSharedCanCreate
    )
    formik.setFieldValue(
      'sharedCanCreateFolders',
      folderConfiguration.subjectGroupSharedCanCreateFolders
    )
  }

  const handleFetchMorePackages = (index) => {
    fetchMorePackages({
      variables: {
        sessionPackagesAfter: packageData.pageInfo.endCursor,
        sessionPackagesFirst: 10,
        sessionPackagesTitleIstartswith:
          formik.values.sessionPackages[index].title,
        sessionPackagesArchived: false,
      },
      fetchPolicy: 'network-only',
    })
  }

  const [searchPackages, { data: packageData, fetchMore: fetchMorePackages }] =
    useLazyQuery(
      gql`
        query PackagesQuery(
          $sessionPackagesAfter: String
          $sessionPackagesFirst: Int
          $sessionPackagesTitleIstartswith: String
          $sessionPackagesArchived: Boolean
        ) {
          sessionPackages(
            after: $sessionPackagesAfter
            first: $sessionPackagesFirst
            title_Istartswith: $sessionPackagesTitleIstartswith
            archived: $sessionPackagesArchived
            customPriceAndDuration: false
          ) {
            edges {
              node {
                id
                title
                price
                durationHighMinutes
                durationLowMinutes
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )

  const handleSessionPackageChange = (e, index) => {
    if (formik.values.sessionPackages[index].id !== '') {
      formik.setFieldValue(`sessionPackages[${index}].title`, '')
      formik.setFieldValue(`sessionPackages[${index}].id`, '')
    } else {
      formik.setFieldValue(`sessionPackages[${index}].title`, e.target.value)
      searchPackages({
        variables: {
          sessionPackagesFirst: 10,
          sessionPackagesTitleIstartswith: e.target.value,
          sessionPackagesArchived: false,
        },
        fetchPolicy: 'network-only',
      })
      setPackageResultIndex(index)
      setShowPackageResults(true)
    }
  }

  const handleFetchMoreSubjects = (index) => {
    fetchMoreSubjects({
      variables: {
        subjectsAfter: subjectData.subjects.pageInfo.endCursor,
        subjectsFirst: 15,
        subjectsGaiaUserIsActive: true,
        organization: formik.values.organization.id,
        subjectsGaiaUserFullNameIcontains:
          formik.values.databaseSubjects[index].fullName,
      },
      fetchPolicy: 'network-only',
    })
  }

  const [searchSubjects, { data: subjectData, fetchMore: fetchMoreSubjects }] =
    useLazyQuery(
      gql`
        query SubjectsQuery(
          $subjectsAfter: String
          $subjectsFirst: Int
          $subjectsGaiaUserIsActive: Boolean
          $organization: ID
          $subjectsGaiaUserFullNameIcontains: String
        ) {
          subjects(
            after: $subjectsAfter
            first: $subjectsFirst
            gaiaUser_IsActive: $subjectsGaiaUserIsActive
            organization: $organization
            gaiaUser_FullName_Icontains: $subjectsGaiaUserFullNameIcontains
          ) {
            edges {
              node {
                id
                studentId
                gaiaUser {
                  id
                  email
                  dummyUsername
                  fullName
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
      }
    )

  const handleSubjectChange = (e, index) => {
    if (formik.values.databaseSubjects[index].id !== '') {
      formik.setFieldValue(`databaseSubjects[${index}].fullName`, '')
      formik.setFieldValue(`databaseSubjects[${index}].id`, '')
    } else {
      formik.setFieldValue(
        `databaseSubjects[${index}].fullName`,
        e.target.value
      )
      searchSubjects({
        variables: {
          subjectsFirst: 15,
          subjectsGaiaUserFullNameIcontains: e.target.value,
          subjectsGaiaUserActive: true,
          organization: formik.values.organization.id,
        },
        fetchPolicy: 'network-only',
      })
      setSubjectResultIndex(index)
      setShowSubjectResults(true)
    }
  }

  const handleDeleteSubject = (subjectId) => {
    if (formik.values.removeSubjects.includes(subjectId)) {
      const removeSubjects = formik.values.removeSubjects.filter(
        (subjId) => subjId !== subjectId
      )
      formik.setFieldValue('removeSubjects', removeSubjects)
    } else {
      const newRemoveSubjects = [...formik.values.removeSubjects, subjectId]
      formik.setFieldValue('removeSubjects', newRemoveSubjects)
    }
  }

  const handleOrgBlur = () => {
    setDisplayOrganizationResults(false)
    formik.setFieldTouched(`organization.name`, true)
    formik.setFieldTouched(`organization.id`, true)
  }

  const CATEGORIES = ['School', 'Other']

  const handleChangeState = (e, i) => {
    formik.setFieldValue(`newSubjects[${i}].state`, e.target.value)
  }
  const handleFetchMoreOrganizations = () => {
    fetchMoreOrganizations({
      variables: {
        after: organizationData.organizations.pageInfo.endCursor,
        first: 5,
        nameIcontains: formik.values.organization.name,
      },
    })
  }

  const handleOrganizationClick = (node, formik) => {
    setDisplayOrganizationResults(false)
    formik.setFieldValue(
      'sharedCanSeeFiles',
      node.subjectGroupSharedCanSeeFiles
    )
    formik.setFieldValue('sharedCanCreate', node.subjectGroupSharedCanCreate)
    formik.setFieldValue(
      'sharedCanCreateFolders',
      node.subjectGroupSharedCanCreateFolders
    )
    formik.setFieldValue(`organization.name`, node.name)
    formik.setFieldValue(`organization.id`, node.id)
    formik.setFieldValue(
      'organization.billingInfo',
      node?.stripeCustomer?.stripeId
    )
  }

  useEffect(() => {
    formik.setFieldValue('metadata', JSON.stringify(metaData))
  }, [metaData])

  const newUploadClick = () => {
    setImporterKey(Math.random())
    setRowsMissingInfo([])
    setCsvDataTransformed([])
    setRowFatalError(false)
  }

  useEffect(() => {
    if (
      !fetchedAllFmClientSessionTemplates &&
      fmApiClientSessionTemplatesData?.fotomerchantApiClientSessionTemplates
        ?.fotomerchantApiClientSessionTemplates
    ) {
      const currentTemplates = JSON.parse(
        fmApiClientSessionTemplatesData.fotomerchantApiClientSessionTemplates
          .fotomerchantApiClientSessionTemplates
      )
      setFmApiClientSessionTemplates((prevState) => {
        return [...prevState, ...currentTemplates.clientSessionTemplates]
      })
      if (
        (currentTemplates.paging.page - 1) * currentTemplates.paging.limit +
          currentTemplates.count <
        currentTemplates.paging.total
      ) {
        getFmApiClientSessionTemplates({
          variables: {
            page: fmClientSessionTemplatesPage + 1,
          },
        })
        setFmClientSessionTemplatesPage((prevState) => (prevState += 1))
      } else {
        setFetchedAllFmClientSessionTemplates(true)
      }
    }
  }, [fmApiClientSessionTemplatesData, fetchedAllFmClientSessionTemplates])

  useEffect(() => {
    if (fotomerchantEnabled) {
      getFmApiClientSessionTemplates({
        variables: {
          page: fmClientSessionTemplatesPage,
        },
      })
    }
  }, [fotomerchantEnabled])

  if (updatingSubjectGroup && !existingSubjectsData) return <></>

  return (
    <>
      <Form
        onSubmit={formik.handleSubmit}
        onKeyDown={(e) => {
          e.key === 'Enter' && e.preventDefault()
        }}
      >
        {!contact && (
          <>
            <Form.Row>
              <Form.Group as={Col} md={3}>
                <Form.Label>Name</Form.Label>
                <Field name="name" className="form-control" />
                <ErrorMessage name="name">
                  {(msg) => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </Form.Group>
              {!organization && (
                <Form.Group as={Col} md={3}>
                  <Form.Label>
                    Organization
                    {formik.values.organization.id && !editSubjectGroupId && (
                      <button
                        type="button"
                        className="p-0 mr-1 ml-1 btn-link"
                        onClick={handleOrganizationXClick}
                      >
                        <Trash />
                      </button>
                    )}
                  </Form.Label>
                  <Form.Control
                    value={formik.values.organization.name}
                    onChange={organizationChange}
                    onBlur={handleOrgBlur}
                    readOnly={Boolean(formik.values.organization.id)}
                    className={
                      formik.values.organization.id && !editSubjectGroupId
                        ? ' border border-success form-control'
                        : 'form-control'
                    }
                  />
                  {organizationData && displayOrganizationResults && (
                    <InfiniteScroll
                      height={100}
                      style={{
                        width: '100%',
                        backgroundColor: 'white',
                        position: 'absolute',
                        zIndex: 80,
                      }}
                      dataLength={organizationData.organizations.edges.length} //This is important field to render the next data
                      next={() => handleFetchMoreOrganizations()}
                      hasMore={
                        organizationData?.organizations.pageInfo.hasNextPage
                      }
                      loader={<Loading />}
                    >
                      <Table size="sm" hover>
                        <tbody>
                          {organizationData.organizations.edges.map((org) => {
                            const { node } = org
                            return (
                              <tr
                                onMouseDown={() =>
                                  handleOrganizationClick(node, formik)
                                }
                                key={node.id}
                                className="hover text-decoration-none"
                              >
                                <td>
                                  <small>{node.name}</small>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  )}
                  {formik.errors?.organization?.id && (
                    <span className="text-danger">
                      {formik.errors?.organization.id}
                    </span>
                  )}
                </Form.Group>
              )}
              <Form.Group as={Col} md={2}>
                <Form.Label>Category</Form.Label>
                <Form.Control
                  name="category"
                  as="select"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.category}
                >
                  {CATEGORIES.map((delta) => (
                    <option key={delta} value={delta}>
                      {delta}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.category}
                </Form.Control.Feedback>
              </Form.Group>

              {fotomerchantEnabled && (
                <Form.Group as={Col} md={3}>
                  <Form.Label>Fotomerchant Client Session Template</Form.Label>
                  <Form.Control
                    name="fotomerchantClientSessionTemplateId"
                    as="select"
                    value={formik.values.fotomerchantClientSessionTemplateId}
                    disabled={
                      formik.values.fotomerchantClientSessionTemplateId
                        ? true
                        : false
                    }
                    onChange={formik.handleChange}
                  >
                    <option value={''}>- - -</option>
                    {fmApiClientSessionTemplates.map(
                      (fmApiClientSessionTemplate) => {
                        return (
                          <option
                            value={fmApiClientSessionTemplate.id}
                            key={fmApiClientSessionTemplate.id}
                          >
                            {fmApiClientSessionTemplate.label}
                          </option>
                        )
                      }
                    )}
                  </Form.Control>
                  <ErrorMessage name="fotomerchantClientSessionTemplateId">
                    {(msg) => <span className="text-danger">{msg}</span>}
                  </ErrorMessage>
                </Form.Group>
              )}
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="auto">
                <Form.Label className="d-block">
                  <span>Booking Start Date</span>
                </Form.Label>

                <DatePicker
                  name="startDateTime"
                  className="form-control"
                  showPopperArrow={false}
                  popperPlacement="auto"
                  selected={formik.values.startDateTime}
                  onChange={(date) =>
                    formik.setFieldValue('startDateTime', date)
                  }
                  popperModifiers={{
                    flip: {
                      behavior: ['bottom'],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} md="auto">
                <Form.Label className="d-block">
                  <span>Booking End Date</span>
                </Form.Label>

                <DatePicker
                  name="endDateTime"
                  className="form-control"
                  showPopperArrow={false}
                  popperPlacement="auto"
                  selected={formik.values.endDateTime}
                  onChange={(date) => formik.setFieldValue('endDateTime', date)}
                  popperModifiers={{
                    flip: {
                      behavior: ['bottom'],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} md="auto">
                <Form.Label className="d-block">
                  <span>Last Date To Pick Pose</span>
                </Form.Label>

                <DatePicker
                  name="lastDateToPickPose"
                  className="form-control"
                  showPopperArrow={false}
                  popperPlacement="auto"
                  selected={formik.values.lastDateToPickPose}
                  onChange={(date) =>
                    formik.setFieldValue('lastDateToPickPose', date)
                  }
                  popperModifiers={{
                    flip: {
                      behavior: ['bottom'],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} md="auto">
                <Form.Label className="d-block">
                  <span>Final Photo Deadline</span>
                </Form.Label>

                <DatePicker
                  name="lastDateToBePhotographed"
                  className="form-control"
                  showPopperArrow={false}
                  popperPlacement="auto"
                  selected={formik.values.lastDateToBePhotographed}
                  onChange={(date) =>
                    formik.setFieldValue('lastDateToBePhotographed', date)
                  }
                  popperModifiers={{
                    flip: {
                      behavior: ['bottom'],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} md="auto">
                <Form.Label className="d-block">
                  <span>Primary Photo Deadline</span>
                </Form.Label>

                <DatePicker
                  name="lastDateForRetouch"
                  className="form-control"
                  showPopperArrow={false}
                  popperPlacement="auto"
                  selected={formik.values.lastDateForRetouch}
                  onChange={(date) =>
                    formik.setFieldValue('lastDateForRetouch', date)
                  }
                  popperModifiers={{
                    flip: {
                      behavior: ['bottom'],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </Form.Group>
            </Form.Row>
            {formik.errors.startDateTime && (
              <span style={{ color: 'red' }}>
                {formik.errors.startDateTime}
              </span>
            )}
            <Row className="mt-3">
              <Col md={3}>
                <Form.Group className="mb-0">
                  <Form.Label>New Subjects Receive Welcome Email</Form.Label>
                  <Form.Check
                    className="ml-1"
                    label={
                      !formik.values.skipWelcomeEmail
                        ? 'Receive Email'
                        : "Don't Receive Email"
                    }
                    checked={!formik.values.skipWelcomeEmail}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'skipWelcomeEmail',
                        !e.target.checked
                      )
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-0">
                  <Form.Label>Billing</Form.Label>
                  <Form.Check
                    className="ml-1"
                    type="checkbox"
                    checked={formik.values.subjectsPaying}
                    label={
                      formik.values.subjectsPaying
                        ? 'Bill Subjects'
                        : 'Bill Organization'
                    }
                    onChange={(e) => {
                      formik.setFieldValue(`subjectsPaying`, e.target.checked)
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-0">
                  <Form.Label>Resit Sessions</Form.Label>
                  <Form.Check
                    type="checkbox"
                    className="ml-1"
                    checked={formik.values.resitsAvailable}
                    label={
                      formik.values.resitsAvailable
                        ? 'Available'
                        : 'Not Available'
                    }
                    onChange={(e) => {
                      formik.setFieldValue(`resitsAvailable`, e.target.checked)
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-0">
                  <Form.Label>Coupons</Form.Label>
                  <Form.Check
                    type="checkbox"
                    className="ml-2"
                    checked={formik.values.allowPromoCodes}
                    label={
                      formik.values.allowPromoCodes
                        ? 'Available'
                        : 'Not Available'
                    }
                    onChange={(e) => {
                      formik.setFieldValue(`allowPromoCodes`, e.target.checked)
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Form.Label>Files</Form.Label>
              </Col>
            </Row>
            <Row style={{ marginLeft: '-10px' }}>
              <Col md={3}>
                <Form.Group>
                  <Form.Check
                    name="sharedCanSeeFiles"
                    type="checkbox"
                    label="Contact Can See Files"
                    checked={formik.values.sharedCanSeeFiles}
                    onChange={(e) => {
                      formik?.setFieldValue(
                        `sharedCanSeeFiles`,
                        e.target.checked
                      )
                    }}
                  />
                </Form.Group>
              </Col>
              {formik.values.sharedCanSeeFiles && (
                <>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Check
                        name="sharedCanCreateFiles"
                        type="checkbox"
                        label="Contact Can Create Files"
                        checked={formik.values.sharedCanCreateFiles}
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `sharedCanCreateFiles`,
                            e.target.checked
                          )
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Check
                        name="sharedCanCreateFolders"
                        type="checkbox"
                        label="Contact Can Create Folders"
                        checked={formik.values.sharedCanCreateFolders}
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `sharedCanCreateFolders`,
                            e.target.checked
                          )
                        }}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
            <Form.Row className="mt-3">
              <Form.Group as={Col} md={6}>
                <div style={{ marginLeft: '-4px' }}>
                  <MetaDataTable
                    metaData={metaData}
                    setMetaData={setMetaData}
                    edit={true}
                  />
                </div>
              </Form.Group>
            </Form.Row>
            <div className="mt-2">
              <Policies formik={formik} nodeType="subjectGroup" />
            </div>
            <Form.Row className="mt-3">
              <Form.Group as={Col} md={12}>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  value={formik.values.notes}
                  onChange={(e) => {
                    formik.setFieldValue(`notes`, e.target.value)
                  }}
                  as="textarea"
                  rows={6}
                  className="form-text"
                />
                <ErrorMessage name="notes">
                  {(msg) => <span className="text-danger">{msg}</span>}
                </ErrorMessage>
              </Form.Group>
            </Form.Row>
            <Row className="mt-5">
              <Col md={5}>
                <h4 className="mt-3">Session Packages</h4>
                <FieldArray
                  name="sessionPackages"
                  render={(arrayHelpers) => (
                    <div>
                      {formik.values.sessionPackages.map((spb, i) => (
                        <Form.Row key={i} className="mb-3 p-2">
                          <Form.Group as={Col} xs={12} className="mt-3">
                            <Form.Control
                              value={formik.values.sessionPackages[i].title}
                              onChange={(e) => handleSessionPackageChange(e, i)}
                              onBlur={() => setShowPackageResults(false)}
                            />

                            <div
                              className={
                                showPackageResults && packageResultIndex === i
                                  ? ''
                                  : 'd-none'
                              }
                            >
                              <InfiniteScroll
                                height={100}
                                dataLength={
                                  packageData
                                    ? packageData.sessionPackages.edges.length
                                    : 0
                                } //This is important field to render the next data
                                next={() => handleFetchMorePackages(i)}
                                hasMore={
                                  packageData
                                    ? packageData.pageInfo?.hasNextPage
                                    : false
                                }
                                loader={<Loading />}
                              >
                                <Table size="sm" hover>
                                  <tbody>
                                    {packageData?.sessionPackages.edges.map(
                                      (sessionPackage) => {
                                        const { node } = sessionPackage
                                        const existingSubGrp =
                                          formik.values.sessionPackages.find(
                                            (sub) => sub.id === node.id
                                          )
                                        if (existingSubGrp) {
                                          return
                                        }
                                        return (
                                          <tr
                                            onMouseDown={() =>
                                              handleSubjectPackageClick(
                                                i,
                                                node,
                                                arrayHelpers
                                              )
                                            }
                                            key={node.id}
                                            className="hover text-decoration-none"
                                          >
                                            <td>
                                              <small
                                                className="text-truncate d-block"
                                                style={{
                                                  width: '370px',
                                                }}
                                              >{`${node.title}, $${node.price}, ${node.durationLowMinutes}-${node.durationHighMinutes} minutes`}</small>
                                            </td>
                                          </tr>
                                        )
                                      }
                                    )}
                                  </tbody>
                                </Table>
                              </InfiniteScroll>
                            </div>
                          </Form.Group>

                          <div
                            className="d-flex align-items-center btn-link hover  mb-2"
                            onClick={() => arrayHelpers.remove(i)}
                          >
                            <Trash className="ml-2 mr-2" />
                            <small>Remove</small>
                          </div>
                        </Form.Row>
                      ))}

                      <Button
                        size="sm"
                        variant="link"
                        onClick={() =>
                          arrayHelpers.push({
                            id: '',
                            title: '',
                          })
                        }
                      >
                        <Images className="mr-2" />
                        Add Session Package
                      </Button>
                    </div>
                  )}
                />
              </Col>
            </Row>
            <h4 className="mt-3">Subjects</h4>
          </>
        )}

        <Row className="mt-2">
          <Col md={12}>
            <FieldArray
              name="newSubjects"
              render={(arrayHelpers) => (
                <div
                  style={
                    formik.values.newSubjects.length > 1
                      ? {
                          overflowY: 'scroll',
                          maxHeight: '400px',
                          overflowX: 'hidden',
                        }
                      : null
                  }
                  className={
                    formik.values.newSubjects.length > 1
                      ? 'border p-3 mt-2'
                      : ''
                  }
                >
                  {formik.values.newSubjects.map((spb, i) => (
                    <>
                      <div
                        key={i}
                        className="border border-secondary rounded mb-3 p-2"
                      >
                        <Form.Row>
                          <Form.Group as={Col} md={4}>
                            <Form.Label>First Name</Form.Label>
                            <Field
                              className="form-control"
                              name={`newSubjects[${i}].firstName`}
                            />
                            <ErrorMessage name={`newSubjects[${i}].firstName`}>
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                          <Form.Group as={Col} md={4}>
                            <Form.Label>Last Name</Form.Label>
                            <Field
                              name={`newSubjects[${i}].lastName`}
                              className="form-control"
                            />
                            <ErrorMessage name={`newSubjects[${i}].lastName`}>
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                          <Form.Group as={Col} md={4}>
                            <Form.Label>
                              {formik.values.category === 'SCHOOL' ? (
                                <>Student ID</>
                              ) : (
                                <>ID Number</>
                              )}
                            </Form.Label>
                            <Field
                              name={`newSubjects[${i}].studentId`}
                              className="form-control"
                            />
                            <ErrorMessage name={`newSubjects[${i}].studentId`}>
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} md={6}>
                            <Form.Label>Email</Form.Label>
                            <Field
                              name={`newSubjects[${i}].email`}
                              className="form-control"
                            />
                            <ErrorMessage name={`newSubjects[${i}].email`}>
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                          <Form.Group as={Col} md={6}>
                            <Form.Label>Secondary Email</Form.Label>
                            <Field
                              name={`newSubjects[${i}].secondaryEmail`}
                              className="form-control"
                            />
                            <ErrorMessage
                              name={`newSubjects[${i}].secondaryEmail`}
                            >
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} md={6}>
                            <Form.Label>Phone Number</Form.Label>
                            <PhoneInput
                              inputClass="w-100"
                              country="us"
                              placeholder=""
                              name={`newSubjects[${i}].phoneNumber`}
                              onChange={(pValue) =>
                                formik.setFieldValue(
                                  `newSubjects[${i}].phoneNumber`,
                                  pValue
                                )
                              }
                              regions={['north-america']}
                            />
                            <ErrorMessage
                              name={`newSubjects[${i}].phoneNumber`}
                            >
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                          <Form.Group as={Col} md={6}>
                            <Form.Label>Secondary Phone Number</Form.Label>
                            <PhoneInput
                              inputClass="w-100"
                              country="us"
                              placeholder=""
                              name={`newSubjects[${i}].secondaryPhoneNumber`}
                              onChange={(pValue) =>
                                formik.setFieldValue(
                                  `newSubjects[${i}].secondaryPhoneNumber`,
                                  pValue
                                )
                              }
                              regions={['north-america']}
                            />
                            <ErrorMessage
                              name={`newSubjects[${i}].secondaryPhoneNumber`}
                            >
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} md={6}>
                            <Form.Label>Password</Form.Label>
                            <Field
                              name={`newSubjects[${i}].password`}
                              className="form-control"
                              type={'password'}
                            />
                            <ErrorMessage name={`newSubjects[${i}].password`}>
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                          <Form.Group as={Col} md={6}>
                            <Form.Label>Confirm Password</Form.Label>
                            <Field
                              name={`newSubjects[${i}].confirmPassword`}
                              className="form-control"
                              type={'password'}
                            />
                            <ErrorMessage
                              name={`newSubjects[${i}].confirmPassword`}
                            >
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                          <Form.Group as={Col}>
                            <Form.Check
                              inline
                              label="Receive SMS"
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `newSubjects[${i}].smsNotificationsEnabled`,
                                  e.target.checked
                                )
                              }
                              checked={
                                formik.values.newSubjects[i]
                                  .smsNotificationsEnabled
                              }
                            />

                            <Form.Check
                              inline
                              label="Receive Email"
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `newSubjects[${i}].emailNotificationsEnabled`,
                                  e.target.checked
                                )
                              }
                              checked={
                                formik.values.newSubjects[i]
                                  .emailNotificationsEnabled
                              }
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} md={6}>
                            <Form.Label>Address Line One</Form.Label>
                            <Field
                              name={`newSubjects[${i}].addressLineOne`}
                              className="form-control"
                            />
                            <ErrorMessage name="addressLineOne">
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>

                          <Form.Group as={Col} md={6}>
                            <Form.Label>Address Line Two</Form.Label>
                            <Field
                              name={`newSubjects[${i}].addressLineTwo`}
                              className="form-control"
                            />
                            <ErrorMessage name="addressLineTwo">
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} md={4}>
                            <Form.Label>City</Form.Label>
                            <Field
                              name={`newSubjects[${i}].city`}
                              className="form-control"
                            />
                            <ErrorMessage name={`newSubjects[${i}].city`}>
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                          <Form.Group as={Col} md={4}>
                            <Form.Label>State</Form.Label>
                            <select
                              className="form-select"
                              name={`newSubjects[${i}].state`}
                              onChange={(e) => handleChangeState(e, i)}
                              value={formik.values.newSubjects[i].state}
                            >
                              <option></option>
                              {US_STATES.map((state) => (
                                <option key={state} value={state}>
                                  {state}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage name={`newSubjects[${i}].state`}>
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                          <Form.Group as={Col} md={4}>
                            <Form.Label>Zip Code</Form.Label>
                            <Field
                              name={`newSubjects[${i}].zipCode`}
                              className="form-control"
                            />
                            <ErrorMessage name={`newSubjects[${i}].zipCode`}>
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} md={12}>
                            <Form.Label>Notes</Form.Label>
                            <Field
                              name={`newSubjects[${i}].notes`}
                              as="textarea"
                              className="form-control"
                            />
                            <ErrorMessage name={`newSubjects[${i}].notes`}>
                              {(msg) => (
                                <span className="text-danger">{msg}</span>
                              )}
                            </ErrorMessage>
                          </Form.Group>
                        </Form.Row>
                        <Form.Group as={Col} md={12}>
                          <FieldArray
                            name={`newSubjects[${i}].parents`}
                            render={(arrayHelpers) => (
                              <div>
                                {formik.values.newSubjects[i].parents.map(
                                  (parent, index) => (
                                    <div
                                      key={index}
                                      className="border border-secondary rounded mt-3 p-3"
                                    >
                                      <Form.Row>
                                        <Form.Group as={Col} md={6}>
                                          <Form.Label>First Name</Form.Label>
                                          <Field
                                            name={`newSubjects[${i}].parents[${index}].firstName`}
                                            className="form-control"
                                          />
                                          <ErrorMessage
                                            name={`newSubjects[${i}].parents[${index}].firstName`}
                                          >
                                            {(msg) => (
                                              <span className="text-danger">
                                                {msg}
                                              </span>
                                            )}
                                          </ErrorMessage>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6}>
                                          <Form.Label>Last Name</Form.Label>
                                          <Field
                                            name={`newSubjects[${i}].parents[${index}].lastName`}
                                            className="form-control"
                                          />
                                          <ErrorMessage
                                            name={`newSubjects[${i}].parents[${index}].lastName`}
                                          >
                                            {(msg) => (
                                              <span className="text-danger">
                                                {msg}
                                              </span>
                                            )}
                                          </ErrorMessage>
                                        </Form.Group>
                                      </Form.Row>

                                      <Form.Row>
                                        <Form.Group as={Col} md={6}>
                                          <Form.Label>Email</Form.Label>
                                          <Field
                                            name={`newSubjects[${i}].parents[${index}].email`}
                                            className="form-control"
                                          />
                                          <ErrorMessage
                                            name={`newSubjects[${i}].parents[${index}].email`}
                                          >
                                            {(msg) => (
                                              <span className="text-danger">
                                                {msg}
                                              </span>
                                            )}
                                          </ErrorMessage>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6}>
                                          <Form.Label>
                                            Secondary Email
                                          </Form.Label>
                                          <Field
                                            name={`newSubjects[${i}].parents[${index}].secondaryEmail`}
                                            className="form-control"
                                          />
                                          <ErrorMessage
                                            name={`newSubjects[${i}].parents[${index}].secondaryEmail`}
                                          >
                                            {(msg) => (
                                              <span className="text-danger">
                                                {msg}
                                              </span>
                                            )}
                                          </ErrorMessage>
                                        </Form.Group>
                                      </Form.Row>

                                      <Form.Row>
                                        <Form.Group as={Col} md={6}>
                                          <Form.Label>Phone Number</Form.Label>
                                          <PhoneInput
                                            inputClass="w-100"
                                            country="us"
                                            placeholder=""
                                            name={`newSubjects[${i}].parents[${index}].phoneNumber`}
                                            regions={['north-america']}
                                            onChange={(pValue) =>
                                              formik.setFieldValue(
                                                `newSubjects[${i}].parents[${index}].phoneNumber`,
                                                pValue
                                              )
                                            }
                                          />
                                          <ErrorMessage
                                            name={`newSubjects[${i}].parents[${index}].phoneNumber`}
                                          >
                                            {(msg) => (
                                              <span className="text-danger">
                                                {msg}
                                              </span>
                                            )}
                                          </ErrorMessage>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6}>
                                          <Form.Label>
                                            Secondary Phone Number
                                          </Form.Label>
                                          <PhoneInput
                                            inputClass="w-100"
                                            country="us"
                                            placeholder=""
                                            name={`newSubjects[${i}].parents[${index}].secondaryPhoneNumber`}
                                            regions={['north-america']}
                                            onChange={(pValue) =>
                                              formik.setFieldValue(
                                                `newSubjects[${i}].parents[${index}].secondaryPhoneNumber`,
                                                pValue
                                              )
                                            }
                                          />
                                          <ErrorMessage
                                            name={`newSubjects[${i}].parents[${index}].secondaryPhoneNumber`}
                                          >
                                            {(msg) => (
                                              <span className="text-danger">
                                                {msg}
                                              </span>
                                            )}
                                          </ErrorMessage>
                                        </Form.Group>
                                      </Form.Row>

                                      <Form.Row>
                                        <Form.Group as={Col}>
                                          <Form.Check
                                            inline
                                            label="Receive SMS"
                                            checked={
                                              formik.values.newSubjects[i]
                                                .parents[index]
                                                .smsNotificationsEnabled
                                            }
                                            onChange={(e) =>
                                              formik.setFieldValue(
                                                `newSubjects[${i}].parents[${index}].smsNotificationsEnabled`,
                                                e.target.checked
                                              )
                                            }
                                          />

                                          <Form.Check
                                            inline
                                            label="Receive Email"
                                            checked={
                                              formik.values.newSubjects[i]
                                                .parents[index]
                                                .emailNotificationsEnabled
                                            }
                                            onChange={(e) =>
                                              formik.setFieldValue(
                                                `newSubjects[${i}].parents[${index}].emailNotificationsEnabled`,
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      </Form.Row>

                                      <div
                                        className="d-flex align-items-center hover text-danger"
                                        onClick={() => {
                                          if (
                                            formik.values.newSubjects[i]
                                              .parents[index].id
                                          ) {
                                            const removeParents =
                                              formik.values.newSubjects[i]
                                                .removeParents
                                            removeParents.push(
                                              formik.values.newSubjects[i]
                                                .parents[index]
                                            )
                                            formik.setFieldValue(
                                              'removeParents',
                                              removeParents
                                            )
                                          }
                                          arrayHelpers.remove(index)
                                        }}
                                      >
                                        <Trash className="mr-2" />
                                        <small>Remove</small>
                                      </div>
                                    </div>
                                  )
                                )}
                                <button
                                  type="button"
                                  style={{ marginLeft: '-10px' }}
                                  className={
                                    formik.values &&
                                    formik.values.newSubjects[i].parents
                                      .length > 0
                                      ? 'mt-3 p-0 btn-link'
                                      : 'p-0 btn-link'
                                  }
                                  onClick={() =>
                                    arrayHelpers.push({
                                      firstName: '',
                                      lastName: '',
                                      email: '',
                                      secondaryEmail: '',
                                      phoneNumber: '',
                                      secondaryPhoneNumber: '',
                                      smsNotificationsEnabled: true,
                                      emailNotificationsEnabled: true,
                                    })
                                  }
                                >
                                  + Add Contact
                                </button>
                              </div>
                            )}
                          />
                          {/* </>
                          } */}
                        </Form.Group>
                        <div
                          className="d-flex align-items-center btn-link hover"
                          onClick={() => arrayHelpers.remove(i)}
                        >
                          <Trash className="mr-2" />
                          <small>Remove Subject</small>
                        </div>
                      </div>
                    </>
                  ))}

                  <Button
                    size="sm"
                    variant="link"
                    onClick={() =>
                      arrayHelpers.push({
                        id: '',
                        firstName: '',
                        lastName: '',
                        email: '',
                        secondaryEmail: '',
                        phoneNumber: '',
                        secondaryPhoneNumber: '',
                        password: '',
                        confirmPassword: '',
                        notes: '',
                        smsNotificationsEnabled: true,
                        emailNotificationsEnabled: true,
                        skipWelcomeEmail: true,
                        addressLineOne: null,
                        addressLineTwo: null,
                        city: null,
                        state: null,
                        zipCode: null,
                        organizationName: '',
                        organizationId: null,
                        studentId: null,
                        parents: [],
                        removeParents: [],
                        colleagues: [],
                        removeColleagues: [],
                        schoolOrganization: false,
                        stripePaymentMethodId: '',
                      })
                    }
                  >
                    <Person className="mr-2" />
                    New Subject
                  </Button>
                </div>
              )}
            />
          </Col>
        </Row>

        <Row className="mt-2">
          {formik.values.organization.id ? (
            <Col md={5}>
              <FieldArray
                name="databaseSubjects"
                render={(arrayHelpers) => (
                  <div>
                    {formik.values.databaseSubjects.map((spb, i) => (
                      <Form.Row
                        key={i}
                        className="border border-secondary rounded mb-3 p-2"
                      >
                        <Form.Group as={Col} xs={12}>
                          <Form.Label>Subject</Form.Label>
                          <Form.Control
                            value={formik.values.databaseSubjects[i].fullName}
                            onChange={(e) => handleSubjectChange(e, i)}
                            onBlur={() => setShowSubjectResults(false)}
                          />

                          <div
                            className={
                              showSubjectResults && subjectResultIndex === i
                                ? ''
                                : 'd-none'
                            }
                          >
                            <InfiniteScroll
                              height={100}
                              dataLength={
                                subjectData
                                  ? subjectData.subjects.edges.length
                                  : 0
                              } //This is important field to render the next data
                              next={() => handleFetchMoreSubjects(i)}
                              hasMore={
                                subjectData
                                  ? subjectData.pageInfo?.hasNextPage
                                  : false
                              }
                              loader={<Loading />}
                            >
                              <Table size="sm" hover>
                                <tbody>
                                  {subjectData?.subjects.edges.map(
                                    (subject) => {
                                      const { node } = subject
                                      const studentId = node.studentId
                                        ? `, ${node.studentId}`
                                        : ''
                                      const subjectEmail =
                                        node.gaiaUser.email &&
                                        !node.gaiaUser.dummyUsername
                                          ? `, ${node.gaiaUser.email}`
                                          : ''
                                      return (
                                        <tr
                                          onMouseDown={() => {
                                            formik.setFieldValue(
                                              `databaseSubjects[${i}]`,
                                              {
                                                id: node.id,
                                                fullName:
                                                  node.gaiaUser.fullName,
                                              }
                                            )
                                          }}
                                          key={node.id}
                                          className="hover text-decoration-none"
                                        >
                                          <td>
                                            <small
                                              className="text-truncate d-block"
                                              style={{
                                                width: '300px',
                                              }}
                                            >
                                              {node.gaiaUser.fullName}
                                              {subjectEmail}
                                              {studentId}
                                            </small>
                                          </td>
                                        </tr>
                                      )
                                    }
                                  )}
                                </tbody>
                              </Table>
                            </InfiniteScroll>
                          </div>
                        </Form.Group>

                        <div
                          className="d-flex align-items-center text-danger hover"
                          onClick={() => arrayHelpers.remove(i)}
                        >
                          <Trash className="mr-2" />
                          <small>remove subject</small>
                        </div>
                      </Form.Row>
                    ))}

                    <Button
                      size="sm"
                      variant="link"
                      onClick={() =>
                        arrayHelpers.push({
                          id: '',
                          fullName: '',
                        })
                      }
                    >
                      <Person className="mr-2" />
                      Add Existing {formik.values.organization.name} Subject
                    </Button>
                  </div>
                )}
              />
            </Col>
          ) : null}
        </Row>
        {iqEnabled && (
          <Row>
            <Col md={4}>
              <Form.Label>
                Extract Blueprint CSV Emails
                {blueprintEmailsFile && (
                  <>
                    <Button
                      className="btn-link"
                      onClick={() => {
                        setDownloadingBlueprintEmails(true)
                        extractBlueprintEmails({
                          variables: {
                            input: {
                              file: blueprintEmailsFile,
                            },
                          },
                        })
                      }}
                    >
                      {!downloadingBlueprintEmails && (
                        <CloudArrowDown className="ml-2" />
                      )}
                      {downloadingBlueprintEmails && <Loading height={14} />}
                    </Button>
                    <Button
                      disabled={downloadingBlueprintEmails}
                      className="btn-link"
                      onClick={() => {
                        setBlueprintEmailsFile(null)
                        document.getElementById('blueprintEmailsFile').value =
                          ''
                      }}
                    >
                      <XCircle className="ml-2" size={14} />
                    </Button>
                  </>
                )}
              </Form.Label>
              <Form.Group>
                <div className="custom-file">
                  <Form.Control
                    type="file"
                    className="custom-file-input"
                    id="blueprintEmailsFile"
                    onChange={(event) => {
                      const file = event.target.files[0]
                      if (file) {
                        if (
                          file.type === 'text/csv' ||
                          file.name.endsWith('.csv')
                        ) {
                          setBlueprintEmailsFile(file)
                        } else {
                          toast.error('CSV File Required')
                          event.target.value = ''
                        }
                      }
                    }}
                  />
                  <Form.Label
                    className="custom-file-label"
                    htmlFor="blueprintEmailsFile"
                  >
                    {blueprintEmailsFile ? blueprintEmailsFile.name : ''}
                  </Form.Label>
                </div>
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row className="mt-3">
          <Col md={4}>
            <Form.Label>CSV Import Existing Record Sync Field</Form.Label>
            <Form.Group>
              <select
                className="form-control-sm form-select"
                name="csvRecordSync"
                value={formik.values.csvRecordSync}
                onChange={(e) => {
                  setCsvRecordSync(e.target.value || undefined)
                }}
              >
                <option value="">- - -</option>
                <option value="subject full name">Subject Full Name</option>
                <option value="subject id">Subject ID</option>
                <option value="subject email">Subject Email</option>
                <option value="custom one">Custom One</option>
                <option value="custom two">Custom Two</option>
                <option value="custom three">Custom Three</option>
                <option value="custom four">Custom Four</option>
                <option value="custom five">Custom Five</option>
              </select>
            </Form.Group>
          </Col>
        </Row>
        {(csvDataTransformed.length > 0 || rowsMissingInfo.length > 0) && (
          <>
            <Row>
              <Col md={3}>
                <Button onClick={newUploadClick} size="sm" variant="link">
                  <ArrowCounterclockwise className="mr-2" />
                  New Upload
                </Button>
              </Col>
            </Row>
          </>
        )}

        <Row className="mt-2">
          <Col>
            <div className="mt-2">
              <SubjectCsvImport
                fotomerchantPassword={
                  formik.values.fotomerchantClientSessionTemplateId
                }
                importerKey={importerKey}
                rowsMissingInfo={rowsMissingInfo}
                rowFatalError={rowFatalError}
                setRowsMissingInfo={setRowsMissingInfo}
                setRowFatalError={setRowFatalError}
                updateCsvData={updateCsvData}
              />
            </div>
          </Col>
        </Row>

        {rowsMissingInfo.length > 0 && (
          <>
            <Row className="mt-3">
              <Col>
                <h4>
                  <ExclamationTriangle className="mr-2" />
                  Import Warnings and Errors
                </h4>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <div
                  style={{
                    overflowY: 'scroll',
                    maxHeight: '300px',
                    overflowX: 'hidden',
                  }}
                >
                  <Table
                    responsive
                    size="sm"
                    bordered
                    style={{ maxHeight: '400px' }}
                  >
                    <thead>
                      <tr>
                        <th>
                          <small className="font-weight-bold">Type</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Row</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Column</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Error</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowsMissingInfo.map((csvError, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <small className="d-block">
                                {csvError.fatal ? <>Fatal</> : <>Warning</>}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {csvError.row}
                              </small>
                            </td>
                            <td>
                              <small
                                className="d-block"
                                style={{
                                  width: '150px',
                                }}
                              >
                                {csvError.column}
                              </small>
                            </td>
                            <td>
                              <small className="d-block">
                                {csvError.error}
                              </small>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </>
        )}

        <Row
          className={
            csvDataTransformed.length > 0 && rowFatalError === false
              ? 'text-secondary'
              : 'd-none'
          }
        >
          <Col>
            <h4
              className={
                csvDataTransformed.length > 0
                  ? 'text-secondary mt-3 mb-0'
                  : 'd-none'
              }
            >
              CSV Data
            </h4>
            <p
              className={
                csvDataTransformed.length > 0 ? 'text-secondary' : 'd-none'
              }
            >
              {csvDataTransformed.length} rows
            </p>
            <div
              style={{
                overflowY: 'scroll',
                maxHeight: '300px',
                overflowX: 'hidden',
              }}
            >
              <Table
                responsive
                size="sm"
                bordered
                style={{ maxHeight: '400px' }}
                className={
                  csvDataTransformed.length > 0 ? 'mt-2 d-block' : 'd-none'
                }
              >
                <thead>
                  <tr>
                    <th>
                      <small className="font-weight-bold">
                        Subject First Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Last Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Job Title
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">ID Number</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">Subject Email</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Secondary Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Password
                      </small>
                    </th>
                    {formik.values.fotomerchantClientSessionTemplateId && (
                      <th>
                        <small className="font-weight-bold">
                          Fotomerchant Password
                        </small>
                      </th>
                    )}
                    <th>
                      <small className="font-weight-bold">Subject Phone</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Secondary Phone
                      </small>
                    </th>
                    {iqEnabled && (
                      <th>
                        <small className="font-weight-bold">
                          <span className="ml-2">Blueprint Online Code</span>
                        </small>
                      </th>
                    )}
                    <th>
                      <small className="font-weight-bold">
                        Subject Metadata
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Group Metadata
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Address Line One
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Address Line Two
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">City</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">State</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">Zip Code</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One First Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Last Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Previous Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Secondary Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Password
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact One Secondary Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two First Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Last Name
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Previous Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Secondary Email
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Password
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Contact Two Secondary Phone
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom One
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom Two
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom Three
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom Four
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Custom Five
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Group Custom One
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Group Custom Two
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Group Custom Three
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Group Custom Four
                      </small>
                    </th>
                    <th>
                      <small className="font-weight-bold">
                        Subject Group Custom Five
                      </small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {csvDataTransformed.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectFirstName}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectLastName}
                          </small>
                        </td>
                        <td>
                          <small className="d-block" style={{ width: '150px' }}>
                            {row.subjectJobTitle}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.studentId}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.subjectEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.subjectSecondaryEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.subjectPassword}
                          </small>
                        </td>
                        {formik.values.fotomerchantClientSessionTemplateId && (
                          <td>
                            <small
                              className="d-block"
                              style={{
                                width: '200px',
                              }}
                            >
                              {row.fotomerchantPassword}
                            </small>
                          </td>
                        )}
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectPhone}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectSecondaryPhone}
                          </small>
                        </td>
                        {iqEnabled && (
                          <td>
                            <small
                              className="d-block"
                              style={{
                                width: '150px',
                              }}
                            >
                              {row.imagequixId}
                            </small>
                          </td>
                        )}
                        <td>
                          <small className="d-block">
                            {row.metadata !== '{}' && <>{row.metadata}</>}
                          </small>
                        </td>
                        <td>
                          <small className="d-block">
                            {row.subjectGroupMetadata !== '{}' && (
                              <>{row.subjectGroupMetadata}</>
                            )}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.addressLineOne}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.addressLineTwo}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.city}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.state}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.zipCode}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.parentOneFirstName}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.parentOneLastName}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.parentOnePreviousEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.parentOneEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.parentOneSecondaryEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.parentOnePassword}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.parentOnePhone}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.parentOneSecondaryPhone}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.parentTwoFirstName}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.parentTwoLastName}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.parentTwoPreviousEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.parentTwoEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.parentTwoSecondaryEmail}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '200px',
                            }}
                          >
                            {row.parentTwoPassword}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.parentTwoPhone}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.parentTwoSecondaryPhone}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectCustomOne}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectCustomTwo}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectCustomThree}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectCustomFour}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectCustomFive}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectGroupCustomOne}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectGroupCustomTwo}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectGroupCustomThree}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectGroupCustomFour}
                          </small>
                        </td>
                        <td>
                          <small
                            className="d-block"
                            style={{
                              width: '150px',
                            }}
                          >
                            {row.subjectGroupCustomFive}
                          </small>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        {existingSubjectsData && (
          <Row className={!updatingSubjectGroup ? 'd-none' : 'mt-4 mb-2'}>
            <Col md={12}>
              <h4>Current Subjects</h4>
              {existingSubjectsData.subjects.edges.length === 0 && (
                <span className="text-secondary">- - -</span>
              )}
              <div
                className={
                  existingSubjectsData &&
                  existingSubjectsData.subjects.edges.length > 0
                    ? 'd-block'
                    : 'd-none'
                }
              >
                <InfiniteScroll
                  height={300}
                  dataLength={
                    existingSubjectsData
                      ? existingSubjectsData.subjects.edges.length
                      : 0
                  } //This is important field to render the next data
                  next={handleFetchMoreExistingSubjects}
                  hasMore={
                    existingSubjectsData
                      ? existingSubjectsData.subjects.pageInfo?.hasNextPage
                      : false
                  }
                  loader={<Loading />}
                >
                  <Table responsive size="sm" bordered className="mt-2">
                    <thead>
                      <tr>
                        <th>
                          <small className="font-weight-bold">Name</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Email</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Phone</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Student ID</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Profile</small>
                        </th>
                        <th>
                          <small className="font-weight-bold">Remove</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {existingSubjectsData &&
                      existingSubjectsData.subjects.edges.length > 0 ? (
                        existingSubjectsData.subjects.edges.map((subj) => (
                          <tr
                            key={subj.node.id}
                            className={
                              formik.values.removeSubjects.includes(
                                subj.node.id
                              )
                                ? 'bg-danger text-white'
                                : 'bg-white text-dark'
                            }
                          >
                            <td>
                              <small>{subj.node.gaiaUser.fullName}</small>
                            </td>
                            <td>
                              <small>
                                {!subj.node.gaiaUser.dummyUsername &&
                                  subj.node.gaiaUser.email}
                              </small>
                            </td>
                            <td>
                              <small>{subj.node.gaiaUser.phoneNumber}</small>
                            </td>
                            <td>
                              <small>
                                {subj.node.studentId ? (
                                  <span>{subj.node.studentId}</span>
                                ) : (
                                  <span></span>
                                )}
                              </small>
                            </td>
                            <td>
                              <Button
                                variant="link"
                                onClick={() => {
                                  if (contact) {
                                    history.push(
                                      `/subject/${subj.node.id}/contact`
                                    )
                                  } else {
                                    history.push(`/subject/${subj.node.id}`)
                                  }
                                }}
                              >
                                <Person size={20} />
                              </Button>
                            </td>
                            <td>
                              <Button
                                variant="link"
                                onClick={() =>
                                  handleDeleteSubject(subj.node.id)
                                }
                              >
                                <Trash size={20} />
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </Table>
                </InfiniteScroll>
              </div>
            </Col>
          </Row>
        )}

        {rowFatalError && (
          <div className="text-danger mt-3">
            <ExclamationCircle className="mr-2" /> Resolve the fatal subject CSV
            import issues before saving
          </div>
        )}
        <Row>
          <Col md={2}>
            <Button
              type="submit"
              variant="outline-primary"
              className="my-4"
              block
              disabled={submitting || rowFatalError === true}
            >
              <PlusCircle className="mr-2" />
              Save
            </Button>
          </Col>
        </Row>

        {submitting ? (
          <Row className="mt-2">
            <Col>
              <Loading />
            </Col>
          </Row>
        ) : null}
      </Form>
    </>
  )
}
