import React, { useState } from 'react'
import { Formik } from 'formik'
import { Form, Modal, Col, Table, Button } from 'react-bootstrap'
import * as Yup from 'yup'
import {
  ArrowCounterclockwise,
  Buildings,
  ExclamationTriangle,
  PlusCircle,
} from 'react-bootstrap-icons'
import OrganizationCsvImporter from './OrganizationCsvImporter'
import Loading from '../common/Loading'
import { gql, useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import { useInterval } from '../../libs/utils'

export default function OrganizationCsvModal(props) {
  const { showModal, toggleModal, setPollInterval } = props

  const [submitting, setIsSubmitting] = useState(false)
  const [importerKey, setImporterKey] = useState('5678')
  const [rowsMissingInfo, setRowsMissingInfo] = useState([])
  const [rowFatalError, setRowFatalError] = useState(false)

  useInterval(() => {
    document
      .querySelectorAll('.CSVImporter_IconButton')
      .forEach((importerButton) => {
        if (!importerButton.hasAttribute('type')) {
          importerButton.setAttribute('type', 'button')
        }
      })
    document
      .querySelectorAll('.CSVImporter_TextButton')
      .forEach((importerTextButton) => {
        if (importerTextButton.innerHTML == 'Import') {
          importerTextButton.innerHTML = 'Validate Import'
        }
      })
    document
      .querySelectorAll('.CSVImporter_ProgressDisplay__status')
      .forEach((importerTextButton) => {
        if (importerTextButton.innerHTML == 'Complete') {
          importerTextButton.innerHTML = ''
        }
      })
  }, 100)

  const [importOrganization] = useMutation(
    gql`
      mutation ImportOrganization(
        $importOrganizationInput: ImportOrganizationInput!
      ) {
        importOrganization(input: $importOrganizationInput) {
          processStarted
        }
      }
    `,
    {
      onCompleted(data) {
        setIsSubmitting(false)
        if (data.importOrganization.processStarted) {
          toast.success('Organization adding!')
          setRowsMissingInfo([])
          toggleModal(false)
        }
      },
      errorPolicy: 'all',
    }
  )

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        csvOrganizations: [],
      }}
      validationSchema={Yup.object().shape({
        csvOrganizations: Yup.array().of(
          Yup.object().shape({
            uuid: Yup.string().nullable(),
            organizationName: Yup.string().nullable(),
            contactFirstName: Yup.string().nullable(),
            contactLastName: Yup.string().nullable(),
            contactJobTitle: Yup.string().nullable(),
            contactEmail: Yup.string().nullable(),
            contactSecondaryEmail: Yup.string().nullable(),
            contactPhone: Yup.string().nullable(),
            contactSecondaryPhone: Yup.string().nullable(),
            contactPassword: Yup.string().nullable(),
            contactLoginAccess: Yup.string().nullable(),
            addressLineOne: Yup.string().nullable(),
            addressLineTwo: Yup.string().nullable(),
            city: Yup.string().nullable(),
            state: Yup.string().nullable(),
            zip: Yup.string().nullable(),
            taskCollectionName: Yup.string().nullable(),
            stageName: Yup.string().nullable(),
            stageColor: Yup.string().nullable(),
          })
        ),
      })}
      onSubmit={async (values) => {
        setIsSubmitting(true)
        setPollInterval(true)
        importOrganization({
          variables: {
            importOrganizationInput: {
              organizationInput: values.csvOrganizations,
            },
          },
        })
      }}
    >
      {(formik) => (
        <Modal
          size="xl"
          show={showModal}
          onHide={() => {
            setIsSubmitting(false)
            setRowsMissingInfo([])
            formik.resetForm()
            toggleModal(false)
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Buildings className="mr-2" />
              <span>Import Organizations</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Row>
                <Form.Group as={Col}>
                  {formik.values.csvOrganizations.length > 0 && (
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Button
                          className="mt-2 mb-1"
                          onClick={() => {
                            setImporterKey(Math.random())
                            setRowsMissingInfo([])
                            setRowFatalError(false)
                            formik.setFieldValue('csvOrganizations', [])
                          }}
                          size="sm"
                          variant="link"
                        >
                          <ArrowCounterclockwise className="mr-2" />
                          New Upload
                        </Button>
                      </Form.Group>
                    </Form.Row>
                  )}
                  <Form.Row className="mt-2">
                    <Form.Group as={Col}>
                      <div className="mt-2">
                        <OrganizationCsvImporter
                          importerKey={importerKey}
                          rowsMissingInfo={rowsMissingInfo}
                          rowFatalError={rowFatalError}
                          setRowsMissingInfo={setRowsMissingInfo}
                          setRowFatalError={setRowFatalError}
                          updateCsvData={(transformedData) => {
                            formik.setFieldValue(
                              'csvOrganizations',
                              transformedData
                            )
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>

                  {rowsMissingInfo.length > 0 && (
                    <>
                      <Form.Row className="mt-3">
                        <Form.Group as={Col}>
                          <h4>
                            <ExclamationTriangle className="mr-2" />
                            Import Warnings and Errors
                          </h4>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row className="mt-3">
                        <Form.Group as={Col}>
                          <Table
                            responsive
                            size="sm"
                            bordered
                            style={{ maxHeight: '300px' }}
                          >
                            <thead>
                              <tr>
                                <th>
                                  <small className="font-weight-bold">
                                    Type
                                  </small>
                                </th>
                                <th>
                                  <small className="font-weight-bold">
                                    Row
                                  </small>
                                </th>
                                <th>
                                  <small className="font-weight-bold">
                                    Column
                                  </small>
                                </th>
                                <th>
                                  <small className="font-weight-bold">
                                    Error
                                  </small>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {rowsMissingInfo.map((csvError, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <small className="d-block">
                                        {csvError.fatal ? (
                                          <>Fatal</>
                                        ) : (
                                          <>Warning</>
                                        )}
                                      </small>
                                    </td>
                                    <td>
                                      <small
                                        className="d-block"
                                        style={{
                                          width: '150px',
                                        }}
                                      >
                                        {csvError.row}
                                      </small>
                                    </td>
                                    <td>
                                      <small
                                        className="d-block"
                                        style={{
                                          width: '150px',
                                        }}
                                      >
                                        {csvError.column}
                                      </small>
                                    </td>
                                    <td>
                                      <small className="d-block">
                                        {csvError.error}
                                      </small>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </Form.Group>
                      </Form.Row>
                    </>
                  )}

                  <Form.Row
                    className={
                      formik.values.csvOrganizations.length > 0 &&
                      rowFatalError === false
                        ? 'text-secondary'
                        : 'd-none'
                    }
                  >
                    <Form.Group as={Col}>
                      <h4
                        className={
                          formik.values.csvOrganizations.length > 0
                            ? 'text-secondary mt-3 mb-0'
                            : 'd-none'
                        }
                      >
                        CSV Data
                      </h4>
                      <p
                        className={
                          formik.values.csvOrganizations.length > 0
                            ? 'text-secondary'
                            : 'd-none'
                        }
                      >
                        {formik.values.csvOrganizations.length} rows
                      </p>
                      <Table
                        responsive
                        size="sm"
                        bordered
                        style={{ maxHeight: '300px' }}
                        className={
                          formik.values.csvOrganizations.length > 0
                            ? 'mt-2 d-block'
                            : 'd-none'
                        }
                      >
                        <thead>
                          <tr>
                            <th>
                              <small className="font-weight-bold">
                                Organization name
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Contact first name
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Contact last name
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Contact Job Title
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Contact Email
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Contact Secondary Email
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Contact Phone
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Contact Secondary Phone
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Contact Password
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Contact Login Access
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Address Line One
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Address Line Two
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">City</small>
                            </th>
                            <th>
                              <small className="font-weight-bold">State</small>
                            </th>
                            <th>
                              <small className="font-weight-bold">Zip</small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Stage Name
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Stage Color
                              </small>
                            </th>
                            <th>
                              <small className="font-weight-bold">
                                Task Collection Name
                              </small>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {formik.values.csvOrganizations.map((row, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {row.organizationName}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{ width: '150px' }}
                                  >
                                    {row.contactFirstName}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {row.contactLastName}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {row.contactJobTitle}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '200px',
                                    }}
                                  >
                                    {row.contactEmail}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '200px',
                                    }}
                                  >
                                    {row.contactSecondaryEmail}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '200px',
                                    }}
                                  >
                                    {row.contactPhone}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {row.contactSecondaryPhone}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {row.contactPassword}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {row.contactLoginAccess}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {row.addressLineOne}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '180px',
                                    }}
                                  >
                                    {row.addressLineTwo}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {row.city}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {row.state}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {row.zip}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {row.stageName}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {row.stageColor}
                                  </small>
                                </td>
                                <td>
                                  <small
                                    className="d-block"
                                    style={{
                                      width: '150px',
                                    }}
                                  >
                                    {row.taskCollectionName}
                                  </small>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={2}>
                  <Button
                    type="submit"
                    variant="outline-primary"
                    className="my-4"
                    block
                    disabled={
                      submitting ||
                      rowFatalError === true ||
                      formik.values.csvOrganizations.length == 0
                    }
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Form.Group>
              </Form.Row>

              {submitting ? (
                <Form.Row className="mt-2">
                  <Form.Group as={Col}>
                    <Loading />
                  </Form.Group>
                </Form.Row>
              ) : null}
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </Formik>
  )
}
