import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import {
  CaretDown,
  CaretRight,
  PlusCircle,
  Tag,
  Trash,
} from 'react-bootstrap-icons'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import SortableInfiniteTable from '../common/SortableInfiniteTable'

const TagModal = (props) => {
  const { showModal, tag, toggleModal } = props
  const [submitting, setSubmitting] = useState(false)
  const [displayAssociateRecords, setDisplayAssociateRecords] = useState(true)

  const [deleteTag] = useMutation(
    gql`
      mutation DeleteTag($deleteTagInput: DeleteTagInput!) {
        deleteTag(input: $deleteTagInput) {
          deleted
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success('Tag Deleted')
        toggleModal()
      },
      refetchQueries: ['Tags'],
      errorPolicy: 'all',
    }
  )

  const [createTag] = useMutation(
    gql`
      mutation CreateTag($input: CreateTagInput!) {
        createTag(input: $input) {
          tag {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Tag Saved`)
        toggleModal()
      },
      refetchQueries: ['Tags'],
    }
  )

  const [updateTag] = useMutation(
    gql`
      mutation UpdateTag($input: UpdateTagInput!) {
        updateTag(input: $input) {
          tag {
            id
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setSubmitting(false)
        toast.success(`Tag Saved`)
        toggleModal()
      },
      refetchQueries: ['Tags'],
    }
  )

  const handleDeleteClick = () => {
    setSubmitting(true)
    deleteTag({
      variables: {
        deleteTagInput: {
          tagIds: [tag.id],
        },
      },
    })
  }

  const [
    query,
    { error: queryError, data: queryData, fetchMore: queryFetchMore },
  ] = useLazyQuery(
    gql`
      query TaggedItems($cursor: String, $tag: ID!) {
        taggedItems(first: 100, after: $cursor, tag: $tag) {
          nodeCount
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              contentObject
              contentType {
                model
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  )

  useEffect(() => {
    if (tag) {
      query({
        variables: {
          tag: tag.id,
        },
      })
    }
  }, [tag])

  const fetchMore = () => {
    const variables = {
      cursor: queryData?.taggedItems?.pageInfo?.endCursor,
    }
    queryFetchMore({
      variables,
    })
  }

  const formik = useFormik({
    initialValues: tag
      ? {
          name: tag.name,
        }
      : {
          name: '',
        },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('required'),
    }),
    validateOnChange: true,
    onSubmit: (values) => {
      setSubmitting(true)
      if (tag) {
        updateTag({
          variables: {
            input: {
              id: tag.id,
              name: values.name,
            },
          },
        })
      } else {
        createTag({
          variables: {
            input: {
              name: values.name,
            },
          },
        })
      }
    },
  })

  if (!showModal) return <></>
  return (
    <>
      <div className="addEditTagsModal">
        <Modal size={'md'} show={showModal} onHide={toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Tag className="mr-2" />
              {tag ? <>Edit Tag</> : <>New Tag</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group as={Row}>
                <Col md={3}>
                  <Form.Label column sm="12" md="auto">
                    Name
                  </Form.Label>
                </Col>
                <Col sm="12" md={7}>
                  <Form.Control
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    isInvalid={formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {tag && (
                <>
                  <Row className="mt-3 mb-4">
                    <Col>
                      <button
                        type="button"
                        onClick={() =>
                          setDisplayAssociateRecords(!displayAssociateRecords)
                        }
                        className="px-0 btn-link mr-1"
                      >
                        <>
                          {displayAssociateRecords ? (
                            <>
                              <CaretDown size={17} />
                            </>
                          ) : (
                            <>
                              <CaretRight size={17} />
                            </>
                          )}
                        </>
                      </button>
                      <Form.Label className="mb-0">
                        Associated Records
                      </Form.Label>
                    </Col>
                  </Row>
                  {displayAssociateRecords && (
                    <Row className="mb-3">
                      <Col className="text-center">
                        <SortableInfiniteTable
                          loading={!queryData}
                          tableData={
                            queryData?.taggedItems?.edges
                              ? queryData.taggedItems.edges
                              : []
                          }
                          loadingMessage="Loading Tagged Items..."
                          tableColumns={[
                            {
                              Header: 'Record Type',
                              id: 'recordType',
                              accessor: 'node.contentType.model',
                              serverSort: false,
                            },
                            {
                              Header: 'Record',
                              id: 'record',
                              serverSort: false,
                              accessor: 'node.contentObject',
                            },
                          ]}
                          fetchMoreTableData={fetchMore}
                          hasMoreTableData={
                            queryData?.taggedItems?.pageInfo?.hasNextPage
                          }
                          tableHeight={300}
                          rowPointer
                          hideGlobalFilter
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
              <Form.Row className="mt-2">
                <Col md={{ span: 8, offset: 2 }}>
                  <Button
                    type="submit"
                    variant="outline-primary"
                    block
                    disabled={submitting}
                  >
                    <PlusCircle className="mr-2" />
                    Save
                  </Button>
                </Col>
              </Form.Row>

              {tag && (
                <Form.Row className="mt-2">
                  <Col md={{ span: 8, offset: 2 }}>
                    <Button
                      variant="outline-danger"
                      onClick={handleDeleteClick}
                      block
                      disabled={submitting}
                    >
                      <Trash className="mr-2" />
                      Delete
                    </Button>
                  </Col>
                </Form.Row>
              )}
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default TagModal
