import React, { useState } from 'react'
import { Modal, Button, Row, Col, Form, Table } from 'react-bootstrap'
import Notifications from './Notifications'
import { ArrowRepeat, Trash } from 'react-bootstrap-icons'
import { useMutation, gql, useLazyQuery } from '@apollo/client'
import toast from 'react-hot-toast'
import Loading from '../common/Loading'
import InfiniteScroll from 'react-infinite-scroll-component'
import NotificationTriggerSearchInput from '../common/node_search_input/NotificationTriggerSearchInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const CopyTriggerModal = (props) => {
  const {
    showModal,
    toggleModal,
    notificationTriggerId,
    all,
    subjectGroup,
    organization,
    organizationIds,
    subjectGroupIds,
    postSubmit,
  } = props
  const [copyTriggerId, setCopyTriggerId] = useState()
  const [loading, setLoading] = useState(false)
  const [syncWithOption, setSyncWithOption] = useState('Defaults')
  const [syncWithOrganizationName, setSyncWithOrganizationName] = useState('')
  const [syncWithOrganizationId, setSyncWithOrganizationId] = useState('')
  const [syncWithSubjectGroupName, setSyncWithSubjectGroupName] = useState('')
  const [syncWithSubjectGroupId, setSyncWithSubjectGroupId] = useState('')
  const [displayOrganizationResults, setDisplayOrganizationResults] =
    useState(false)
  const [displaySubjectGroupResults, setDisplaySubjectGroupResults] =
    useState(false)

  const syncAllOptions = ['Default', 'Organization', 'Subject Group']

  const [
    searchOrganizations,
    { data: organizationsData, fetchMore: fetchMoreOrganizations },
  ] = useLazyQuery(
    gql`
      query NotificationOrganizations(
        $after: String
        $first: Int
        $nameIcontains: String
      ) {
        organizations(
          after: $after
          first: $first
          name_Icontains: $nameIcontains
          orderBy: "name"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [
    searchSubjectGroups,
    { data: subjectGroupsData, fetchMore: fetchMoreSubjectGroups },
  ] = useLazyQuery(
    gql`
      query NotificationSubjectGroups(
        $after: String
        $first: Int
        $nameIcontains: String
      ) {
        subjectGroups(
          after: $after
          first: $first
          name_Icontains: $nameIcontains
          orderBy: "name"
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    }
  )

  const handleSubjectGroupChange = (e) => {
    setDisplaySubjectGroupResults(true)
    setSyncWithSubjectGroupName(e.target.value)
    searchSubjectGroups({
      variables: {
        after: null,
        first: 10,
        nameIcontains: e.target.value,
      },
    })
  }

  const handleSubjectGroupXClick = () => {
    setSyncWithSubjectGroupName('')
    setSyncWithSubjectGroupId('')
    formik.setFieldValue('notificationTriggers', [])
  }

  const handleFetchMoreSubjectGroups = () => {
    fetchMoreSubjectGroups({
      variables: {
        after: subjectGroupsData.subjectGroups.pageInfo.endCursor,
        first: 10,
        nameIcontains: syncWithSubjectGroupName,
      },
    })
  }

  const handleSubjectGroupBlur = () => {
    setDisplaySubjectGroupResults(false)
  }

  const handleSubjectGroupClick = (node) => {
    setDisplaySubjectGroupResults(false)
    setSyncWithSubjectGroupId(node.id)
    setSyncWithSubjectGroupName(node.name)
  }

  const handleOrganizationChange = (e) => {
    setDisplayOrganizationResults(true)
    setSyncWithOrganizationName(e.target.value)
    searchOrganizations({
      variables: {
        after: null,
        first: 10,
        nameIcontains: e.target.value,
      },
    })
  }

  const handleOrganizationXClick = () => {
    setSyncWithOrganizationName('')
    setSyncWithOrganizationId('')
    formik.setFieldValue('notificationTriggers', [])
  }

  const handleFetchMoreOrganizations = () => {
    fetchMoreOrganizations({
      variables: {
        after: organizationsData.organizations.pageInfo.endCursor,
        first: 10,
        nameIcontains: syncWithOrganizationName,
      },
    })
  }

  const handleOrganizationBlur = () => {
    setDisplayOrganizationResults(false)
  }

  const handleOrganizationClick = (node) => {
    setDisplayOrganizationResults(false)
    setSyncWithOrganizationName(node.name)
    setSyncWithOrganizationId(node.id)
  }

  const innerToggleModal = () => {
    toggleModal()
    formik.resetForm()
    setSyncWithOption('Defaults')
    setSyncWithSubjectGroupId('')
    setSyncWithSubjectGroupName('')
    setSyncWithOrganizationName('')
    setSyncWithOrganizationId('')
  }

  useState(() => {
    if (syncWithOption === 'Organization') {
      setSyncWithSubjectGroupId('')
      setSyncWithSubjectGroupName('')
    } else if (syncWithOption === 'Subject Group') {
      setSyncWithOrganizationId('')
      setSyncWithOrganizationName('')
    } else if (syncWithOption === 'Defaults') {
      setSyncWithOrganizationName('')
      setSyncWithOrganizationId('')
      setSyncWithSubjectGroupName('')
      setSyncWithSubjectGroupId('')
    }
  }, [syncWithOption])

  const [notificationTemplateSync] = useMutation(
    gql`
      mutation NotificationTemplateSync(
        $notificationTemplateSyncInput: NotificationTemplateSyncInput!
      ) {
        notificationTemplateSync(input: $notificationTemplateSyncInput) {
          synced
        }
      }
    `,
    {
      errorPolicy: 'all',
      onCompleted: () => {
        setLoading(false)
        if (all) {
          toast.success('Sync Started')
        } else {
          toast.success('Sync Complete')
        }
        innerToggleModal()
        if (postSubmit) {
          postSubmit()
        }
      },
      refetchQueries: ['NotificationTriggers'],
    }
  )
  let title = 'Select Template For Sync'
  if (all) {
    title = 'Sync Notification Templates'
  }

  const formik = useFormik({
    initialValues: {
      syncAllNotifications: true,
      notificationTriggers: [],
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      syncAllNotifications: Yup.boolean(),
      notificationTriggers: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().nullable(),
            description: Yup.string().nullable(),
          })
        )
        .test('notificationTriggers', 'Required', function (value) {
          const { syncAllNotifications } = this.parent
          if (!syncAllNotifications && (!value || value.length === 0)) {
            return false
          }
          return true
        }),
    }),
  })

  if (!showModal) return <></>
  return (
    <>
      <div className="customNotificationTrigger">
        <Modal
          size={all && formik.values.syncAllNotifications ? 'md' : 'lg'}
          show={showModal}
          onHide={innerToggleModal}
          aria-labelledby="new-title"
          className="invmodal detail"
          closeButton
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {all && (
              <>
                <Row style={{ marginLeft: '-8px' }}>
                  <Col md={{ offset: 1, span: 10 }}>
                    <Form.Label>Sync With</Form.Label>
                    <Form.Control
                      className="form-control-sm"
                      name="category"
                      as="select"
                      value={syncWithOption}
                      onChange={(e) => {
                        if (e.target.value !== syncWithOption) {
                          setSyncWithOption(e.target.value)
                          formik.setFieldValue('notificationTriggers', [])
                        }
                      }}
                    >
                      {syncAllOptions.map((syncOption) => (
                        <option key={syncOption} value={syncOption}>
                          {syncOption}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
                {syncWithOption === 'Organization' && (
                  <Row className="mt-3" style={{ marginLeft: '-8px' }}>
                    <Col
                      sm="12"
                      md={
                        syncWithOrganizationId
                          ? { offset: 1, span: 9 }
                          : { offset: 1, span: 10 }
                      }
                    >
                      <Form.Control
                        placeholder="Search Organizations"
                        value={syncWithOrganizationName}
                        onBlur={handleOrganizationBlur}
                        onChange={(e) => handleOrganizationChange(e)}
                        readOnly={Boolean(syncWithOrganizationId)}
                        className={
                          syncWithOrganizationId
                            ? ' border border-success form-control form-control-sm'
                            : 'form-control form-control-sm'
                        }
                      />
                      {organizationsData && displayOrganizationResults && (
                        <InfiniteScroll
                          height={100}
                          dataLength={
                            organizationsData.organizations.edges.length
                          }
                          next={handleFetchMoreOrganizations}
                          hasMore={
                            organizationsData?.organizations.pageInfo
                              .hasNextPage
                          }
                          loader={<Loading />}
                        >
                          <Table size="sm" hover>
                            <tbody>
                              {organizationsData.organizations.edges.map(
                                (org) => {
                                  const { node } = org
                                  if (
                                    !organization ||
                                    organization.id != node.id
                                  ) {
                                    return (
                                      <tr
                                        onMouseDown={() => {
                                          handleOrganizationClick(node)
                                          formik.setFieldValue(
                                            'notificationTriggers',
                                            []
                                          )
                                        }}
                                        key={node.id}
                                        className="hover text-decoration-none"
                                      >
                                        <td>
                                          <small>{node.name}</small>
                                        </td>
                                      </tr>
                                    )
                                  }
                                }
                              )}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                      )}
                    </Col>
                    <Col>
                      {syncWithOrganizationId && (
                        <button
                          type="button"
                          className="p-0 mr-1 btn-link"
                          onClick={handleOrganizationXClick}
                        >
                          <Trash />
                        </button>
                      )}
                    </Col>
                  </Row>
                )}
                {syncWithOption === 'Subject Group' && (
                  <Row className="mt-3" style={{ marginLeft: '-8px' }}>
                    <Col
                      sm="12"
                      md={
                        syncWithSubjectGroupId
                          ? { offset: 1, span: 9 }
                          : { offset: 1, span: 10 }
                      }
                    >
                      <Form.Control
                        placeholder="Search Subject Groups"
                        value={syncWithSubjectGroupName}
                        onBlur={handleSubjectGroupBlur}
                        onChange={(e) => handleSubjectGroupChange(e)}
                        readOnly={Boolean(syncWithSubjectGroupId)}
                        className={
                          syncWithSubjectGroupId
                            ? ' border border-success form-control form-control-sm'
                            : 'form-control form-control-sm'
                        }
                      />
                      {subjectGroupsData && displaySubjectGroupResults && (
                        <InfiniteScroll
                          height={100}
                          dataLength={
                            subjectGroupsData.subjectGroups.edges.length
                          }
                          next={handleFetchMoreSubjectGroups}
                          hasMore={
                            subjectGroupsData?.subjectGroups.pageInfo
                              .hasNextPage
                          }
                          loader={<Loading />}
                        >
                          <Table size="sm" hover>
                            <tbody>
                              {subjectGroupsData.subjectGroups.edges.map(
                                (org) => {
                                  const { node } = org
                                  if (
                                    !subjectGroup ||
                                    subjectGroup.id != node.id
                                  ) {
                                    return (
                                      <tr
                                        onMouseDown={() => {
                                          handleSubjectGroupClick(node)
                                          formik.setFieldValue(
                                            'notificationTriggers',
                                            []
                                          )
                                        }}
                                        key={node.id}
                                        className="hover text-decoration-none"
                                      >
                                        <td>
                                          <small>{node.name}</small>
                                        </td>
                                      </tr>
                                    )
                                  }
                                }
                              )}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                      )}
                    </Col>
                    <Col>
                      {syncWithSubjectGroupId && (
                        <button
                          type="button"
                          className="p-0 mr-1 btn-link"
                          onClick={handleSubjectGroupXClick}
                        >
                          <Trash />
                        </button>
                      )}
                    </Col>
                  </Row>
                )}
                <Row className="mt-3" style={{ marginLeft: '-8px' }}>
                  <Col sm="12" md={{ offset: 1, span: 10 }}>
                    <Form.Check
                      type="checkbox"
                      label="Sync All Notification Templates"
                      checked={formik.values.syncAllNotifications}
                      onChange={(e) =>
                        formik.setFieldValue(
                          'syncAllNotifications',
                          e.target.checked
                        )
                      }
                    />
                  </Col>
                </Row>
                {!formik.values.syncAllNotifications && (
                  <Row className="mt-3">
                    <Col md={{ offset: 1, span: 10 }}>
                      <Form.Label>Notification Triggers</Form.Label>
                      <NotificationTriggerSearchInput
                        multiple
                        dropdown
                        formik={formik}
                        variables={{
                          custom: false,
                          subjectGroupId: syncWithSubjectGroupId,
                          organizationId: syncWithOrganizationId,
                          organizationIdIsNull: syncWithOrganizationId == '',
                          subjectGroupIdIsNull: syncWithSubjectGroupId == '',
                        }}
                        error={formik.errors.notificationTriggers}
                      />
                    </Col>
                  </Row>
                )}
                <Row className="mt-3">
                  <Col md={{ offset: 1, span: 10 }}>
                    <Button
                      variant="outline-primary"
                      disabled={
                        loading || Object.keys(formik.errors).length > 0
                      }
                      block
                      onClick={() => {
                        formik.validateForm()
                        const variables = {}
                        if (organization) {
                          variables.organizationId = organization.id
                        } else if (subjectGroup) {
                          variables.subjectGroupId = subjectGroup.id
                        } else if (organizationIds) {
                          variables.organizationIds = organizationIds
                        } else if (subjectGroupIds) {
                          variables.subjectGroupIds = subjectGroupIds
                        }
                        if (!formik.values.syncAllNotifications) {
                          variables.notificationTriggerIds =
                            formik.values.notificationTriggers.map(
                              (notificationTrigger) => notificationTrigger.id
                            )
                        }
                        if (
                          syncWithOption === 'Organization' &&
                          !syncWithOrganizationId
                        ) {
                          toast.error('Organization Required')
                        } else if (
                          syncWithOption === 'Subject Group' &&
                          !syncWithSubjectGroupId
                        ) {
                          toast.error('Subject Group Required')
                        } else {
                          if (syncWithOrganizationId) {
                            variables.syncWithOrganizationId =
                              syncWithOrganizationId
                          } else if (syncWithSubjectGroupId) {
                            variables.syncWithSubjectGroupId =
                              syncWithSubjectGroupId
                          }
                          notificationTemplateSync({
                            variables: {
                              notificationTemplateSyncInput: {
                                notificationTemplateSyncInput: variables,
                              },
                            },
                          })
                        }
                      }}
                    >
                      <ArrowRepeat className="mr-2" /> Sync
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {!all && (
              <>
                <Notifications
                  copyTrigger={true}
                  setCopyTriggerId={setCopyTriggerId}
                />
                <Row>
                  <Col>
                    <Button
                      variant="outline-primary"
                      disabled={loading}
                      onClick={() => {
                        setLoading(true)
                        notificationTemplateSync({
                          variables: {
                            notificationTemplateSyncInput: {
                              notificationTemplateSyncInput: {
                                notificationTriggerId: notificationTriggerId,
                                syncNotificationTriggerId: copyTriggerId,
                              },
                            },
                          },
                        })
                      }}
                    >
                      <ArrowRepeat className="mr-2" /> Sync
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {loading && <Loading />}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export default CopyTriggerModal
