import React, { useState } from 'react'
import { Row, Col, Tab } from 'react-bootstrap'
import { PencilSquare, Clipboard2Check } from 'react-bootstrap-icons'
import Forms from './Forms'
import FormSubmissions from './FormSubmissions'
import Tabs from '../common/Tabs'
import { useLocation } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { loggedInUserVar } from '../../libs/apollo'

const FormsFormSubmissions = (props) => {
  const {
    submitterId,
    sessionId,
    sessionDescription,
    subjectId,
    subjectDesciption,
    employeeId,
    employeeDescription,
    jobId,
    jobDescription,
    subjectGroupId,
    subjectGroupDescription,
    organizationId,
    organizationDescription,
    employeeJobId,
    employeeJobDescription,
  } = props
  const [activeTab, setActiveTab] = useState('Forms')
  const location = useLocation()
  const loggedInUser = useReactiveVar(loggedInUserVar)
  return (
    <>
      {location.pathname == '/forms' && (
        <Row>
          <Col>
            <h1 className="d-flex justify-content-between mb-0 mt-3">Forms</h1>
          </Col>
        </Row>
      )}
      {loggedInUser.permissions.group == 'Administrator' && (
        <Row className="mt-4">
          <Col>
            <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
              <Tab
                key="Forms"
                eventKey="Forms"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <PencilSquare className="mr-1" />
                    Forms
                  </b>
                }
              >
                <div className="mt-4">
                  <Forms
                    sessionId={sessionId}
                    sessionDescription={sessionDescription}
                    subjectId={subjectId}
                    subjectDesciption={subjectDesciption}
                    employeeId={employeeId}
                    employeeDescription={employeeDescription}
                    jobId={jobId}
                    jobDescription={jobDescription}
                    subjectGroupId={subjectGroupId}
                    subjectGroupDescription={subjectGroupDescription}
                    organizationId={organizationId}
                    organizationDescription={organizationDescription}
                    employeeJobId={employeeJobId}
                    employeeJobDescription={employeeJobDescription}
                  />
                </div>
              </Tab>
              <Tab
                key="FormSubmissions"
                eventKey="FormSubmissions"
                style={{ marginTop: '10px' }}
                title={
                  <b>
                    <Clipboard2Check className="mr-1" />
                    Form Submissions
                  </b>
                }
              >
                <div className="mt-4">
                  <FormSubmissions
                    submitterId={submitterId}
                    subjectId={subjectId}
                    employeeId={employeeId}
                    jobId={jobId}
                    subjectGroupId={subjectGroupId}
                    organizationId={organizationId}
                    employeeJobId={employeeJobId}
                  />
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      )}
      {!loggedInUser.permissions.group == 'Administrator' && (
        <Row className="mt-4">
          <Col>
            <Forms
              sessionId={sessionId}
              sessionDescription={sessionDescription}
              subjectId={subjectId}
              subjectDesciption={subjectDesciption}
              employeeId={employeeId}
              employeeDescription={employeeDescription}
              jobId={jobId}
              jobDescription={jobDescription}
              subjectGroupId={subjectGroupId}
              subjectGroupDescription={subjectGroupDescription}
              organizationId={organizationId}
              organizationDescription={organizationDescription}
              employeeJobId={employeeJobId}
              employeeJobDescription={employeeJobDescription}
            />
          </Col>
        </Row>
      )}
    </>
  )
}
export default FormsFormSubmissions
