import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Accordion, Card, Modal } from 'react-bootstrap'
import { useLazyQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { GeoAlt } from 'react-bootstrap-icons'
import { formatDateToStringOmmitYear } from '../../../libs/utils'
import moment from 'moment'
const Reschedule = (props) => {
  const {
    subjectGroupId,
    sessionPackageId,
    handleSessionTimeClick,
    packageCategoryId,
    toggleRescheduleModal,
    showRescheduleModal,
    sessionIndex,
  } = props

  const [availableSessionsTransformed, setAvailableSessionsTransformed] =
    useState({})

  const [availableSessionsQuery, { data: availableSessionsQueryData }] =
    useLazyQuery(
      gql`
        query AvailableSessionsQuery(
          $sessionPackageId: ID!
          $subjectGroupId: ID
          $packageCategoryId: ID
        ) {
          availableSessions(
            sessionPackageId: $sessionPackageId
            subjectGroupId: $subjectGroupId
            packageCategoryId: $packageCategoryId
          ) {
            startDateTime
            endDateTime
            job {
              id
              location {
                id
                name
                addressLineOne
                addressLineTwo
                city
                zipCode
              }
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only',
        onError: (error) => {
          // TODO
        },
      }
    )

  useEffect(() => {
    availableSessionsQuery({
      variables: {
        sessionPackageId: sessionPackageId,
        packageCategoryId: packageCategoryId,
        subjectGroupId: subjectGroupId,
      },
    })
  }, [sessionPackageId, subjectGroupId])

  useEffect(() => {
    if (availableSessionsQueryData?.availableSessions) {
      const currentLocationsSessions = {}
      availableSessionsQueryData.availableSessions.forEach((session) => {
        const startDateTime = moment(session.startDateTime).toDate()
        const startDate = formatDateToStringOmmitYear(startDateTime)
        const currentSession = {
          jobNode: session.job,
          startDateTime: session.startDateTime,
          formattedStartTime: moment(session.startDateTime).format('h:mma'),
        }
        const locationId = session.job.location.id
        if (locationId in currentLocationsSessions) {
          if (startDate in currentLocationsSessions[locationId].sessions) {
            currentLocationsSessions[locationId].sessions[startDate].push(
              currentSession
            )
          } else {
            currentLocationsSessions[locationId].sessions[startDate] = [
              currentSession,
            ]
          }
        } else {
          let address
          if (session.job.location.addressLineTwo) {
            address = `${session.job.location.addressLineOne} ${session.job.location.addressLineTwo}`
          } else {
            address = session.job.location.addressLineOne
          }
          currentLocationsSessions[locationId] = {
            location: {
              address,
              city: session.job.location.city,
              name: session.job.location.name ? session.job.location.name : '',
              zipCode: session.job.location.zipCode,
            },
            sessions: {},
          }
          currentLocationsSessions[locationId].sessions[startDate] = [
            currentSession,
          ]
        }
      })
      setAvailableSessionsTransformed(currentLocationsSessions)
    }
  }, [availableSessionsQueryData])

  if (Object.entries(availableSessionsTransformed).length === 0) {
    return (
      <Modal
        size="lg"
        show={showRescheduleModal}
        onHide={() => toggleRescheduleModal(sessionIndex, 'reschedule')}
      >
        <Modal.Header closeButton>
          <div>
            Rescheduling Session To Another Job
            <small className="d-block text-secondary fst-italic">
              changes made to this session will carry over to the rescheduled
              session
            </small>
          </div>
        </Modal.Header>

        <Modal.Body>
          <p>No available sessions for this session package on upcoming jobs</p>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Modal
      size="lg"
      show={showRescheduleModal}
      onHide={() => toggleRescheduleModal(sessionIndex, 'reschedule')}
    >
      <Modal.Header closeButton>
        <div>
          Rescheduling Session To Another Job
          <small className="d-block text-secondary fst-italic">
            any changes you have made to this session card will carry over to
            rescheduled session
          </small>
        </div>
      </Modal.Header>

      <Modal.Body>
        <Row className="mt-1">
          <Col md={12}>
            {availableSessionsQueryData &&
              Object.entries(availableSessionsTransformed).map(
                (locationIdSessions, index) => {
                  const locationSessions = locationIdSessions[1]
                  return (
                    <>
                      <Row>
                        <Col>
                          <p className="d-flex align-items-center mb-2">
                            <GeoAlt className="mr-2" />
                            {locationSessions.location.name}{' '}
                            {locationSessions.location.address},{' '}
                            {locationSessions.location.city}{' '}
                            {locationSessions.location.zipCode}
                          </p>
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Col md={9}>
                          <Card.Body className="p-0 mt-2">
                            <Accordion flush>
                              {Object.entries(locationSessions.sessions).map(
                                (sessions, index) => (
                                  <Card key={index}>
                                    <Card.Header className="m-n2">
                                      <Accordion.Toggle
                                        as={Button}
                                        eventKey={`${index}`}
                                        className="w-100 btn-link"
                                      >
                                        <div className="d-flex align-items-center justify-content-between">
                                          <span>{sessions[0]}</span>
                                          <small className="title-space text-secondary">
                                            {sessions[1].length} Openings
                                          </small>
                                        </div>
                                      </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={`${index}`}>
                                      <Card.Body
                                        style={{
                                          maxHeight: '200px',
                                          overflowY: 'scroll',
                                        }}
                                      >
                                        <Row>
                                          <Col className="d-flex flex-wrap">
                                            {sessions[1].map((session) => (
                                              <Button
                                                style={{
                                                  width: '85px',
                                                }}
                                                key={sessionIndex}
                                                onClick={() =>
                                                  handleSessionTimeClick(
                                                    session.jobNode.id,
                                                    session.formattedStartTime,
                                                    sessions[0],
                                                    locationSessions.location,
                                                    sessionIndex
                                                  )
                                                }
                                                variant="outline-primary"
                                                className=" mt-2 mb-1 mx-1"
                                                size="sm"
                                              >
                                                <small>
                                                  {session.formattedStartTime}
                                                </small>
                                              </Button>
                                            ))}
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                )
                              )}
                            </Accordion>
                          </Card.Body>
                        </Col>
                      </Row>
                    </>
                  )
                }
              )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default Reschedule
